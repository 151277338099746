<script setup>
import pick from "ramda/es/pick";
import SectionFrame from "~components/SectionFrame.vue";
import EditPassword from "~components/settings/EditPassword.vue";
import SocialMediaInput from "~components/form_fields/SocialMediaInput.vue";
import TextAreaField from "~components/form_fields/TextArea.vue";
import TextInputField from "~components/form_fields/TextInput.vue";
import PasswordConfirmationModal from "~components/PasswordConfirmationModal.vue";
import { safeJSONParse } from "~utils";
import { ref, computed, defineEmits, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "~src/lib/i18n";
import useAccountValidation from "~src/composables/accountValidation";

const FIELDS = {
  username: undefined
};

const { i18n, t } = useI18n();
const store = useStore();
const emit = defineEmits(["success", "error"]);

const initializer = () => undefined;
const alertInitializer = () => ({ message: "", type: "" });
const local = ref({ ...FIELDS });
const serverSideErrors = ref([]);
const alert = ref(alertInitializer());
const sending = ref(false);

const props = defineProps({
  profile: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    default: true
  }
});

const { state, v$ } = useAccountValidation({ initialValues: props.profile });

const persist = params => store.dispatch("profile/update", { ...params });

const changeUsername = callback => {
  serverSideErrors.value = [];
  sending.value = true;

  persist({ payload: state })
    .then(() => {
      sending.value = false;
      alert.value = {
        message: t("user.sections.account_settings.profile.success_message"),
        type: "success"
      };
      setTimeout(() => (alert.value = alertInitializer()), 5000);
      emit("success", { what: "personal" });
    })
    .catch(callback);
};

const submit = () => {
  changeUsername(error => {
    sending.value = false;
    const { response } = error;
    const errorDetail = response?.data?.details || "unmapped_constraint";
    const errorHint = safeJSONParse(response?.data?.hint);
    const errorField =
      errorDetail.match(/^(?:(?<field>.*)__)/)?.groups?.field || "profile";
    const errorMessage = i18n
      .t(`db.${errorDetail}`, errorHint)
      ?.replace(/\.$/, "");

    serverSideErrors.value = {
      [errorField]: [errorMessage]
    };

    alert.value = {
      message: errorMessage,
      type: "danger"
    };

    emit("error", {
      what: "instructor",
      where: errorField,
      why: errorMessage
    });
  });
};

local.value = pick(Object.keys(FIELDS), props.profile);

watch(props.profile, (n, o) => {
  local.value = pick(Object.keys(FIELDS), n);
});

const emailConfirmed = computed(() => store.state.userAccount.confirmed_at);

const submitOrModal = () => {
  if (emailConfirmed.value) {
    changeUsername(() => {
      sending.value = false;
      openPasswordConfirmation();
    });
  } else {
    store.commit("emailVerificationModalStatus/change", { open: true });
  }
};

const passwordConfirmationOpen = ref(false);

const closePasswordModal = () => {
  passwordConfirmationOpen.value = false;
};

const openPasswordConfirmation = () => {
  passwordConfirmationOpen.value = true;
};

const confirmationSuccessful = e => {
  submit();
};
</script>

<template>
  <password-confirmation-modal
    :opened="passwordConfirmationOpen"
    @close="closePasswordModal"
    param="username"
    :paramValue="v$.username.$model"
    @success="confirmationSuccessful"
  />

  <div>
    <div
      ref="top"
      class="font-bold uppercase text-xs text-foreground-medium tracking-widest mb-6"
    >
      Username
    </div>
    <section-frame :alert="alert">
      <form action="#" ref="form">
        <text-input-field
          class="mb-4"
          field="username"
          :errors="[
            ...v$.username.$errors.map(e => e.$message),
            ...(serverSideErrors.username || [])
          ]"
          :disabled="loading || sending"
          label="Change username"
          input-size="medium"
          :input-block="true"
          :input-placeholder="
            t('user.forms.profile.username_field_placeholder')
          "
          v-model="v$.username.$model"
        >
        </text-input-field>

        <input
          :disabled="loading || sending || v$.$invalid"
          @click.prevent="submitOrModal"
          type="submit"
          class="m-button m-button--blue-medium m-button--primary m-button--md"
          :value="$t('user.forms.profile.submit')"
        />
      </form>
    </section-frame>
    <div class="mx-border-t mb-10 mt-10"></div>

    <edit-password />
  </div>
</template>
