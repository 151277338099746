<script setup>
import SectionFrame from "~components/SectionFrame.vue";
import { useStore } from "vuex";
import { useI18n } from "~src/lib/i18n";
import { unref, ref, onMounted, watch, computed } from "vue";
import pick from "ramda/es/pick";
import omit from "ramda/es/omit";
import { snakeCasedKeys, normalizeUrl, safeJSONParse } from "~utils";
import useTimezoneValidation from "~src/composables/timezoneValidation";

import {
  timezones,
  shortDateFormats,
  longDateFormats,
  shortTimeFormats,
  longTimeFormats,
  currentBrowserTimezone,
  localizedDateTime
} from "~src/lib/utils/tz.js";

const FIELDS = {
  timezone: undefined,
  locale: undefined,
  shortDateFormat: undefined,
  longDateFormat: undefined,
  shortTimeFormat: undefined,
  longTimeFormat: undefined
};

const { t, i18n } = useI18n();
const store = useStore();
const emit = defineEmits(["success", "error"]);

const initializer = () => undefined;
const alertInitializer = () => ({ message: "", type: "" });

const props = defineProps({
  profile: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    default: true
  }
});

const local = ref({ ...FIELDS });
local.value = pick(Object.keys(FIELDS), props.profile);

const { state, v$ } = useTimezoneValidation({ initialValues: local });

const locales = ref([]);
const serverSideErrors = ref([]);
const alert = alertInitializer();
const sending = ref(false);

const persist = params => store.dispatch("profile/update", params);

const computeLocales = () => {
  locales.value = Object.entries(unref(i18n.tm("iso639_codes"))).sort((a, b) =>
    a[1].localeCompare(b[1])
  );
};

const submit = event => {
  event.preventDefault();
  serverSideErrors.value = [];
  sending.value = true;

  var payload = snakeCasedKeys({
    ...state
  });

  if (payload.country === "" || payload.country == null) {
    payload = omit(["country"], payload);
  }

  persist({ payload })
    .then(() => {
      sending.value = false;
      alert.value = {
        message: t("user.sections.account_settings.profile.success_message"),
        type: "success"
      };
      setTimeout(() => (alert.value = alertInitializer()), 5000);
      emit("success", { what: "personal" });
    })
    .catch(error => {
      sending.value = false;
      const { response } = error;
      const errorDetail = response?.data?.details || "unmapped_constraint";
      const errorHint = safeJSONParse(response?.data?.hint);
      const errorField =
        errorDetail.match(/^(?:(?<field>.*)__)/)?.groups?.field || "profile";
      const errorMessage = i18n
        .t(`db.${errorDetail}`, errorHint)
        ?.replace(/\.$/, "");

      serverSideErrors.value = {
        [errorField]: [errorMessage]
      };
    });
};

computeLocales();
watch(props.profile, (n, o) => {
  local.value = pick(Object.keys(FIELDS), n);
});

const emailConfirmed = computed(() => store.state.userAccount.confirmed_at);

const submitOrModal = event => {
  if (emailConfirmed.value) {
    submit(event);
  } else {
    store.commit("emailVerificationModalStatus/change", { open: true });
  }
};
</script>

<template>
  <section-frame :alert="alert">
    <template #header>
      <div class="mt-10 mx-border-t">
        <div class="flex bdAb mt-10">
          <div
            class="font-bold uppercase text-xs text-foreground-medium tracking-widest"
          >
            Region and Timezone
          </div>
        </div>
      </div>
    </template>

    <div class="grid grid-cols-12 gap-x-2 mt-8">
      <div class="col-span-12">
        <form action="#" ref="form">
          <div class="m-form-field">
            <div class="m-form-field__label m-form-field__label--over">
              <label for="timezone">Timezone</label>
            </div>
            <div class="m-select">
              <select v-model="v$.timezone.$model">
                <option
                  v-for="[timezoneLabel, timezone] in timezones"
                  :value="timezone"
                  :key="timezone"
                >
                  {{ timezoneLabel }}
                </option>
              </select>
            </div>
          </div>

          <div class="m-form-field mt-4">
            <div class="m-form-field__label m-form-field__label--over">
              <label for="country">Locale</label>
            </div>
            <div class="m-select">
              <select v-model="v$.locale.$model">
                <option
                  v-for="[code, locale] in locales"
                  :value="code"
                  :key="code"
                >
                  {{ locale }}
                </option>
              </select>
            </div>
          </div>

          <div class="m-form-field mt-4">
            <div class="m-form-field__label m-form-field__label--over">
              <label for="country">Short Date Format</label>
            </div>
            <div class="m-select">
              <select v-model="v$.shortDateFormat.$model">
                <option
                  v-for="[shortDateLabel, shortDateFormat] in shortDateFormats"
                  :value="shortDateFormat"
                  :key="shortDateFormat"
                >
                  {{ shortDateLabel }}
                </option>
              </select>
            </div>
          </div>

          <div class="m-form-field mt-5">
            <div class="m-form-field__label m-form-field__label--over">
              <label for="country">Long Date Format</label>
            </div>
            <div class="m-select">
              <select v-model="v$.longDateFormat.$model">
                <option
                  v-for="[longDateLabel, longDateFormat] in longDateFormats"
                  :value="longDateFormat"
                  :key="longDateFormat"
                >
                  {{ longDateLabel }}
                </option>
              </select>
            </div>
          </div>

          <div class="m-form-field mt-5">
            <div class="m-form-field__label m-form-field__label--over">
              <label for="country">Short Time Format</label>
            </div>
            <div class="m-select">
              <select v-model="v$.shortTimeFormat.$model">
                <option
                  v-for="[shortTimeLabel, shortTimeFormat] in shortTimeFormats"
                  :value="shortTimeFormat"
                  :key="shortTimeFormat"
                >
                  {{ shortTimeLabel }}
                </option>
              </select>
            </div>
          </div>

          <div class="m-form-field mt-5">
            <div class="m-form-field__label m-form-field__label--over">
              <label for="country">Long Time Format</label>
            </div>
            <div class="m-select">
              <select v-model="v$.longTimeFormat.$model">
                <option
                  v-for="[longtTimeLabel, longTimeFormat] in longTimeFormats"
                  :value="longTimeFormat"
                  :key="longTimeFormat"
                >
                  {{ longtTimeLabel }}
                </option>
              </select>
            </div>
          </div>

          <div class="mt-8 text-sm">
            <div class="my-2">
              <span class="font-bold">Examples</span>
            </div>
            <table>
              <tbody>
                <tr>
                  <td class="mt-2 pl-0 pr-8">
                    <span> Short date: </span>
                  </td>
                  <td class="mt-2">
                    <span>
                      {{
                        localizedDateTime(v$).toFormat(
                          v$.shortDateFormat.$model
                        )
                      }}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td class="mt-2 pl-0 pr-8">
                    <span> Long date: </span>
                  </td>
                  <td class="mt-2">
                    <span>
                      {{
                        localizedDateTime(v$).toFormat(v$.longDateFormat.$model)
                      }}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td class="mt-2 pl-0 pr-8">
                    <span> Short time: </span>
                  </td>
                  <td class="mt-2">
                    <span>
                      {{
                        localizedDateTime(v$).toFormat(
                          v$.shortTimeFormat.$model
                        )
                      }}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td class="mt-2 pl-0 pr-8">
                    <span> Long time: </span>
                  </td>
                  <td class="mt-2">
                    <span>
                      {{
                        localizedDateTime(v$).toFormat(v$.longTimeFormat.$model)
                      }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <input
            :disabled="loading || sending || v$.$invalid"
            type="submit"
            @click.prevent="submitOrModal"
            class="mt-6 m-button m-button--blue-medium m-button--primary m-button--md"
            :value="t('user.forms.profile.submit')"
          />
        </form>
      </div>
    </div>
  </section-frame>
</template>
