<script setup>
import pick from "ramda/es/pick";
import omit from "ramda/es/omit";
import SectionFrame from "~components/SectionFrame.vue";
import SocialPresence from "~components/form_fields/SocialPresence.vue";
import TextAreaField from "~components/form_fields/TextArea.vue";
import TextInputField from "~components/form_fields/TextInput.vue";
import RadioInputField from "~components/form_fields/RadioInput.vue";
import Avatar from "~components/profile/Avatar.vue";
import Alert from "~components/Alert.vue";
import { snakeCasedKeys, safeJSONParse } from "~utils";
import { ref, inject, watch, computed } from "vue";
import { useI18n } from "~src/lib/i18n";
import { useStore } from "vuex";
import useProfileValidation from "~src/composables/profileValidation";
import useSocialMedia from "~src/composables/socialMedia";
import {
  ISODateFromFormattedShortDate,
  formattedShortDateFromISO
} from "~src/lib/utils/tz";

const FIELDS = {
  name: undefined,
  shortBio: undefined,
  longBio: undefined,
  organization: undefined,
  dateOfBirth: undefined,
  public: false,
  pronouns: undefined,
  website: undefined,
  instructor: false,
  socialProfiles: {},
  elearningProfiles: {}
};

const { t, i18n } = useI18n();
const store = useStore();
const emit = defineEmits(["success", "error"]);

const alertInitializer = () => ({ message: "", type: "" });
const user = store.state.userAccount;

const top = ref(null);
const middle = ref(null);
const bottom = ref(null);
const currentScrollTarget = ref(undefined);

const smoothScroll = inject("smoothScroll");
const SCROLL_TARGETS = {
  top,
  middle,
  bottom
};
const scrollTo = target => {
  smoothScroll({
    scrollTo: (SCROLL_TARGETS[target] || top).value,
    updateHistory: false,
    offset: -100
  });
};

const props = defineProps({
  profile: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    default: true
  }
});

const local = ref({ ...FIELDS });
local.value = pick(Object.keys(FIELDS), props.profile);

const serverSideErrors = ref([]);
const alert = ref(alertInitializer());
const sending = ref(false);
const {
  platforms,
  selectedPlatform,
  onInputPlatformID,
  removeProfilePlatform
} = useSocialMedia({ profile: props.profile });

const currentDateFormat = computed(() => {
  var shortFormat = store.state.profile.shortDateFormat;

  if (shortFormat === "DDD") {
    return "MM/dd/yyyy";
  } else {
    return shortFormat || "MM/dd/yyyy";
  }
});

const currentDatePlaceholder = computed(() => {
  return currentDateFormat.value.replaceAll(/[mdy]/gi, "_");
});

if (local.value.dateOfBirth != null && local.value.dateOfBirth != "") {
  local.value.dateOfBirth = formattedShortDateFromISO(
    local.value.dateOfBirth,
    currentDateFormat.value
  );
}

const { state, v$ } = useProfileValidation({ initialValues: local });

const persist = params => store.dispatch("profile/update", params);

const submit = target => event => {
  event.preventDefault();
  serverSideErrors.value = [];
  sending.value = true;
  currentScrollTarget.value = target;

  var payload = snakeCasedKeys({
    ...state,
    socialProfiles: platforms.value.social.urls,
    elearningProfiles: platforms.value.elearning.urls
  });

  if (payload.country === "" || payload.country == null) {
    payload = omit(["country"], payload);
  }

  if (payload.website === "" || payload.website == null) {
    payload = omit(["website"], payload);
  }

  if (payload.pronouns === "" || payload.pronouns == null) {
    payload = omit(["pronouns"], payload);
  }

  if (payload.date_of_birth === "" || payload.date_of_birth == null) {
    payload = omit(["date_of_birth"], payload);
  } else {
    payload.date_of_birth = ISODateFromFormattedShortDate(
      payload.date_of_birth,
      currentDateFormat.value
    );
  }

  persist({ payload })
    .then(() => {
      sending.value = false;
      alert.value = {
        message: t("user.sections.account_settings.profile.success_message"),
        type: "success"
      };
      setTimeout(() => {
        alert.value = alertInitializer();
        currentScrollTarget.value = undefined;
      }, 5000);
      emit("success", { what: "personal" });
    })
    .catch(error => {
      sending.value = false;
      const { response } = error;
      const errorDetail = response?.data?.details || "unmapped_constraint";
      const errorHint = safeJSONParse(response?.data?.hint);
      const errorField =
        errorDetail.match(/^(?:(?<field>.*)__)/)?.groups?.field || "profile";
      const errorMessage = i18n
        .t(`db.${errorDetail}`, errorHint)
        ?.replace(/\.$/, "");

      serverSideErrors.value = {
        [errorField]: [errorMessage]
      };

      alert.value = {
        message: errorMessage,
        type: "danger"
      };

      emit("error", {
        what: "instructor",
        where: errorField,
        why: errorMessage
      });
    });
};

watch(props.profile, (n, o) => {
  local.value = pick(Object.keys(FIELDS), n);
});

const emailConfirmed = computed(() => store.state.userAccount.confirmed_at);

const submitOrModal = scrollTarget => event => {
  if (emailConfirmed.value) {
    submit(scrollTarget)(event);
    scrollTo(scrollTarget);
  } else {
    store.commit("emailVerificationModalStatus/change", { open: true });
  }
};
</script>

<template>
  <div>
    <div
      ref="top"
      class="font-bold uppercase text-xs text-foreground-medium tracking-widest mb-6"
    >
      Personal Data
    </div>

    <alert
      v-if="currentScrollTarget === 'top' && alert"
      class-list="text-xs pL10 mb-4"
      v-bind="alert"
    ></alert>

    <form action="#" ref="form">
      <keep-alive>
        <avatar class="mb-6" />
      </keep-alive>

      <text-input-field
        class="mb-4"
        field="name"
        :errors="[
          ...v$.name.$errors.map(e => e.$message),
          ...(serverSideErrors.name || [])
        ]"
        :disabled="loading || sending"
        :label="t('user.forms.profile.name_field_label')"
        input-size="medium"
        :input-block="true"
        :input-placeholder="t('user.forms.profile.name_field_placeholder')"
        v-model="v$.name.$model"
      >
      </text-input-field>

      <text-input-field
        class="mb-4"
        field="dateOfBirthday"
        :errors="[
          ...v$.dateOfBirth.$errors.map(e => e.$message),
          ...(serverSideErrors.dateOfBirth || [])
        ]"
        :disabled="loading || sending"
        :label="t('user.forms.profile.date_of_birth_field_label')"
        input-size="medium"
        :input-block="true"
        :input-placeholder="
          t('user.forms.profile.date_of_birth_field_placeholder', {
            format: currentDateFormat.toLowerCase()
          })
        "
        v-model="v$.dateOfBirth.$model"
        :mask="{
          alias: 'datetime',
          clearIncomplete: true,
          inputFormat: currentDateFormat.toLowerCase(),
          placeholder: currentDatePlaceholder
        }"
      >
      </text-input-field>

      <radio-input-field
        class="mb-4"
        field="pronouns"
        :errors="[
          ...v$.pronouns.$errors.map(e => e.$message),
          ...(serverSideErrors.pronouns || [])
        ]"
        :disabled="loading || sending"
        :label="t('user.forms.profile.pronouns_field_label')"
        v-model="v$.pronouns.$model"
        :options="[
          {
            key: 'he',
            value: t('user.forms.profile.pronouns_field_options_he')
          },
          {
            key: 'she',
            value: t('user.forms.profile.pronouns_field_options_she')
          },
          {
            key: 'they',
            value: t('user.forms.profile.pronouns_field_options_they')
          }
        ]"
      >
      </radio-input-field>

      <input
        :disabled="loading || sending || v$.$invalid"
        type="submit"
        @click.prevent="e => submitOrModal('top')(e)"
        class="mt-6 m-button m-button--blue-medium m-button--primary m-button--md"
        :value="t('user.forms.profile.submit')"
      />

      <div class="mx-border-t my-10"></div>

      <div
        ref="middle"
        class="font-bold uppercase text-xs text-foreground-medium tracking-widest mb-6"
      >
        About me
      </div>

      <alert
        v-if="currentScrollTarget === 'middle' && alert"
        class-list="text-xs pL10 mb-4"
        v-bind="alert"
      ></alert>

      <text-input-field
        class="mb-4"
        field="shortbio"
        :errors="[
          ...v$.shortBio.$errors.map(e => e.$message),
          ...(serverSideErrors.shortbio || [])
        ]"
        :disabled="loading || sending"
        :label="t('user.forms.profile.shortbio_field_label')"
        input-size="medium"
        :input-block="true"
        :input-placeholder="t('user.forms.profile.shortbio_field_placeholder')"
        v-model="v$.shortBio.$model"
      />

      <text-area-field
        class="mb-4"
        field="longbio"
        :errors="[
          ...v$.longBio.$errors.map(e => e.$message),
          ...(serverSideErrors.longbio || [])
        ]"
        :disabled="loading || sending"
        :label="t('user.forms.profile.about_field_label')"
        :placeholder="t('user.forms.profile.about_field_placeholder')"
        height="200px"
        v-model="v$.longBio.$model"
      >
      </text-area-field>

      <text-input-field
        field="organization"
        :disabled="loading || sending"
        :label="t('user.forms.profile.organization_field_label')"
        input-size="medium"
        :input-block="true"
        :input-placeholder="
          t('user.forms.profile.organization_field_placeholder')
        "
        v-model="v$.organization.$model"
      ></text-input-field>

      <input
        :disabled="loading || sending || v$.$invalid"
        type="submit"
        @click.prevent="e => submitOrModal('middle')(e)"
        class="mt-6 m-button m-button--blue-medium m-button--primary m-button--md"
        :value="t('user.forms.profile.submit')"
      />

      <div class="mx-border-t my-10"></div>

      <div
        ref="bottom"
        class="font-bold uppercase text-xs text-foreground-medium tracking-widest mb-6"
      >
        Social Presence and Interests
      </div>

      <alert
        v-if="currentScrollTarget === 'bottom' && alert"
        class-list="text-xs pL10 mb-4"
        v-bind="alert"
      ></alert>

      <text-input-field
        class="mb-4"
        field="website"
        :errors="[
          ...v$.website.$errors.map(e => e.$message),
          ...(serverSideErrors.website || [])
        ]"
        :disabled="loading || sending"
        :label="t('user.forms.profile.website_field_label')"
        input-size="medium"
        :input-block="true"
        :input-placeholder="t('user.forms.profile.website_field_placeholder')"
        v-model="v$.website.$model"
      >
      </text-input-field>

      <div>
        <social-presence
          class="mt-4"
          :server-side-errors="serverSideErrors"
          :platforms="platforms"
          :onSocialPlatformSelection="selectedPlatform('social')"
          :onSocialPlatformInput="onInputPlatformID"
          :onSocialPlatformRemoval="removeProfilePlatform('social')"
        />

        <div class="mt-4">
          <slot name="interests"></slot>
        </div>
      </div>

      <input
        :disabled="loading || sending || v$.$invalid"
        type="submit"
        @click.prevent="e => submitOrModal('bottom')(e)"
        class="mt-6 m-button m-button--blue-medium m-button--primary m-button--md"
        :value="t('user.forms.profile.submit')"
      />
    </form>
  </div>
</template>

<style lang="scss" scoped>
.user\:platform-remover {
  display: inline-block;
  cursor: pointer;
  width: 1.125em;
  height: 1.125em;
  fill: rgb(var(--foreground-medium));
  &:hover {
    fill: rgb(var(--red-medium));
  }
}
</style>
