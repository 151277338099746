import axios from "axios";
import cookieJar from "~src/config/cookieJar";
import env from "~src/config/environment";

// create api endpoints
const api = axios.create({
  baseURL: env.apiURL,
  withCredentials: true,
  timeout: 10000
});

const railsApi = axios.create({
  baseURL: env.webAppApiURL,
  withCredentials: true,
  timeout: 10000
});

// create public endpoints
const crossOriginXHR = axios.create({
  baseURL: env.webAppURL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    "X-CSRF-TOKEN": cookieJar.get("_csrf_token"),
    Accept: "application/json"
  },
  withCredentials: true,
  timeout: 10000
});

export { api, railsApi, crossOriginXHR };
