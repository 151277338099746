import { computed } from "vue";
import capitalize from "lodash/capitalize";
import { formattedShortDate } from "~src/lib/utils/tz.js";
import { DateTime } from "luxon";

export const headers = [
  { name: "order_code", alias: "Order ID" },
  { name: "date", alias: "Date" },
  { name: "total_price", alias: "Total price" },
  { name: "payment_type", alias: "Payment type" },
  { name: "order_state", alias: "Status" },
  { name: "description", alias: "Description" },
  { name: "receipt_url", alias: "" }
];
export const formattedPaymentType = x => {
  if (!x) {
    return null;
  }
  if (x.payment_method == "credit_card" && x.payment_method_data["card"]) {
    return (
      capitalize(x.payment_method_data["card"]["brand"]) +
      " ****" +
      x.payment_method_data["card"]["last4"]
    );
  } else {
    return x.payment_type;
  }
};

export const formattedDescription = cohort => {
  return `${cohort.course.code} - ${cohort.name}`;
};

export function usePurchaseHistory({ orders, profile }) {
  const purchaseData = computed(() => {
    return orders.value.map(x => {
      const receiptUrl = (x.payments[0] || { responses: [] }).responses[0]
        .receipt_url;

      const cohort = x.line_items[0].cohort;

      return {
        order_code: x.code,
        date: formattedShortDate(x.created_at, profile),
        total_price: "$" + x.total,
        payment_type: formattedPaymentType(x.payments[0]),
        order_state:
          x.state === "approved"
            ? receiptUrl
              ? "approved"
              : "pre-approved"
            : x.state,
        description: formattedDescription(cohort),
        receipt_url: receiptUrl
      };
    });
  });

  return {
    purchaseData
  };
}
