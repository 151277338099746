<script setup>
import Modal from "~external/v3/Modal.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import useResendConfirmationLink from "~src/composables/resendConfirmationLink";

const props = defineProps({
  opened: {
    type: Boolean,
    required: true,
    default: false
  }
});

const store = useStore();
const opened = computed(() => props.opened);
const email = computed(
  () =>
    store.state.userAccount.unconfirmed_email || store.state.userAccount.email
);
const name = "emailVerificationModal";
const emit = defineEmits(["close"]);
const resendConfirmationLink = useResendConfirmationLink();

const submit = () => {
  resendConfirmationLink();
  emit("close", name);
};

const close = e => {
  emit("close", e);
};
</script>

<template>
  <modal
    :name="name"
    :opened="opened"
    @close="close"
    :maskClasses="['grid grid-cols-12 gap-x-2']"
    :wrapperClasses="[
      'bg-transparent',
      'max-md:px-3',
      'items-center',
      'justify-center',
      'col-span-12',
      'md:col-span-6',
      'md:col-start-4',
      'xl:col-span-4',
      'xl:col-start-5',
      'my-auto'
    ]"
    :containerClasses="['p-[1em]']"
  >
    <template #body>
      <div class="flex items-center m-8">
        <div class="max-md:mt-32 space-y-2">
          <p class="text-2xl font-bold text-center mt-4">
            You haven't confirmed your e-mail yet
          </p>

          <p class="text-center leading-tight">
            Click on the button below to send a confirmation link to
            <span class="font-bold">{{ email }}</span>
            so we can make sure you own it. Please check your inbox and follow
            the instructions
          </p>

          <input
            @click="submit"
            class="mt-6 m-button m-button--blue-medium m-button--primary m-button--md w-full block"
            value="Resend Confimation Link"
          />
        </div>
      </div>
    </template>
  </modal>
</template>
