import { ref, unref, watch } from "vue";
import { useI18n } from "~src/lib/i18n";

export default function useCountries() {
  const countries = ref([]);
  const { i18n } = useI18n();

  const computeCountries = () => {
    countries.value = Object.entries(
      unref(i18n.tm("iso3166_1_alpha2_codes"))
    ).sort((a, b) => a[1].localeCompare(b[1]));
  };

  computeCountries();

  watch(i18n.locale, computeCountries);

  return {
    countries
  };
}
