<template>
  <svg
    :class="iconClasses"
    class="inline-block"
    :style="style"
    viewbox="0 0 24 24"
  >
    <use :href="path" />
  </svg>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      scope: {
        type: String,
        default: "icons"
      },
      width: {
        type: String,
        default: "1em"
      },
      height: {
        type: String,
        default: "var(--width, 1em)"
      },
      transform: {
        type: String,
        default: "initial"
      },
      cursor: {
        type: String,
        defaul: "initial"
      },
      iconClasses: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    computed: {
      style() {
        return {
          transform: this.transform,
          transition: "all .5s",
          width: `var(--width, ${this.width})`,
          height: `var(--height, ${this.height})`,
          flex: `0 0 var(--width, ${this.width})`,
          cursor: this.cursor
        };
      },
      path() {
        return `#${this.scope}-${this.name}`;
      }
    }
  };
</script>
