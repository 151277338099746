import toLower from "lodash/toLower";
import { isURL } from "~utils";
import { i18n } from "~src/lib/i18n";
import * as validators from "@vuelidate/validators";
import socialValidations from "~validations/social";

const { createI18nMessage, helpers } = validators;
export const withI18nMessage = createI18nMessage({ t: i18n.global.t });

export const email = withI18nMessage(validators.email);
export const required = withI18nMessage(validators.required);
export const minLength = withI18nMessage(validators.minLength, {
  withArguments: true
});
export const maxLength = withI18nMessage(validators.maxLength, {
  withArguments: true
});

export const usernameFormat = withI18nMessage(value => {
  if (value && !value.match(/[^0-9a-zA-Z.\-_]/)) {
    return true;
  }

  return false;
});

export const usernameConsecutiveDash = withI18nMessage(value => {
  if (value && !value.match(/--/)) {
    return true;
  }
  return false;
});

export const usernameConsecutiveUnderline = withI18nMessage(value => {
  if (value && !value.match(/__/)) {
    return true;
  }
  return false;
});

export const usernameBoundaryDash = withI18nMessage(value => {
  if (value && !(value.match(/^-/) || value.match(/-$/))) {
    return true;
  }

  return false;
});

export const usernameBoundaryUnderline = withI18nMessage(value => {
  if (value && !(value.match(/^_/) || value.match(/_$/))) {
    return true;
  }

  return false;
});

export const usernameLowercased = withI18nMessage(value => {
  if (value && value == toLower(value)) {
    return true;
  }

  return false;
});

export const url = allowEmpty =>
  withI18nMessage(value => {
    if (allowEmpty) {
      return !helpers.req(value) || isURL(value);
    } else {
      return isURL(value);
    }
  });

export const platformFormat = ({ platform, type }) =>
  withI18nMessage(value => {
    const validation = socialValidations[platform].validator(value);
    if (value && validation.valid) {
      return {
        $valid: true,
        extraParams: {
          platform,
          valid: true,
          id: validation.id,
          type,
          url: socialValidations[platform].canonicalURL(value)
        }
      };
    }
    return {
      $valid: false,
      extraParams: {
        platform,
        type,
        valid: false,
        id: value
      }
    };
  });

export const fullName = withI18nMessage(value => helpers.regex(/^\w+( \w+)*/));

// export function mismatch(value, target, { form, field }) {
//   if (value === form[target]) {
//     return true;
//   }

//   return i18n.global.t(`validations.messages.mismatch`, { field, target });
// }

export default {
  // mismatch,
  required,
  url,
  minLength,
  maxLength,
  usernameFormat,
  usernameConsecutiveDash,
  usernameConsecutiveUnderline,
  usernameBoundaryDash,
  usernameBoundaryUnderline,
  usernameLowercased,
  platformFormat
};
