<script setup>
import { unref, ref, computed, watch, inject } from "vue";
import { useStore } from "vuex";
import pickBy from "ramda/es/pickBy";
import pick from "ramda/es/pick";
import TextAreaField from "~components/form_fields/TextArea.vue";
import TextInputField from "~components/form_fields/TextInput.vue";
import RadioInputField from "~components/form_fields/RadioInput.vue";
import SocialPresence from "~components/form_fields/SocialPresence.vue";
import useScholarshipValidation from "~src/composables/scholarshipValidation";
import useCountries from "~src/composables/countries";
import useSocialMedia from "~src/composables/socialMedia";
import { snakeCasedKeys } from "~utils";

const { countries } = useCountries();
const serverSideErrors = ref([]);
const sending = ref(false);
const loading = ref(false);
const applicationConfirmation = ref(false);
const emit = defineEmits(["submitted"]);

const store = useStore();
const user = unref(store.state.userAccount);

const EMPLOYED_OPTIONS = [
  {
    key: "yes",
    value: "Yes"
  },
  {
    key: "no",
    value: "No"
  },
  {
    key: "partially",
    value: "Partially"
  },
  {
    key: "in_a_different_industry",
    value: "In a different industry"
  }
];

const HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS = [
  { key: "friend", value: "Recommended by friend or colleague" },
  { key: "employer", value: "My employer/workplace" },
  { key: "influencer", value: "Digital Influencer" },
  { key: "google", value: "Google Search" },
  { key: "podcast", value: "Recommendation from a podcast or youtube channel" },
  { key: "blog", value: "Blog or publication" },
  { key: "ad", value: "Online Advertisement" },
  { key: "networking", value: "Networking Event" }
];

const props = defineProps({
  cohort: {
    type: Object,
    required: true
  },
  scholarship: {
    type: Object,
    required: true
  },
  operation: {
    type: String,
    require: true,
    default: "create"
  }
});

const { state, v$ } = useScholarshipValidation({
  initialValues: {
    name: unref(props.scholarship).name || store.state.profile.name,
    email: unref(props.scholarship).email || store.state.userAccount.email,
    country: unref(props.scholarship).country || store.state.address.country,
    company: unref(props.scholarship).company,
    employed: unref(props.scholarship).employed,
    employment_description: unref(props.scholarship).employment_description,
    tell_us_about_yourself: unref(props.scholarship).tell_us_about_yourself,
    why_do_you_want_this_scholarship: unref(props.scholarship)
      .why_do_you_want_this_scholarship,
    anything_you_would_like_us_to_know: unref(props.scholarship)
      .anything_you_would_like_us_to_know,
    how_did_you_hear_about_us: unref(props.scholarship)
      .how_did_you_hear_about_us
  }
});

const {
  platforms,
  selectedPlatform,
  onInputPlatformID,
  removeProfilePlatform
} = useSocialMedia({ profile: store.state.profile });

const currentState = ref(unref(props.scholarship)._state || "draft");

const create = async payload => {
  if (props.operation === "create") {
    const application = await store.dispatch("application/create", {
      payload: {
        ...pick(
          [
            "applicable_id",
            "applicable_type",
            "name",
            "email",
            "country",
            "social_networks",
            "state"
          ],
          payload
        ),
        user_account_id: user.id
      }
    });

    const scholarship = await store.dispatch("scholarship/create", {
      payload: {
        ...pick(
          [
            "employed",
            "employment_description",
            "company",
            "how_did_you_hear_about_us",
            "tell_us_about_yourself",
            "why_do_you_want_this_scholarship",
            "anything_you_would_like_us_to_know"
          ],
          payload
        ),
        _user_account_id: user.id,
        application_id: application.id
      }
    });

    return scholarship;
  } else {
    const application = await store.dispatch("application/update", {
      id: unref(props.scholarship).application.id,
      payload: {
        ...pick(
          [
            "applicable_id",
            "applicable_type",
            "name",
            "email",
            "country",
            "social_networks",
            "state"
          ],
          payload
        ),
        user_account_id: user.id
      }
    });

    const scholarship = await store.dispatch("scholarship/update", {
      id: unref(props.scholarship).id,
      payload: {
        ...pick(
          [
            "employed",
            "employment_description",
            "company",
            "how_did_you_hear_about_us",
            "tell_us_about_yourself",
            "why_do_you_want_this_scholarship",
            "anything_you_would_like_us_to_know"
          ],
          payload
        ),
        application_id: application.id,
        _user_account_id: user.id
      }
    });

    return scholarship;
  }
};

const submit = applicationState => () => {
  serverSideErrors.value = [];
  if (applicationState != "draft") {
    sending.value = true;
  }

  var data = snakeCasedKeys(state);

  create(
    pickBy(value => value != null && value.toString().trim() != "", {
      applicable_id: unref(props.cohort).id,
      applicable_type: "Cohort",
      state: applicationState || "draft",
      name: data.name,
      email: data.email,
      country: data.country,
      social_networks: Object.keys(platforms.value.social.urls).reduce(
        (acc, key) =>
          platforms.value.social.urls[key]
            ? { ...acc, [key]: platforms.value.social.urls[key] }
            : acc,
        {}
      ),
      employed: data.employed,
      employment_description: data.employment_description,
      company: data.company,
      how_did_you_hear_about_us: data.how_did_you_hear_about_us,
      tell_us_about_yourself: data.tell_us_about_yourself,
      why_do_you_want_this_scholarship: data.why_do_you_want_this_scholarship,
      anything_you_would_like_us_to_know:
        data.anything_you_would_like_us_to_know
    })
  )
    .then(s => {
      currentState.value = applicationState;
      sending.value = false;
      if (applicationState) {
        emit(applicationState);
      }

      // alert.value = {
      //   message: t("user.sections.account_settings.profile.success_message"),
      //   type: "success"
      // };
      // setTimeout(() => (alert.value = alertInitializer()), 5000);
      // emit("success", { what: "personal" });
    })
    .catch(error => {
      if (applicationState != "draft") {
        sending.value = false;
        const { response } = error;
      }
      // const errorDetail = response?.data?.details || "unmapped_constraint";
      // const errorHint = safeJSONParse(response?.data?.hint);
      // const errorField =
      //   errorDetail.match(/^(?:(?<field>.*)__)/)?.groups?.field || "profile";
      // const errorMessage = i18n
      //   .t(`db.${errorDetail}`, errorHint)
      //   ?.replace(/\.$/, "");

      // serverSideErrors.value = {
      //   [errorField]: [errorMessage]
      // };

      // alert.value = {
      //   message: errorMessage,
      //   type: "danger"
      // };

      // emit("error", {
      //   what: "instructor",
      //   where: errorField,
      //   why: errorMessage
      // });
    });
};

const draft = submit("draft");
const submitted = submit("submitted");

const draftInterval = ref(undefined);

if (currentState.value != "submitted") {
  draftInterval.value = setInterval(draft, 10000);
}

watch(currentState, (n, o) => {
  if (n == "submitted") {
    clearInterval(draftInterval.value);
    draftInterval.value = undefined;
  }
});
</script>

<template>
  <form action="#" ref="form" style="position: relative">
    <div
      v-if="currentState != 'draft'"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-color: var(--surface);
        z-index: 1;
      "
    ></div>
    <div class="space-y-6 mt-8">
      <text-input-field
        field="name"
        :errors="[...v$.name.$errors.map(e => e.$message)]"
        :disabled="loading || sending"
        label="Name"
        labelClass="text-lg"
        :required="true"
        input-size="medium"
        :input-block="true"
        input-placeholder="Type your name"
        v-model="v$.name.$model"
      >
      </text-input-field>

      <text-input-field
        field="email"
        :errors="[...v$.email.$errors.map(e => e.$message)]"
        :disabled="loading || sending"
        label="Email"
        labelClass="text-lg"
        :required="true"
        input-size="medium"
        :input-block="true"
        input-placeholder="Type your email"
        v-model="v$.email.$model"
      >
      </text-input-field>

      <div class="m-form-field">
        <div class="m-form-field__label m-form-field__label--over">
          <label class="text-lg">Country <span>(required)</span></label>
        </div>
        <div class="m-select">
          <select v-model="v$.country.$model">
            <option value="">Choose your country</option>
            <option
              v-for="[code, country] in unref(countries)"
              :value="code"
              :key="code"
            >
              {{ country }}
            </option>
          </select>
        </div>
      </div>

      <div class="m-form-field">
        <div class="m-form-field__label m-form-field__label--over">
          <label class="text-lg"
            >Are you currently employed? <span>(required)</span></label
          >
        </div>
        <div class="m-select">
          <select v-model="v$.employed.$model">
            <option
              v-for="{ key, value } in EMPLOYED_OPTIONS"
              :value="key"
              :key="key"
              :selected="v$.employed.$model == key"
            >
              {{ value }}
            </option>
          </select>
        </div>
      </div>

      <text-input-field
        v-if="v$.employed.$model != 'no'"
        field="company"
        :errors="[...v$.company.$errors.map(e => e.$message)]"
        :disabled="loading || sending"
        label="Company"
        labelClass="text-lg"
        :required="true"
        input-size="medium"
        :input-block="true"
        input-placeholder="Type your company"
        v-model="v$.company.$model"
      >
      </text-input-field>

      <text-area-field
        v-if="v$.employed.$model != 'no'"
        field="employment_description"
        :errors="[
          ...v$.employment_description.$errors.map(e => e.$message),
          ...(serverSideErrors.employment_description || [])
        ]"
        :disabled="loading || sending"
        label="Describe your employment"
        labelClass="text-lg"
        placeholder="Describe your employment"
        height="200px"
        :maxLength="1000"
        v-model="v$.employment_description.$model"
      >
      </text-area-field>

      <social-presence
        :minRequired="1"
        labelClass="text-lg"
        :server-side-errors="serverSideErrors"
        :platforms="platforms"
        :onSocialPlatformSelection="selectedPlatform('social')"
        :onSocialPlatformInput="onInputPlatformID"
        :onSocialPlatformRemoval="removeProfilePlatform('social')"
      />

      <text-area-field
        field="tell_us_about_yourself"
        :errors="[
          ...v$.tell_us_about_yourself.$errors.map(e => e.$message),
          ...(serverSideErrors.tell_us_about_yourself || [])
        ]"
        :disabled="loading || sending"
        label="Tell us about yourself"
        labelClass="text-lg"
        placeholder="Tell us about yourself"
        height="200px"
        :required="true"
        :maxLength="300"
        v-model="v$.tell_us_about_yourself.$model"
      >
      </text-area-field>

      <text-area-field
        field="why_do_you_want_this_scholarship"
        :errors="[
          ...v$.why_do_you_want_this_scholarship.$errors.map(e => e.$message),
          ...(serverSideErrors.why_do_you_want_this_scholarship || [])
        ]"
        :disabled="loading || sending"
        :required="true"
        label="Why do you want this scholarship?"
        labelClass="text-lg"
        placeholder="Tell us why you want this scholarship"
        height="200px"
        :maxLength="1000"
        v-model="v$.why_do_you_want_this_scholarship.$model"
      >
      </text-area-field>

      <text-area-field
        field="anything_you_would_like_us_to_know"
        :errors="[
          ...v$.anything_you_would_like_us_to_know.$errors.map(e => e.$message),
          ...(serverSideErrors.anything_you_would_like_us_to_know || [])
        ]"
        :disabled="loading || sending"
        label="Anything you would like us to know"
        labelClass="text-lg"
        placeholder="Tell us anything you would like us to know that might help with your application"
        height="200px"
        :maxLength="1000"
        v-model="v$.anything_you_would_like_us_to_know.$model"
      >
      </text-area-field>

      <div class="m-form-field">
        <div class="m-form-field__label m-form-field__label--over">
          <label class="text-lg"
            >How did you hear about us? <span>(required)</span></label
          >
        </div>
        <div class="m-select">
          <select v-model="v$.how_did_you_hear_about_us.$model">
            <option
              v-for="{ key, value } in HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS"
              :value="key"
              :key="key"
              :selected="key == v$.how_did_you_hear_about_us.$model"
            >
              {{ value }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="currentState == 'draft'">
      <div
        class="mt-12 m-checkbox flex items-center text-base"
        @click="applicationConfirmation = !applicationConfirmation"
      >
        <div class="m-checkbox__selector">
          <input
            :checked="applicationConfirmation"
            value="yes"
            type="checkbox"
          />
          <label></label>
        </div>
        <span class="m-checkbox__label"
          >By applying for this scholarship, I hereby certify that the
          information provided in this form is complete, true and correct.</span
        >
      </div>

      <input
        :disabled="
          loading ||
          sending ||
          v$.$invalid ||
          !applicationConfirmation ||
          platforms.social.added.length < 1
        "
        @click.prevent="submitted"
        type="submit"
        class="mt-4 mb-12 m-button m-button--blue-medium m-button--primary m-button--md w-full block"
        value="Apply for scholarship"
      />
    </div>
    <div class="mb-12" v-else></div>
  </form>
</template>
