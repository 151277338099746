<script setup>
import { useRoute } from "vue-router";
import { urls, paths } from "~config/environment";

const logoutURL = `${urls.webAppURL}${paths.logOutPath}`;
const route = useRoute();
</script>

<template>
  <div class="flex flex-col">
    <div class="flex flex-col">
      <div class="flex relative">
        <div
          class="absolute bg-blue-medium h-4 top-1 rounded"
          style="width: 4px"
          v-if="route.name == 'Profile'"
        ></div>
        <div
          class="ml-3 flex items-center hover:text-blue-medium"
          :class="{ 'text-blue-medium': route.name == 'Profile' }"
        >
          <svg class="w-[1em] h-[1em] mr-2" style="color: inherit">
            <use href="#icons-profile"></use>
          </svg>
          <router-link style="color: inherit" :to="{ name: 'Profile' }"
            >Profile</router-link
          >
        </div>
      </div>
      <div class="flex relative mt-6">
        <div
          class="absolute bg-blue-medium h-4 top-1 rounded"
          style="width: 4px"
          v-if="route.name == 'AccountSettings'"
        ></div>
        <div
          class="ml-3 flex items-center hover:text-blue-medium hover:fill-blue-medium"
          :class="{
            'fill-blue-medium': route.name == 'AccountSettings',
            'text-blue-medium': route.name == 'AccountSettings',
            'fill-foreground-high': route.name != 'AccountSettings'
          }"
        >
          <svg
            class="w-[1em] h-[1em] mr-2"
            style="fill: inherit; color: inherit"
          >
            <use href="#icons-gear"></use>
          </svg>
          <router-link style="color: inherit" :to="{ name: 'AccountSettings' }"
            >Account Settings</router-link
          >
        </div>
      </div>
    </div>
    <div class="mx-border-t mt-8"></div>
    <div class="flex flex-col">
      <div class="mt-8 font-bold ml-3">ClasspertX</div>
      <div class="mt-4 flex relative">
        <div
          class="absolute bg-blue-medium h-4 top-1 rounded"
          style="width: 4px"
          v-if="route.name == 'PurchaseHistory'"
        ></div>
        <div
          class="ml-3 flex items-center hover:fill-blue-medium hover:text-blue-medium"
          :class="{
            'fill-blue-medium': route.name == 'PurchaseHistory',
            'text-blue-medium': route.name == 'PurchaseHistory',
            'fill-foreground-high': route.name != 'PurchaseHistory'
          }"
        >
          <svg
            class="w-[1em] h-[1em] mr-2"
            style="fill: inherit; color: inherit"
          >
            <use href="#icons-receipt"></use>
          </svg>
          <router-link style="color: inherit" :to="{ name: 'PurchaseHistory' }"
            >Purchase History</router-link
          >
        </div>
      </div>

      <div
        class="uppercase text-foreground-medium text-xs tracking-widest mt-8 ml-3"
      >
        My Courses
      </div>
      <div class="mt-4 flex relative">
        <div
          class="absolute bg-blue-medium h-4 top-1 rounded"
          style="width: 4px"
          v-if="route.name == 'MyCoursesCohorts'"
        ></div>
        <div
          class="ml-3 flex items-center hover:fill-blue-medium hover:text-blue-medium"
          :class="{
            'fill-blue-medium': route.name == 'MyCoursesCohorts',
            'text-blue-medium': route.name == 'MyCoursesCohorts',
            'fill-foreground-high': route.name != 'MyCoursesCohorts'
          }"
        >
          <svg
            class="w-[1em] h-[1em] mr-2"
            style="fill: inherit; color: inherit"
          >
            <use href="#icons-cohort"></use>
          </svg>
          <router-link style="color: inherit" :to="{ name: 'MyCoursesCohorts' }"
            >Cohorts</router-link
          >
        </div>
      </div>
      <div class="mt-4 flex relative">
        <div
          class="absolute bg-blue-medium h-4 top-1 rounded"
          style="width: 4px"
          v-if="route.name == 'MyCoursesOnDemand'"
        ></div>
        <div
          v-if="false"
          class="ml-3 flex items-center hover:fill-blue-medium hover:text-blue-medium"
          :class="{
            'fill-blue-medium': route.name == 'MyCoursesOnDemand',
            'text-blue-medium': route.name == 'MyCoursesOnDemand',
            'fill-foreground-high': route.name != 'MyCoursesOnDemand'
          }"
        >
          <svg
            class="w-[1em] h-[1em] mr-2"
            style="fill: inherit; color: inherit"
          >
            <use href="#icons-on-demand"></use>
          </svg>
          <router-link
            style="color: inherit"
            :to="{ name: 'MyCoursesOnDemand' }"
            >On Demand</router-link
          >
        </div>
      </div>
    </div>
    <div class="mx-border-t mt-8"></div>
    <div class="mt-8 flex relative">
      <div
        class="ml-3 flex items-center hover:fill-blue-medium hover:text-blue-medium fill-foreground-high"
      >
        <svg class="w-[1em] h-[1em] mr-2" style="fill: inherit; color: inherit">
          <use href="#icons-logout"></use>
        </svg>
        <a :href="logoutURL" style="color: inherit">Logout</a>
      </div>
    </div>
  </div>
</template>
