import pipe from "ramda/es/pipe";
import evolve from "ramda/es/evolve";
import mergeLeft from "ramda/es/mergeLeft";
import omit from "ramda/es/omit";
import { api } from "~src/config/axios/instances";
import { normalizeUrl, update, shallowCamelCasedKeys } from "~utils";

const state = {
  loaded: false,
  id: undefined,
  address_type: undefined,
  name: undefined,
  street_line1: undefined,
  street_line2: undefined,
  zip_code: undefined,
  country: undefined,
  state: undefined,
  city: undefined,
  phone_number: undefined
};

const loaded = mergeLeft({ loaded: true });

const mutations = {
  get(state, payload) {
    pipe(shallowCamelCasedKeys, loaded, update(state))(payload);
  },
  create(state, payload) {
    pipe(shallowCamelCasedKeys, update(state))(payload);
  },
  update(state, payload) {
    pipe(shallowCamelCasedKeys, update(state))(payload);
  },
  post(state, { field, value }) {
    state[field] = value;
  }
};

const actions = {
  get({ commit }) {
    return new Promise((resolve, reject) => {
      api.get(`/account/v1/app/addresses?limit=1`).then(
        response => {
          const payload = response.data[0];

          commit("get", payload || {});
          resolve(payload);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  create({ commit }, { payload }) {
    return new Promise((resolve, reject) => {
      api
        .post(`/account/v1/app/addresses`, omit(["loaded"], payload), {
          headers: {
            Prefer: "return=representation",
            Accept: "application/vnd.pgrst.object+json"
          }
        })
        .then(
          response => {
            commit("create", payload);
            resolve(response.data[0]);
          },
          error => {
            reject(error);
          }
        );
    });
  },
  update({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      api
        .patch(
          `/account/v1/app/addresses?id=eq.${id}`,
          omit(["loaded"], payload),
          {
            headers: {
              Prefer: "return=representation",
              Accept: "application/vnd.pgrst.object+json"
            }
          }
        )
        .then(
          response => {
            commit("update", payload);
            resolve(response.data[0]);
          },
          error => {
            reject(error);
          }
        );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
