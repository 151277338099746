import { createStore, createLogger } from "vuex";
import modules from "./modules";

const debug = import.meta.env.DEV;

export default createStore({
  modules,
  strict: debug,
  plugins: debug ? [createLogger()] : []
});
