<script setup>
import Icon from "~external/Icon.vue";
import map from "lodash/map";
import reduce from "lodash/reduce";
import without from "lodash/without";
import values from "lodash/values";
import last from "lodash/last";
import SectionFrame from "~components/SectionFrame.vue";
import { useStore } from "vuex";
import { useI18n } from "~src/lib/i18n";
import { unref, ref, computed, onMounted, onBeforeUnmount } from "vue";

const store = useStore();
const { t, i18n } = useI18n();

const alertInitializer = () => ({ message: "", type: "" });
const props = defineProps({
  cptClassList: {
    type: String,
    required: false,
    default: ""
  },
  placeholder: {
    type: String,
    default: "Search for subjects"
  }
});

const loading = ref(true);
const duplicate = ref(false);
const interests = ref([]);
const options = ref({});
const arrowCounter = ref(-1);
const tags = ref([]);
const tagSearch = ref({
  lang: "en",
  timeout: undefined,
  query: undefined
});
const root = ref(null);
const alert = alertInitializer();

const change = params => store.commit("profile/update", params);
const initProfile = params => store.dispatch("profile/get", params);
const persist = params => store.dispatch("profile/update", params);
const loadOptions = () => {
  const tags = unref(i18n.messages)["en"]["tags"];

  return reduce(
    tags,
    (r, v, k) => {
      r[k] = {
        tag: k,
        label: v,
        $isDisabled: store.state.profile?.interests?.includes(k) ? true : false
      };
      return r;
    },
    {}
  );
};

const slug = tag => {
  return store.state.locale.webAppURL(
    `/${tag.replace(
      "_",
      "-"
    )}?utm_source=user_dashboard&utm_campaign=${tag}&utm_medium=interests`
  );
};

const addInterest = option => {
  if (option) {
    option.$isDisabled = true;
    if (interests.value.indexOf(option.tag) == -1) {
      interests.value = [...interests.value, option.tag];

      persist({
        payload: { interests: interests.value }
      });
    } else {
      duplicate.value = true;
    }
  }
};
const removeInterest = option => {
  interests.value = without(interests.value, option.tag);
  option.$isDisabled = false;

  persist({
    payload: { interests: interests.value }
  });
};

const onDelete = () => {
  if (tagSearch.value.query === "") {
    tagSearch.value.query = undefined;
  } else {
    if (mappedOptions.value.length > 0 && tagSearch.value.query == null) {
      removeInterest(mappedOptions.value[mappedOptions.value.length - 1]);
    }
  }
};

const onEnter = e => {
  e.preventDefault();

  if (arrowCounter.value >= 0) {
    addInterest(mappedOptions.value[arrowCounter.value]);
  } else {
    if (tagSearch.value.query && tagSearch.value.query.length > 0) {
      const key = Object.keys(options.value).filter(k => {
        const o = options.value[k];

        return (
          o.tag.toLowerCase().startsWith(tagSearch.value.query.toLowerCase()) ||
          o.label.toLowerCase().startsWith(tagSearch.value.query.toLowerCase())
        );
      })[0];
      const choice = options.value[key];

      addInterest(
        choice || { tag: tagSearch.value.query, label: tagSearch.value.query }
      );
    }
  }

  tagSearch.value.query = "";
};

const onChange = evt => {
  arrowCounter.value = -1;
  tagSearch.value.query = evt.target.value;
  duplicate.value = false;
  // on escape don't do anything
  if (evt.keyCode === 27) {
    return false;
  }
};

const onArrowDown = () => {
  if (arrowCounter.value < mappedOptions.value.length - 1) {
    arrowCounter.value = arrowCounter.value + 1;
  }
};

const onArrowUp = () => {
  if (arrowCounter.value > 0) {
    arrowCounter.value = arrowCounter.value - 1;
  }
};

const handleClickOutside = evt => {
  // if escape key or out of target bounds
  // then close options
  if (evt.keyCode === 27 || ref == evt.target) {
    // this.autocompletable = false;
    tagSearch.value.query = "";
    arrowCounter.value = -1;
    duplicate.value = false;
  }
};

const mappedInterests = computed(() => {
  if (Object.keys(options.value).length) {
    return map(interests.value, tag => {
      return options.value[tag] || { label: tag, tag, $isDisabled: true };
    });
  } else {
    return [];
  }
});

const mappedOptions = computed(() => {
  return values(options.value).filter(o => {
    return (
      !o.$isDisabled &&
      (tagSearch.value.query == null ||
        tagSearch.value.query.length == 0 ||
        o.tag.toLowerCase().startsWith(tagSearch.value.query.toLowerCase()) ||
        o.label.toLowerCase().startsWith(tagSearch.value.query.toLowerCase()))
    );
  });
});

const autocompletable = computed(() => {
  return tagSearch.value.query && tagSearch.value.query.length > 0;
});

initProfile().then(() => {
  options.value = loadOptions();
  interests.value = store.state.profile.interests;
  loading.value = false;
});

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
  document.addEventListener("keydown", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
  document.removeEventListener("keydown", handleClickOutside);
});
</script>

<template>
  <div>
    <div class="font-bold text-sm mb-2">Interests</div>
    <div class="grid grid-cols-12 gap-x-2">
      <div class="col-span-12">
        <div class="m-tags text-sm flg2 gmT8@<sm" :class="cptClassList">
          <div
            class="m-tags__input p-3 rounded"
            :style="{
              border: '1px solid rgb(var(--foreground-medium))'
            }"
          >
            <span
              class="m-tag m-tag--sm m-tag--blue-medium-flat mx-truncate"
              v-for="o in mappedInterests"
              :key="o.tag"
              >{{ o.label
              }}<span @click="removeInterest(o)"
                ><icon
                  width="0.5rem"
                  height="0.5rem"
                  name="close"
                  class="cursor-pointer ml-2"
                ></icon></span
            ></span>
            <input
              :style="{
                backgroundColor: 'inherit'
              }"
              type="text"
              ref="tags"
              :placeholder="placeholder"
              autocomplete="off"
              :value="tagSearch.query"
              @input="onChange"
              @keydown.delete="onDelete"
              @keydown.enter="e => onEnter(e)"
              @keydown.down.prevent="onArrowDown"
              @keydown.up.prevent="onArrowUp"
            />
          </div>
          <ul
            :class="[
              'm-tags__options',
              autocompletable && 'm-tags__options--autocompletable',
              mappedOptions &&
                mappedOptions.length === 0 &&
                'm-tags__options--empty'
            ]"
          >
            <template v-if="mappedOptions.length">
              <li
                v-for="(o, i) in mappedOptions.slice(0, 10)"
                :key="o.tag"
                @click="addInterest(o)"
                :class="{ active: i === arrowCounter }"
              >
                {{ o.label }}
              </li>
            </template>
            <template v-else>
              <li style="word-break: break-word" v-if="tagSearch.query">
                <span v-if="duplicate">Term already added</span>
                <span v-else>{{
                  t("Sorry, no matching results for %{term}", {
                    term: tagSearch.query
                  })
                }}</span>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
