import useVueValidate from "@vuelidate/core";
import { reactive, unref } from "vue";
import { currentBrowserTimezone } from "~src/lib/utils/tz.js";

export default function useTimezoneValidation({ initialValues }) {
  const state = reactive({
    timezone: unref(initialValues).timezone || currentBrowserTimezone,
    locale: unref(initialValues).locale || "en",
    shortDateFormat: unref(initialValues).shortDateFormat || "DDD",
    longDateFormat: unref(initialValues).longDateFormat || "DDDD",
    shortTimeFormat: unref(initialValues).shortTimeFormat || "t",
    longTimeFormat: unref(initialValues).longTimeFormat || "tt"
  });

  const rules = {
    timezone: {},
    locale: {},
    shortDateFormat: {},
    longDateFormat: {},
    shortTimeFormat: {},
    longTimeFormat: {}
  };

  const v$ = useVueValidate(rules, state);

  return { state, v$ };
}
