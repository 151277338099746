<script setup>
import Modal from "~external/v3/Modal.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import TextInputField from "~components/form_fields/TextInput.vue";
import { crossOriginXHR } from "~config/axios/instances";
import { useToast } from "~external/v3/Toast/api";

const props = defineProps({
  opened: {
    type: Boolean,
    required: true,
    default: false
  },
  param: {
    type: String,
    required: true
  },
  paramValue: {
    type: String,
    required: true
  }
});

const toast = useToast();
const store = useStore();
const name = "PasswordConfirmationModal";
const emit = defineEmits(["close", "success"]);
const password = ref("");

const requestPasswordConfirmation = token => {
  return crossOriginXHR.post("api/auth/sign_in", {
    public_id: store.state.userAccount.public_id,
    password: password.value
  });
};

const failureToast = message => {
  toast.open({
    message: message,
    duration: 10000,
    type: "l3",
    position: "bottom"
  });
};

const submit = e => {
  requestPasswordConfirmation()
    .then(response => {
      close(e);
      emit("success", e);
    })
    .catch(error => {
      close(e);
      failureToast(
        "Password verification failed, please ensure you entered the correct password"
      );
    });
};

const close = e => {
  password.value = "";
  emit("close", e);
};
</script>

<template>
  <modal
    :name="name"
    :opened="opened"
    @close="close"
    :maskClasses="['grid grid-cols-12 gap-x-2']"
    :wrapperClasses="[
      'bg-transparent',
      'max-md:px-3',
      'items-center',
      'justify-center',
      'col-span-12',
      'md:col-span-6',
      'md:col-start-4',
      'xl:col-span-4',
      'xl:col-start-5',
      'my-auto'
    ]"
    :containerClasses="['p-[1em]']"
  >
    <template #body>
      <div class="flex items-center m-4 leading-tight">
        <div class="max-md:mt-32 space-y-2 flg2">
          <p class="text-2xl font-bold text-center mt-4">Change {{ param }}</p>
          <div class="mt-0">
            <span>
              Your are changing your {{ param }} to
              <span class="font-bold">{{ paramValue }}</span>
            </span>
          </div>
          <div class="mt-0">
            <span>Type your password to confirm this action</span>
          </div>

          <text-input-field
            class="mb-4 mt-4"
            input-type="password"
            field="password"
            label="Current password"
            input-size="medium"
            :input-block="true"
            input-placeholder="Type you password"
            v-model="password"
          />

          <input
            @click="submit"
            class="mt-6 m-button m-button--blue-medium m-button--primary m-button--md w-full block"
            value="Submit"
          />
        </div>
      </div>
    </template>
  </modal>
</template>
