const state = {
  open: false
};

const mutations = {
  change(state, payload) {
    state.open = payload.open;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
