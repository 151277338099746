import { api } from "~src/config/axios/instances";

const state = {
  items: []
};

const mutations = {
  list(state, payload) {
    state.items = payload;
  }
};

const actions = {
  list({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/account/v1/app/orders?select=*,line_items:app__line_items(*,cohort:app__cohorts(*,course:app__course_skeletons(*))),payments:app__payments(*, responses:app__payment_responses(payload->charges->data->0->>receipt_url))&payments.state=eq.authorized&state=in.(refunded,approved,canceled)`
        )
        .then(
          response => {
            const payload = response.data;

            if (payload) {
              commit("list", payload);
            }

            resolve(payload);
          },
          error => {
            reject(error);
          }
        );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
