<template>
  <div class="bg-surface relative">
    <slot name="alert" :alert="alert">
      <alert v-if="alert" class-list="text-xs pL10 mb-4" v-bind="alert"></alert>
    </slot>
    <slot name="header"></slot>
    <div class="relative">
      <div v-if="disabled" class="absolute section-frame-overlay"></div>
      <div>
        <h3 class="text-xl font-bold text-foreground-high leading-none">
          <slot name="title"></slot>
        </h3>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "./Alert.vue";
export default {
  components: {
    Alert
  },

  props: {
    alert: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss">
.section-frame-overlay {
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.75);
}

html[data-theme="dark"] {
  .section-frame-overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
}
</style>
