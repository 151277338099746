<script setup>
import { ref, nextTick, computed, watch } from "vue";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";

const emit = defineEmits(["close"]);

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  bodyScrollThreshold: {
    type: Number,
    default: 992
  },
  videoPlayerMode: {
    type: Boolean,
    default: false
  },
  opened: {
    type: Boolean,
    required: true,
    default: false
  },
  rootClasses: {
    type: Array,
    required: false,
    default() {
      return [];
    }
  },
  maskClasses: {
    type: Array,
    required: false,
    default() {
      return [];
    }
  },
  wrapperClasses: {
    type: Array,
    required: false,
    default() {
      return ["col-span-12 md:col-span-6 bg-transparent"];
    }
  },
  containerClasses: {
    type: Array,
    required: false,
    default() {
      return [];
    }
  },
  containerStyles: {
    type: Object,
    required: false,
    default() {
      return {};
    }
  },
  headerClasses: {
    type: Array,
    required: false,
    default() {
      return [];
    }
  },
  bodyClasses: {
    type: Array,
    required: false,
    default() {
      return [];
    }
  },
  footerClasses: {
    type: Array,
    required: false,
    default() {
      return [];
    }
  }
});

const top = ref(0);
const opened = computed(() => props.opened);
const modal = ref(null);

watch(opened, (n, o) => {
  if (typeof document !== "undefined") {
    if (n) {
      top.value = document.documentElement.scrollTop;
    }

    const func = () => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

      if (vw > props.bodyScrollThreshold) {
        document.documentElement.scrollTop = top.value;
        return true;
      }

      if (!/iP(ad|hone|od)/.test(navigator.userAgent)) {
        if (n) {
          disableBodyScroll(modal.value);
        } else {
          enableBodyScroll(modal.value);
          document.documentElement.scrollTop = top.value;
        }
      } else {
        const iOSBodyStyle = {
          WebkitOverflowScrolling: n ? "none" : "",
          overflow: n ? "hidden" : ""
        };
        if (!n) {
          document.documentElement.scrollTop = top.value;
        }
        Object.assign(document.body.style, iOSBodyStyle);
      }
    };

    if (n) {
      nextTick().then(func);
    } else {
      func();
    }
  }
});

if (typeof document !== "undefined") {
  document.addEventListener("keyup", event => {
    // esc
    if (event.keyCode === 27) {
      emit("close", props.name);
    }
  });
}
</script>

<template>
  <Teleport v-if="opened" ref="modal" to="body">
    <transition name="o-modal">
      <div
        class="o-modal"
        :class="[
          props.videoPlayerMode ? 'o-modal--video-player' : '',
          ...props.rootClasses
        ]"
      >
        <div
          :class="['o-modal__mask', ...props.maskClasses]"
          @click.self="emit('close', props.name)"
        >
          <div
            :class="['o-modal__wrapper', ...props.wrapperClasses]"
            @click.self="emit('close', props.name)"
          >
            <slot name="subtitle"></slot>
            <div
              :class="['o-modal__container', ...props.containerClasses]"
              :style="props.containerStyles"
            >
              <div
                class="o-modal__close w-4 h-4 ml-auto"
                aria-label="Close"
                @click.self="emit('close', props.name)"
              ></div>

              <div :class="['o-modal__header', ...props.headerClasses]">
                <slot name="header"></slot>
              </div>

              <div :class="['o-modal__body', ...props.bodyClasses]">
                <slot name="body"></slot>
              </div>

              <div :class="['o-modal__footer', ...props.footerClasses]">
                <slot name="footer"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
