import env from "./environment";
import parse from "url-parse";

let url = parse(env.webAppURL);

function domain(locale = "en", path) {
  return `${url.protocol}//${url.host}${path}`;
}

export default domain;
