import { api } from "~src/config/axios/instances";

const state = {
  loading: false,
  loaded: false
};

const mutations = {
  update(state, { field, value }) {
    state[field] = value;
  }
};

const getters = {
  // getScholarshippableCohort: state => (courseSlug, cohortSlug) => {
  //   return state.scholarshippable.find(
  //     ({ slug, course_skeleton }) =>
  //       slug === cohortSlug && course_skeleton.slug === courseSlug
  //   );
  // }
};

const actions = {
  get({ commit }, { applicableId, applicableType }) {
    commit("update", { field: "loaded", value: false });

    return new Promise((resolve, reject) => {
      api
        .get(
          `/account/v1/app/scholarships?select=*,application:app__applications!inner(*)&application.applicable_id=eq.${applicableId}&application.applicable_type=eq.${applicableType}&limit=1`,
          {
            headers: { Prefer: "return=representation" }
          }
        )
        .then(
          response => {
            commit("update", { field: "loaded", value: true });
            const { data } = response;

            if (data?.length) {
              resolve(data[0]);
            } else {
              resolve(null);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  },
  create({ commit }, { payload }) {
    return new Promise((resolve, reject) => {
      api
        .post("/account/v1/app/scholarships", payload, {
          headers: {
            Prefer: "return=representation",
            Accept: "application/vnd.pgrst.object+json"
          }
        })
        .then(
          response => {
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        );
    });
  },
  update({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/account/v1/app/scholarships?id=eq.${id}`, payload, {
          headers: {
            Prefer: "return=representation",
            Accept: "application/vnd.pgrst.object+json"
          }
        })
        .then(
          response => {
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
