<script setup>
import { computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Certificate from "~src/components/Certificate.vue";
import { webAppURL } from "~src/lib/utils/urls";

const route = useRoute();
const router = useRouter();
const store = useStore();

const course = computed(() => {
  return (
    store.getters["course/getProduct"](
      route.params.course_slug,
      route.params.slug
    ) || {}
  );
});

const issued = computed(() => {
  return course.value && course.value.certificate_issuing_state === "issued";
});

const pending = computed(() => {
  return course.value && course.value.certificate_issuing_state === "pending";
});

watch(course, () => {
  if (!issued.value) {
    if (pending.value) {
      router.replace({
        name: "CertificateWizard",
        params: {
          course_slug: route.params.course_slug,
          slug: route.params.slug
        }
      });
    } else {
      router.replace({ name: "MyCoursesCohorts" });
    }
  }
});
</script>

<template>
  <div id="certificate" v-if="issued">
    <div class="container mx-auto max-lg:px-3">
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-12 lg:col-start-3 lg:col-span-8">
          <div class="mt-8">
            <router-link
              :to="{ name: 'MyCoursesCohorts' }"
              class="flex items-center"
            >
              <svg
                class="w-[1em] mr-2"
                style="height: auto; max-height: 1em; transform: rotate(180deg)"
              >
                <use href="#icons-arrow-stroke" />
              </svg>

              <span>Return to dashboard</span>
            </router-link>
          </div>

          <div class="mt-8 p-8 mx-border rounded">
            <Certificate :course="course" />
          </div>
          <div class="mt-6 mb-24 text-center">
            If you need to re-issue your certificate, please
            <a
              :href="webAppURL('contact-us')"
              class="text-blue-medium hover:text-blue-medium"
              target="_blank"
              >contact us</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
