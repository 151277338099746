import useVueValidate from "@vuelidate/core";
import { reactive } from "vue";
import {
  required,
  minLength,
  maxLength,
  usernameFormat,
  usernameConsecutiveDash,
  usernameConsecutiveUnderline,
  usernameBoundaryDash,
  usernameBoundaryUnderline,
  usernameLowercased
} from "~src/lib/validations";

export default function useAccountValidation({ initialValues }) {
  const state = reactive({
    username: initialValues.username || ""
  });

  const rules = {
    username: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(15),
      usernameFormat,
      usernameConsecutiveDash,
      usernameConsecutiveUnderline,
      usernameBoundaryDash,
      usernameBoundaryUnderline,
      usernameLowercased
    }
  };

  const v$ = useVueValidate(rules, state);

  return { state, v$ };
}
