import CRC32 from "crc-32";

var __mt = {};

export function fallback(i18n) {
  return function (...args) {
    let [key, ...rest] = args;
    let result = i18n.t.apply(i18n, args);
    let originalResult = result;

    if (result === "" || typeof result !== "string") {
      var hashedKey = (CRC32.str(key) >>> 0).toString(36);
      result = i18n.t.apply(i18n, [hashedKey, ...rest]);
    } else {
      return result;
    }

    if (result === "") {
      if (import.meta.env.DEV && __mt[hashedKey] == null) {
        console.log(`${hashedKey}: ${JSON.stringify(key)}`);
        __mt[hashedKey] = key;
      }
      if (typeof originalResult === "object") {
        return originalResult;
      } else {
        return key;
      }
    } else {
      return result;
    }
  };
}
