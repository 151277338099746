import { api } from "~src/config/axios/instances";

const state = {
  loading: false,
  loaded: false
};

const mutations = {
  update(state, { field, value }) {
    state[field] = value;
  }
};

const actions = {
  create({ commit }, { payload }) {
    return new Promise((resolve, reject) => {
      api
        .post("/account/v1/app/applications", payload, {
          headers: {
            Prefer: "return=representation",
            Accept: "application/vnd.pgrst.object+json"
          }
        })
        .then(
          response => {
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        );
    });
  },
  update({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/account/v1/app/applications?id=eq.${id}`, payload, {
          headers: {
            Prefer: "return=representation",
            Accept: "application/vnd.pgrst.object+json"
          }
        })
        .then(
          response => {
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
