<template>
  <div
    class="m-form-field"
    :class="[
      disabled && 'm-form-field--disabled',
      hasErrors && 'm-form-field--error'
    ]"
  >
    <div
      class="m-form-field__label"
      :class="[labelOver && 'm-form-field__label--over']"
    >
      <label
        :for="field"
        :disabled="disabled ? true : null"
        v-html="labelWithRequired"
        :class="labelClass"
      ></label>
    </div>
    <div class="m-form-field__textarea">
      <textarea
        :name="field"
        v-model="model"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :style="{
          height: height,
          width: '100%'
        }"
      ></textarea>

      <div v-if="hasErrors" class="text-red-medium">
        <span class="text-xs">{{ errors[0] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      required: false
    },
    height: {
      type: String,
      required: false,
      default: "150px"
    },
    maxLength: {
      type: Number,
      required: false,
      default: 5000
    },
    errors: {
      type: Array,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      required: true
    },
    labelOver: {
      type: Boolean,
      default: true
    },
    labelClass: {
      type: [String, Array],
      default() {
        return [];
      }
    },
    field: {
      type: String,
      required: true
    },
    placeholder: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0;
    },
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    labelWithRequired() {
      if (this.required) {
        return `${this.label} <span>(required)</span>`;
      } else {
        return this.label;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
label[disabled] {
  color: var(--foreground-low);
}
</style>
