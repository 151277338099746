<script setup>
import { ref, computed, onMounted } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    required: false
  },
  errors: {
    type: Array,
    default() {
      return [];
    }
  },
  rootClasses: {
    type: Array,
    default() {
      return [];
    }
  },
  checkboxClasses: {
    type: Array,
    default() {
      return [];
    }
  },
  disabled: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    required: true
  },
  field: {
    type: String,
    required: true
  },
  labelOver: {
    type: Boolean,
    default: true
  },
  options: {
    type: Array,
    default() {
      return [];
    }
  }
});

const emit = defineEmits(["update:modelValue"]);
const hasErrors = computed(() => props.errors.length > 0);
const model = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  }
});
const field = ref(undefined);
</script>

<template>
  <div
    class="m-form-field"
    :class="[
      hasErrors && 'm-form-field--error',
      props.disabled && 'm-form-field--disabled',
      ...props.rootClasses
    ]"
  >
    <div
      class="m-form-field__label"
      :class="[props.labelOver && 'm-form-field__label--over']"
    >
      <label v-html="props.label" :for="props.field"></label>
    </div>
    <div class="space-y-3 mt-4">
      <div
        v-for="{ key, value } in props.options"
        :key="key"
        class="m-checkbox flex items-center text-sm"
        :class="checkboxClasses"
        @click="emit('update:modelValue', key)"
      >
        <div class="m-checkbox__selector">
          <input
            :checked="props.modelValue === key"
            :value="key"
            type="radio"
          />
          <label></label>
        </div>
        <span class="m-checkbox__label">{{ value }}</span>
      </div>
    </div>
  </div>
</template>
