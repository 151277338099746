import useVueValidate from "@vuelidate/core";
import { reactive, unref } from "vue";
import { platformFormat, required } from "~src/lib/validations";

export default function useSocialMediaValidation({
  initialValue,
  platform,
  type
}) {
  const state = reactive({
    platform: initialValue || ""
  });

  const rules = {
    platform: {
      required,
      platformFormat: platformFormat({ platform, type })
    }
  };

  const v$ = useVueValidate(rules, state);

  return { state, v$ };
}
