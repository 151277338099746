import pipe from "ramda/es/pipe";
import evolve from "ramda/es/evolve";
import mergeLeft from "ramda/es/mergeLeft";
import omit from "ramda/es/omit";
import { api } from "~src/config/axios/instances";
import { normalizeUrl, update, shallowCamelCasedKeys } from "~utils";

const state = {
  id: undefined,
  name: "",
  pronouns: undefined,
  dateOfBirth: undefined,
  avatarUrl: undefined,
  avatarFileUrl: undefined,
  avatarIsLegacy: true,
  avatarIsLoaded: false,
  username: "",
  shortBio: "",
  longBio: "",
  website: "",
  instructor: false,
  public: true,
  country: "",
  socialProfiles: {},
  elearningProfiles: {},
  interests: [],
  loaded: false,
  timezone: "America/Los_Angeles",
  locale: "en",
  shortDateFormat: "DDD",
  longDateFormat: "DDDD",
  shortTimeFormat: "t",
  longTimeFormat: "tt"
};

const normalizeAvatarUrl = evolve({ avatarUrl: normalizeUrl });
const loaded = mergeLeft({ loaded: true });

const getters = {
  firstName: state => {
    if (state.name) {
      var fn = state.name.match(/(.*?)\s+/gm);

      return fn ? fn[0] : state.name;
    } else {
      return "";
    }
  },
  avatar: state => {
    if (!state.avatarIsLoaded) {
      return {
        url: undefined
      };
    }

    if (state.avatarIsLegacy) {
      return {
        url: state.avatarUrl,
        legacy: true
      };
    } else {
      return {
        url: state.avatarFileUrl,
        legacy: false
      };
    }
  }
};

const mutations = {
  get(state, payload) {
    pipe(
      shallowCamelCasedKeys,
      normalizeAvatarUrl,
      loaded,
      mergeLeft({ country: payload.country || "" }),
      update(state)
    )(payload);
  },
  update(state, payload) {
    pipe(shallowCamelCasedKeys, normalizeAvatarUrl, update(state))(payload);
  },
  patch(state, { field, value }) {
    state[field] = value;
  }
};

const actions = {
  get({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/account/v1/app/profiles?select=*,user_account:app__user_accounts(*)`
        )
        .then(
          response => {
            const payload = response.data[0];

            if (payload) {
              commit("get", payload);
              commit(
                "userAccount/get",
                { ...payload.user_account, loaded: true },
                { root: true }
              );
            }

            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
  },
  update({ commit }, { payload }) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/account/v1/app/profiles`, omit(["loaded"], payload), {
          headers: {
            Prefer: "return=representation",
            Accept: "application/vnd.pgrst.object+json"
          }
        })
        .then(
          response => {
            commit("update", payload);
            resolve(response.data[0]);
          },
          error => {
            reject(error);
          }
        );
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
