<template>
  <transition name="fade">
    <div
      v-show="open"
      v-if="message"
      class="m-alert"
      :class="alertClass"
      style="border-width: 0px; border-radius: 0px"
    >
      <slot>{{ message }}</slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
      default: ""
    },

    type: {
      type: String,
      required: false,
      default: "primary"
    },

    classList: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      open: true
    };
  },

  computed: {
    alertClass() {
      var typeClass;
      switch (this.type) {
        case "primary":
          typeClass = "m-alert--flat-l1";
          break;
        case "success":
          typeClass = "m-alert--flat-l1";
          break;
        case "warning":
          typeClass = "m-alert--flat-l2";
          break;
        case "danger":
          typeClass = "m-alert--flat-l3";
          break;
        default:
          typeClass = "";
      }
      return `${typeClass} ${this.classList}`;
    }
  },

  methods: {
    close() {}
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
