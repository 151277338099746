<script setup>
import env from "~config/environment";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "~src/lib/i18n";
import { webAppURL } from "~src/lib/utils/urls";
import { ref, computed, onMounted, inject } from "vue";
import SideMenu from "~components/SideMenu.vue";
import { crossOriginXHR } from "~config/axios/instances";
import EmailVerificationModal from "~components/EmailVerificationModal.vue";
import useResendConfirmationLink from "~src/composables/resendConfirmationLink";
import useDebounced from "~src/composables/debounced";

const store = useStore();
const route = useRoute();
const router = useRouter();
const { t, i18n } = useI18n();
const confirmationAlert = computed(
  () =>
    !store.state.userAccount.confirmed_at ||
    store.state.userAccount.email?.includes("@users.classpert.com")
);
const email = computed(() => store.state.userAccount.email);

const responseAlert = computed(() => store.state.topAlert);
const open = computed(() => store.state.emailVerificationModalStatus.open);

const resendConfirmation = useResendConfirmationLink();
const debouncedConfirmation = () => {
  useDebounced(resendConfirmation);
};

const closeModal = () => {
  store.commit("emailVerificationModalStatus/change", {
    open: false
  });
};
</script>
<template>
  <div>
    <email-verification-modal :opened="open" @close="closeModal" />

    <div
      v-if="confirmationAlert && !responseAlert.visible"
      class="m-alert m-alert--flat-l2"
      style="border-width: 0px; border-radius: 0px"
    >
      <span
        >You haven't confirmed your e-mail yet.
        <a class="underline" href="#" @click="debouncedConfirmation">
          Resend confirmation link
        </a>
      </span>
    </div>

    <div
      v-if="responseAlert.visible"
      class="m-alert text-foreground-high"
      :class="'m-alert--flat-' + responseAlert.type"
      style="border-width: 0px; border-radius: 0px"
      v-html="responseAlert.message"
    ></div>

    <router-view v-slot="{ Component, route }" class="lg:pl-12">
      <div class="container mx-auto max-lg:px-3 flex-1">
        <div class="grid grid-cols-12 gap-x-2 pt-10 pb-12">
          <div
            v-if="!route.meta.plain"
            class="hidden lg:block lg:col-start-2 col-span-2"
          >
            <SideMenu />
          </div>
          <transition name="slide">
            <Suspense>
              <template #default>
                <component :is="Component" />
              </template>
              <template #fallback> Loading... </template>
            </Suspense>
          </transition>
        </div>
      </div>
    </router-view>
  </div>
</template>
