import env from "~config/environment";
import { useStore } from "vuex";
import { webAppURL } from "~src/lib/utils/urls";
import { crossOriginXHR } from "~config/axios/instances";
import { useToast } from "~external/v3/Toast/api";

export default function resendConfirmationLink() {
  const toast = useToast();
  const store = useStore();
  const email =
    store.state.userAccount.unconfirmed_email || store.state.userAccount.email;

  const request = () => {
    crossOriginXHR
      .get(env.resendConfirmationPath)
      .then(() => {
        toast.open({
          message: "Confirmation link successfully sent to " + email,
          duration: 5000,
          type: "l1",
          position: "bottom"
        });
      })
      .catch(() => {
        toast.open({
          message:
            "The server has failed to send the confirmation link. Please enter in contact with our support",
          duration: 5000,
          type: "l3",
          position: "bottom"
        });
      });
  };

  return request;
}
