import domain from "./domain";
import { paths } from "./environment";

export function logInURL() {
  return domain("en", paths.logInPath);
}

export function logOutURL() {
  return domain("en", paths.logOutPath);
}

export function requireSignIn(redirPath = "/") {
  let url = `${this.logInURL()}?redirect_to=${decodeURIComponent(
    redirPath
  )}&__sb=1`;
  window.location.replace(url);
}

export function logOut() {
  window.location.replace(this.logOutURL());
}

export default {
  logInURL,
  logOutURL,
  requireSignIn,
  logOut
};
