<script setup>
import pick from "ramda/es/pick";
import SectionFrame from "~components/SectionFrame.vue";
import TextAreaField from "~components/form_fields/TextArea.vue";
import TextInputField from "~components/form_fields/TextInput.vue";
import PasswordConfirmationModal from "~components/PasswordConfirmationModal.vue";
import { safeJSONParse } from "~utils";
import { crossOriginXHR } from "~config/axios/instances";
import { ref, computed, defineEmits, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "~src/lib/i18n";
import clone from "ramda/es/clone";
import { useToast } from "~external/v3/Toast/api";
import useResendConfirmationLink from "~src/composables/resendConfirmationLink";
import useDebounced from "~src/composables/debounced";

const toast = useToast();
const { i18n, t } = useI18n();
const store = useStore();
const emit = defineEmits(["success", "error"]);
const initializer = () => undefined;
const alertInitializer = () => ({ message: "", type: "" });
const alert = ref(alertInitializer());
const sending = ref(false);
const userAccount = ref({});
const emailConfirmed = computed(() => store.state.userAccount.confirmed_at);
const unconfirmedEmail = computed(
  () => store.state.userAccount.unconfirmed_email
);
userAccount.value = clone(store.state.userAccount);
const publicId = store.state.userAccount.public_id;

const email = ref("");
const currentEmail = store.state.userAccount.email;
email.value = currentEmail.includes("@users.classpert.com")
  ? unconfirmedEmail.value
  : currentEmail;

const changeEmail = callback => {
  crossOriginXHR
    .post("api/auth/change_email", {
      id: store.state.userAccount.id,
      email: email.value
    })
    .then(() => {
      store.dispatch("userAccount/get");
      toast.open({
        message: "Confirmation link successfully sent to " + email.value,
        duration: 5000,
        type: "l1",
        position: "bottom"
      });
    })
    .catch(callback);
};

const submit = () => {
  changeEmail(error => {
    toast.open({
      message:
        error.response.data.message ||
        "The server has failed to send the confirmation link. Please contact our support [2]",
      duration: 5000,
      type: "l3",
      position: "bottom"
    });
  });
};

watch(userAccount, (n, o) => {
  local.value = pick(Object.keys(FIELDS), n);
});

const openPasswordConfirmation = () => {
  passwordConfirmationOpen.value = true;
};

const submitOrModal = () => {
  changeEmail(openPasswordConfirmation);
};

const passwordConfirmationOpen = ref(false);

const closePasswordModal = () => {
  passwordConfirmationOpen.value = false;
};

const confirmationSuccessful = e => {
  submit();
};

const resendConfirmation = useResendConfirmationLink();
const debouncedConfirmation = () => {
  useDebounced(resendConfirmation);
};
</script>

<template>
  <password-confirmation-modal
    :opened="passwordConfirmationOpen"
    @close="closePasswordModal"
    param="email"
    :paramValue="email"
    @success="confirmationSuccessful"
  />

  <div>
    <div class="mx-border-t mb-10 mt-10"></div>
    <div
      ref="top"
      class="font-bold uppercase text-xs text-foreground-medium tracking-widest mb-6"
    >
      Email
    </div>
    <section-frame :alert="alert">
      <div
        v-if="unconfirmedEmail"
        class="p-2 m-alert m-alert--flat-l2 text-foreground-high"
        style="border-width: 0px; border-radius: 0px"
      >
        For changes to take effect, follow the confirmation instructors we've
        sent to
        <span class="font-bold">{{ unconfirmedEmail }}</span
        >.
        <a class="underline" href="#" @click="debouncedConfirmation">
          Resend confirmation link
        </a>
      </div>

      <form action="#" ref="form">
        <text-input-field
          class="mt-6"
          field="email"
          :disabled="loading || sending"
          label="Change email"
          input-size="medium"
          :input-block="true"
          input-placeholder="Change your email"
          v-model="email"
        >
        </text-input-field>

        <input
          :disabled="email == currentEmail"
          @click.prevent="submitOrModal"
          type="submit"
          class="mt-4 m-button m-button--blue-medium m-button--primary m-button--md"
          value="Update Email"
        />
      </form>
    </section-frame>
  </div>
</template>
