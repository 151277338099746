<template>
  <div>
    <form action="#">
      <div class="space-y-6 mt-8">
        <div class="m-form-field">
          <div class="m-skeleton m-form-field__label m-form-field__label--over">
            <label for="name"></label>
          </div>
          <div
            class="m-form-field__input m-form-field__input--medium m-form-field__input--block"
          >
            <div class="m-skeleton" style="height: 2.75rem" />
          </div>
        </div>

        <div class="m-form-field">
          <div class="m-skeleton m-form-field__label m-form-field__label--over">
            <label for="name"></label>
          </div>
          <div
            class="m-form-field__input m-form-field__input--medium m-form-field__input--block"
          >
            <div class="m-skeleton" style="height: 2.75rem" />
          </div>
        </div>

        <div class="m-form-field">
          <div class="m-skeleton m-form-field__label m-form-field__label--over">
            <label for="name"></label>
          </div>
          <div
            class="m-form-field__input m-form-field__input--medium m-form-field__input--block"
          >
            <div class="m-skeleton" style="height: 2.75rem" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
