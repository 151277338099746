<script setup>
import { urls } from "~src/config/environment";
import { useI18n } from "~src/lib/i18n";
import LanguageSelect from "~components/LanguageSelect.vue";
import { webAppURL, apiDocsURL, dashboardURL } from "~src/lib/utils/urls";
const { t } = useI18n();
</script>

<template>
  <footer>
    <!-- new -->
    <div class="text-foreground-fixed bg-surface-fixed py-12">
      <div class="container mx-auto px-3 lg:px-6">
        <div class="grid grid-cols-12 gap-x-2">
          <div class="col-span-12 lg:col-span-2">
            <!-- Pages -->
            <h2 class="text-foreground-medium text-sm max-lg:mt-10 mb-6">
              {{ t("Pages") }}
            </h2>
            <ul class="m-list text-sm space-y-1">
              <li>
                <a
                  target="_blank"
                  class="text-foreground-fixed hover:text-foreground-fixed"
                  :href="webAppURL('blog')"
                  >Blog</a
                >
              </li>
            </ul>
          </div>

          <!-- Company -->
          <div class="col-span-12 lg:col-span-2">
            <h2 class="text-foreground-medium text-sm max-lg:mt-10 mb-6">
              {{ t("Company") }}
            </h2>
            <ul class="m-list text-sm">
              <li>
                <a
                  target="_blank"
                  class="text-foreground-fixed hover:text-foreground-fixed"
                  :href="webAppURL('contact-us')"
                  >{{ t("contact_us.new.title") }}</a
                >
              </li>
            </ul>
          </div>

          <!-- Legal -->
          <div class="col-span-12 lg:col-span-4">
            <h2 class="text-foreground-medium text-sm max-lg:mt-10 mb-6">
              {{ t("Legal") }}
            </h2>
            <ul class="m-list text-sm space-y-1">
              <li>
                <a
                  target="_blank"
                  class="text-foreground-fixed hover:text-foreground-fixed"
                  :href="webAppURL('terms-and-conditions')"
                  >{{ t("Terms and Services") }}</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  class="text-foreground-fixed hover:text-foreground-fixed"
                  :href="webAppURL('privacy-policy')"
                  >{{ t("Privacy Policy") }}</a
                >
              </li>
            </ul>
          </div>

          <div class="col-span-12 lg:col-span-4 order-first lg:order-last">
            <h2 class="text-foreground-medium text-sm mb-6">
              {{ t("Follow us") }}
            </h2>
            <ul class="m-list m-list--hrz align-middle inline-block">
              <li class="mr-6">
                <a
                  class="leading-none"
                  target="_blank"
                  rel="noreferrer"
                  href="https://facebook.com/classpert"
                >
                  <svg class="fill-foreground-fixed h-[1.5em] w-[1.5em]">
                    <use href="#icons-facebook-circle"></use>
                  </svg>
                </a>
              </li>
              <li class="mr-6">
                <a
                  class="leading-none"
                  target="_blank"
                  rel="noreferrer"
                  href="https://linkedin.com/company/classpert"
                >
                  <svg class="fill-foreground-fixed h-[1.5em] w-[1.5em]">
                    <use href="#icons-linkedin-circle"></use>
                  </svg>
                </a>
              </li>
              <li class="mr-6">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://instagram.com/classpert"
                >
                  <svg class="fill-foreground-fixed h-[1.5em] w-[1.5em]">
                    <use href="#icons-instagram-circle"></use>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/classpert"
                >
                  <svg class="fill-foreground-fixed h-[1.5em] w-[1.5em]">
                    <use href="#icons-twitter-circle"></use>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-background-fixed">
      <div class="container mx-auto px-3 lg:px-6 pt-6 pb-6">
        <div class="grid grid-cols-12 gap-x-2 items-center">
          <div class="col-span-12 lg:col-span-6">
            <span class="leading-none text-xs text-foreground-medium">
              Copyright &copy; {{ new Date().getFullYear() }} Classpert
            </span>
          </div>
          <div class="col-span-4 flex lg:justify-end lg:col-span-6 max-lg:mt-2">
            <svg class="w-[100px] inline-block fill-foreground-fixed h-6">
              <use href="#brand-logo"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
