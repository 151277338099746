<script setup>
import Navbar from "~components/Navbar.vue";
import Footer from "~components/Footer.vue";
import OffCanvasMenu from "~components/OffCanvasMenu.vue";
import ProgressBar from "~external/ProgressBar.vue";

import useAvatar from "~src/composables/avatar";
import useTheme from "~src/composables/theme";
import useProfile from "~src/composables/profile";

const { getAvatar } = useAvatar();
const { loaded, loading, fetch, enforceUsername } = useProfile();
useTheme();

fetch().then(() => {
  if (enforceUsername()) {
    getAvatar();
  }
});
</script>

<template>
  <div class="flex flex-col" style="min-height: 100vh">
    <navbar />
    <div class="relative">
      <progress-bar class="absolute" v-if="loading" :indeterminate="true" />
    </div>

    <!-- OFF CANVAS MENU -->
    <off-canvas-menu v-if="loaded" />
    <router-view v-slot="{ Component }">
      <transition name="slide">
        <component :is="Component" v-if="loaded" class="flex-1" />
      </transition>
    </router-view>
    <Footer v-if="loaded"></Footer>
  </div>
</template>
