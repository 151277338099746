<script setup>
import validations from "~validations/social";
import { onpaste } from "~utils";
import { SOCIAL_ICONS, ELEARNING_ICONS } from "~utils/social";
import { useI18n } from "~src/lib/i18n";
import { ref, defineEmits, onMounted, watch, computed } from "vue";
import useSocialMediaValidation from "~src/composables/socialMediaValidation";

const emit = defineEmits(["input"]);
const errors = ref([]);
const { t, i18n } = useI18n();

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  platform: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  serverSideErrors: {
    type: Array,
    default() {
      return [];
    }
  }
});

const serverSideErrors = computed(() => props.serverSideErrors);
const platform = computed(() => props.platform);
const type = computed(() => props.type);

const { state, v$ } = useSocialMediaValidation({
  initialValue: props.modelValue[platform.value],
  platform: platform.value,
  type
});

const transform = validator => {
  return function (pasted) {
    const validation = validator(pasted);
    return validation.valid ? validation.id : validation.originalValue;
  };
};

const canonicalURL = computed(() => validations[platform.value].canonicalURL);
const validator = computed(() => validations[platform.value].validator);
const iconName = computed(() => {
  return type.value === "elearning"
    ? ELEARNING_ICONS[platform.value]?.icon
    : SOCIAL_ICONS[platform.value]?.icon;
});
const iconColor = computed(() => {
  return type.value === "elearning"
    ? ELEARNING_ICONS[platform.value]?.color
    : SOCIAL_ICONS[platform.value]?.color;
});
const iconType = computed(() => {
  return type.value === "elearning" ? "providers" : "icons";
});
const icon = computed(() => {
  return `#${iconType.value}-${iconName.value}`;
});
const fillIcon = computed(() => {
  switch (platform.value) {
    case "behance":
      return "fill-behance";
    case "dribbble":
      return "fill-dribbble";
    case "facebook":
      return "fill-facebook";
    case "github":
      return "fill-github";
    case "hackaday":
      return "fill-hackaday";
    case "instagram":
      return "fill-instagram";
    case "linkedin":
      return "fill-linkedin";
    case "pinterest":
      return "fill-pinterest";
    case "reddit":
      return "fill-reddit";
    case "soundcloud":
      return "fill-soundcloud";
    case "twitter":
      return "fill-twitter";
    case "youtube":
      return "fill-youtube";
    default:
      return "";
  }
});

watch(state, () => {
  emit("input", v$.value.platform.platformFormat.$response.extraParams);
});
</script>

<template>
  <div
    class="flex items-center mb-8 md:margin-[inherit]"
    :class="[v$.platform.$errors && v$.platform.$errors.length && 'mB32@>lg']"
  >
    <div style="flex: 1" class="relative inline-block">
      <div
        class="flex relative user:sm-input"
        :class="{
          'user:sm-input--error':
            v$.platform.$errors && v$.platform.$errors.length
        }"
      >
        <div class="flex items-center user:sm-input__left">
          <svg class="user:sm-input__icon inline-block">
            <use :class="`${fillIcon}`" :xlink:href="icon" /></svg
          ><b class="hidden md:inline-block">{{ canonicalURL("") }}</b>
        </div>

        <input
          class="!hidden !lg:inline-block user:sm-input__right"
          @paste.prevent="
            e => {
              v$.platform.$model = onpaste(transform(validator))(e);
            }
          "
          :placeholder="t('user.components.social_media_input.placeholder')"
          :disabled="disabled ? true : null"
          v-model="v$.platform.$model"
        />
        <input
          class="!inline-block !lg:hidden user:sm-input__right"
          @paste.prevent="
            e => {
              v$.platform.$model = onpaste(transform(validator))(e);
            }
          "
          :placeholder="
            t('user.components.social_media_input.placeholder_mobile')
          "
          :disabled="disabled ? true : null"
          v-model="v$.platform.$model"
        />
        <svg
          v-if="v$.platform.$errors && v$.platform.$errors.length"
          class="user:sm-input__icon absolute text-red-medium"
        >
          <use xlink:href="#icons-wrong" />
        </svg>
        <svg
          v-if="
            v$.platform.$model &&
            !(v$.platform.$errors && v$.platform.$errors.length)
          "
          class="user:sm-input__icon absolute fill-blue-medium"
        >
          <use xlink:href="#icons-checked" />
        </svg>
      </div>
      <div
        v-if="v$.platform.$errors && v$.platform.$errors.length"
        class="absolute hidden md:display-[inherit] text-red-medium"
        style="top: 3em"
      >
        <span class="text-xs">{{ v$.platform.$errors[0].$message }}</span>
      </div>
      <div class="absolute lg:hidden mt-1" style="top: 2.75em">
        <span
          class="text-xs"
          :class="
            v$.platform.$errors &&
            v$.platform.$errors.length &&
            'text-red-medium'
          "
          >{{ canonicalURL(v$.platform.$model || "") }}</span
        >
      </div>
    </div>
    <div style="flex: 0 0 2em" class="text-center">
      <slot :errors="v$.platform.$errors" name="control"> </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "sass:math";
.user\:sm-input {
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid rgb(var(--foreground-medium));
  &--error {
    border: 1px solid rgb(var(--red-medium));
  }
  &__left {
    font-size: 0.875em;
    border: none;
    padding: 0.925em 0 0.925em #{math.div(0.75, 0.875) * 1em};
  }
  &__right {
    background-color: var(--surface);
    line-height: 1;
    display: inline-block;
    box-sizing: border-box;
    color: var(--foreground);
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
    outline: 0px;
    font-size: 0.875em;
    padding: 0.925em #{math.div(0.75, 0.875) * 1em} 0.925em 0;
    border: none;
    width: 100%;
  }
  &__icon {
    width: 1.125em;
    height: 1.125em;
  }
  &__left > &__icon {
    margin-right: 0.5em;
  }
  &__right + &__icon {
    top: 0.75em;
    right: 0.75em;
  }
}
</style>
