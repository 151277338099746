import { api } from "~src/config/axios/instances";

const state = {
  items: []
};

const mutations = {
  list(state, payload) {
    state.items = payload;
  }
};

const actions = {
  list({ commit }) {
    return new Promise((resolve, reject) => {
      api.get(`/account/v1/app/oauth_available_providers`).then(
        response => {
          const payload = response.data;

          if (payload) {
            commit("list", payload);
          }

          resolve(payload);
        },
        error => {
          reject(error);
        }
      );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
