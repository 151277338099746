import useVueValidate from "@vuelidate/core";
import { reactive } from "vue";
import { required, minLength } from "~src/lib/validations";

export default function useCertificateValidation({ initialValues }) {
  const state = reactive({
    name: initialValues.name || ""
  });

  const rules = {
    name: {
      required,
      minLength: minLength(5)
    }
  };

  const v$ = useVueValidate(rules, state);

  return { state, v$ };
}
