<script setup>
import { ref } from "vue";
import { useI18n } from "~src/lib/i18n";
import { useStore } from "vuex";
import AvatarCropper from "~src/components/patches/AvatarCropper.vue";
import { urls } from "~config/environment";
import session from "~config/session";
import useAvatar from "~src/composables/avatar";

const { t } = useI18n();
const open = ref(false);
const { profileId, getAvatar, avatar } = useAvatar();

const labels = {
  submit: t("user.avatar.submit"),
  cancel: t("user.avatar.cancel")
};

var formData = new FormData();
formData.set("sub_id", "avatar");

const avatarUploaded = response => {
  open.value = false;
  return getAvatar();
};
</script>

<template>
  <div class="flex items-center">
    <div class="mr-1">
      <div class="m-avatar m-avatar--circle m-avatar--48x48 mx-border mr-2">
        <img :src="avatar.url" v-if="avatar.url" />
        <svg v-else>
          <use href="#icons-avatar-light-gray"></use>
        </svg>
        <div
          @click="open = true"
          class="m-avatar__overlay m-avatar__overlay--upload-icon"
        ></div>
      </div>
      <button
        @click.prevent="open = true"
        class="m-button m-button--xs m-button--blue-medium m-button--outline"
      >
        Upload
      </button>
      <avatar-cropper
        v-if="profileId"
        v-model="open"
        mimes="image/png, image/jpeg"
        @uploaded="avatarUploaded"
        :labels="{ submit: 'Submit', cancel: 'Cancel' }"
        upload-file-field="files"
        :upload-form-data="formData"
        :request-options="{ credentials: 'include', method: 'POST' }"
        :upload-url="`${urls.apiURL}/v1/files?fileableType=profiles&fileableId=${profileId}`"
      />
    </div>
  </div>
</template>

<style>
.avatar-cropper-btn:hover {
  background-color: #027aff !important;
}
.avatar-cropper-overlay {
  background-color: #000000b3;
}
</style>
