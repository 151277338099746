import session from "~src/config/session";

export default {
  req: {
    suc(conf) {
      return conf;
    },

    err(error) {
      return Promise.reject(error);
    }
  },

  res: {
    suc: undefined,
    err(error) {
      if (error.response.status === 401 || error.response.status === 403) {
        session.requireSignIn();
      }
      return Promise.reject(error);
    }
  }
};
