import { api } from "~src/config/axios/instances";

const state = {
  id: null,
  final_project_spec_id: null,
  grade: null,
  title: null,
  description: null,
  url: null,
  author_name: null,
  enrollment_id: null
};

const mutations = {
  get(state, payload) {
    state.id = payload.id;
    state.final_project_spec_id = payload.final_project_spec_id;
    state.grade = payload.grade;
    state.title = payload.title;
    state.description = payload.description;
    state.url = payload.url;
    state.author_name = payload.author_name;
    state.enrollment_id = payload.enrollment_id;
  }
};

const actions = {
  get({ commit }, { enrollmentId }) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/account/v1/app/final_projects?enrollment_id=eq.${enrollmentId}`,
          {
            headers: { Prefer: "return=representation" }
          }
        )
        .then(
          response => {
            commit("get", response.data[0]);
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
