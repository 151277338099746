const state = {
  visible: false,
  message: "",
  type: "l1"
};

const mutations = {
  change(state, payload) {
    state.visible = payload.visible;
    state.message = payload.message;
    state.type = payload.type;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
