import useVueValidate from "@vuelidate/core";
import { reactive, unref } from "vue";
import {
  required,
  minLength,
  maxLength,
  url
} from "~src/lib/validations";

export default function useProfileValidation({ initialValues }) {
  const state = reactive({
    name: unref(initialValues).name || "",
    shortBio: unref(initialValues).shortBio || "",
    longBio: unref(initialValues).longBio || "",
    country: unref(initialValues).country || "",
    website: unref(initialValues).website || "",
    organization: unref(initialValues).organization || "",
    dateOfBirth: unref(initialValues).dateOfBirth || "",
    pronouns: unref(initialValues).pronouns || ""
  });

  const rules = {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(50)
    },
    shortBio: {
      maxLength: maxLength(60)
    },
    longBio: {},
    country: {},
    organization: {},
    dateOfBirth: {},
    pronouns: {},
    website: {
      url: url(true)
    }
  };

  const v$ = useVueValidate(rules, state);

  return { state, v$ };
}
