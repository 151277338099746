<script setup>
import SideMenu from "~components/SideMenu.vue";
import Interests from "~components/profile/Interests.vue";
import PersonalInformation from "~components/profile/PersonalInformation.vue";
import ContactInfo from "~components/profile/ContactInfo.vue";
import clone from "ramda/es/clone";
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";

const profile = ref({});
const loading = ref(true);
const store = useStore();

const init = () => {
  profile.value = clone(store.state.profile);
  loading.value = false;
};

const loaded = computed(() => store.state.profile.loaded);

watch(loaded, init);

if (loaded.value) {
  init();
}
</script>

<template>
  <div class="col-span-12 lg:col-span-5">
    <div class="mb-4 font-bold">Profile</div>
    <div class="mx-border-t mb-10"></div>
    <personal-information class="mb-6" :profile="profile" :loading="loading">
      <template #interests>
        <interests />
      </template>
    </personal-information>

    <contact-info class="mb-6" />
  </div>
</template>
