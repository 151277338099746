import { computed, ref, watch } from "vue";
import useClipboard from "vue-clipboard3";
import { useStore } from "vuex";
import { urls } from "~config/environment";

const CLASSPERT_LINKEDIN_ID = 12595644;

export default function useCertificate({ course }) {
  const store = useStore();

  const certificate = computed(() => course.certificate || {});
  const certificateURL = computed(() =>
    certificate.value?.fingerprint
      ? `https:${urls.webAppURL}/certificates/${certificate.value?.fingerprint}`
      : undefined
  );
  const certificateImageURL = ref(undefined);
  const certificatePDFURL = ref(undefined);
  const certificateDate = computed(() => {
    if (certificate.value && certificate.value.valid_at) {
      return new Date(certificate.value.valid_at);
    } else {
      return new Date();
    }
  });

  const linkedinCertificationURL = computed(() => {
    return [
      "https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME",
      "&name=",
      encodeURIComponent(course.cohort.course.name),
      "&organizationId=",
      CLASSPERT_LINKEDIN_ID,
      "&issueYear=",
      certificateDate.value.getFullYear(),
      "&issueMonth=",
      certificateDate.value.getMonth() + 1,
      "&certUrl=",
      encodeURIComponent(certificateURL.value),
      "&certId=",
      certificate.value?.id
    ].join("");
  });

  // clipboard stuff
  const { toClipboard } = useClipboard();
  const copied = ref(false);

  const copy = async () => {
    try {
      await toClipboard(certificateURL.value);
      copied.value = true;
      setTimeout(() => (copied.value = false), 5000);
    } catch (e) {
      console.error(e);
    }
  };

  store
    .dispatch("file/get", {
      id: certificate.value?.id,
      type: "Certificate",
      subId: "image"
    })
    .then(file => {
      if (file && file.name) {
        certificateImageURL.value = `${urls.fileURL}/${file.name}`;
      }
    });
  store
    .dispatch("file/get", {
      id: certificate.value?.id,
      type: "Certificate",
      subId: "pdf"
    })
    .then(file => {
      if (file && file.name) {
        certificatePDFURL.value = `${urls.fileURL}/${file.name}`;
      }
    });

  return {
    certificateURL,
    certificateImageURL,
    certificatePDFURL,
    linkedinCertificationURL,
    copied,
    copy
  };
}
