<script setup>
import env from "~config/environment";
import SectionFrame from "~components/SectionFrame.vue";
import { useI18n } from "~src/lib/i18n";
import { useStore } from "vuex";
import { ref } from "vue";
import { crossOriginXHR } from "~config/axios/instances";
import { webAppURL } from "~src/lib/utils/urls";

const store = useStore();
const passwordResetURL = webAppURL(env.forgotPasswordPath);

const { t } = useI18n();
const alert = ref({
  message: "",
  type: ""
});
</script>

<template>
  <div
    ref="top"
    class="font-bold uppercase text-xs text-foreground-medium tracking-widest mb-6"
  >
    Password
  </div>
  <section-frame :alert="alert">
    <div class="text-sm font-bold text-foreground-high leading-none">
      {{ t("user.sections.account_settings.password.title") }}
    </div>
    <p class="text-sm mb-5 leading-tight">
      {{ t("user.sections.account_settings.password.info") }}
    </p>

    <a
      :href="passwordResetURL"
      target="_blank"
      class="m-button m-button--blue-medium m-button--primary m-button--md"
      :disabled="store.state.userAccount.autogen_email_for_oauth ? true : null"
      :value="'change password'"
    >
      {{ t("user.sections.account_settings.password.change_password_button") }}
    </a>
  </section-frame>
</template>
