import { urls } from "~config/environment";

export default function (file) {
  var variants = Object.keys(file.variants).reduce(
    (a, k) => ({ ...a, [k]: `${urls.thumborURL}${file.variants[k]}` }),
    {}
  );

  return function (variant) {
    return variants[variant] || `${urls.fileURL}/${file.name}`;
  };
}
