import { getTimeZones } from "@vvo/tzdb";
import { DateTime } from "luxon";

const _timezones = getTimeZones();

export const genTimezoneLabel = x => {
  return (
    "(UTC" +
    x.currentTimeFormat.substring(0, 6) +
    ") " +
    x.alternativeName +
    " - " +
    x.mainCities[0]
  );
};

var tzs = _timezones.map(x => [genTimezoneLabel(x), x.name]);
tzs.unshift(["(UTC+00:00) Coordinated Universal Time - UTC", "Etc/UTC"]);
export const timezones = tzs;

export const shortDateFormats = [
  ["Localized Short Date", "DDD"],
  ["Year-Month-Day", "yyyy-MM-dd"],
  ["Year/Month/Day", "yyyy/MM/dd"],
  ["Day/Month/Year", "dd/MM/yyyy"],
  ["Month/Day/Year", "MM/dd/yyyy"]
];

export const longDateFormats = [
  ["Localized Long Date", "DDDD"],
  ["Year-Month-Day Weekday", "yyyy-MM-dd EEEE"],
  ["Year/Month/Day Weekday", "yyyy/MM/dd EEEE"],
  ["Weekday Day/Month/Year", "EEEE dd/MM/yyyy"],
  ["Weekday Month/Day/Year", "EEEE MM/dd/yyyy"]
];

export const shortTimeFormats = [
  ["Localized Short Time with timezone code", "t ZZZZ"],
  ["Localized Short Time", "t"],
  ["24-hour time", "HH:mm"],
  ["24-hour time with timezone", "HH:mm ZZZZZ"],
  ["24-hour time with timezone code", "HH:mm a ZZZZ"],
  ["12-hour time", "hh:mm a"],
  ["12-hour time with timezone", "hh:mm a ZZZZZ"],
  ["12-hour time with timezone code", "hh:mm a ZZZZ"]
];

export const longTimeFormats = [
  ["Localized Long Time with timezone", "tt ZZZZZ"],
  ["Localized Long Time", "tt"],
  ["24-hour time", "HH:mm:ss"],
  ["24-hour time with timezone", "HH:mm:ss ZZZZZ"],
  ["12-hour time", "hh:mm:ss a"],
  ["12-hour time with timezone", "hh:mm:ss a ZZZZZ"]
];

export const localizedDateTime = x => {
  return DateTime.now().setZone(x.timezone.$model).setLocale(x.locale.$model);
};

const genCurrentBrowserTimezone = () => {
  var browserTimezone;
  try {
    browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    browserTimezone = "Etc/UTC";
  }
  return browserTimezone;
};

export const currentBrowserTimezone = genCurrentBrowserTimezone();

export const formattedShortDate = (x, profile) => {
  if (x) {
    return DateTime.fromISO(x)
      .setZone(profile.value.timezone || currentBrowserTimezone)
      .setLocale(profile.value.locale || "en")
      .toFormat(profile.value.shortDateFormat || "DDD");
  } else {
    return "";
  }
};

export const formattedShortDateFromISO = (date, format) => {
  return DateTime.fromISO(date).toFormat(format);
};

export const ISODateFromFormattedShortDate = (date, format) => {
  return DateTime.fromFormat(date, format).toISO();
};
