<script setup>
  import { useStore } from "vuex";

  const store = useStore();
  const props = defineProps({
    rootClasses: {
      type: Array,
      default() {
        return [];
      }
    },
    left: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    }
  });
</script>

<template>
  <div>
    <div
      :id="props.id"
      :class="[
        'o-off-canvas-menu',
        props.left && 'o-off-canvas-menu--left-positioned',
        ...props.rootClasses,
        store.state.menu.open ? 'o-off-canvas-menu--open' : ''
      ]"
    >
      <slot></slot>
    </div>
    <div
      class="o-off-canvas-menu-mask"
      @click="store.commit('menu/close')"
      :style="{ display: store.state.menu.open ? 'block' : 'none' }"
    ></div>
  </div>
</template>
