import { createI18n, useI18n as _useI18n } from "vue-i18n";
import messages from "../locales/en";
import axios from "axios";
import { fallback } from "./extensions";

export const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: JSON.parse(messages),
  missing: (locale, key, vm) => {
    return "";
  },
  silentTranslationWarn: true
});

export function useI18n() {
  const i18n = _useI18n();
  const t = fallback(i18n);
  return { t, i18n };
}
