import { ref, unref } from "vue";
import without from "ramda/es/without";
import omit from "ramda/es/omit";

import validations, {
  SOCIAL_PLATFORMS,
  ELEARNING_PLATFORMS,
  socialPlatformsReducer,
  elearningPlatformsReducer
} from "~validations/social";
import { normalizeUrl } from "~utils";

const initializer = () => undefined;

export default function useSocialMedia({ profile }) {
  const platforms = ref({
    social: {
      ids: socialPlatformsReducer(initializer),
      urls: socialPlatformsReducer(initializer),
      open: false,
      available: SOCIAL_PLATFORMS,
      added: []
    },
    elearning: {
      ids: elearningPlatformsReducer(initializer),
      urls: elearningPlatformsReducer(initializer),
      open: false,
      available: ELEARNING_PLATFORMS,
      added: []
    }
  });

  const addProfilePlatform = type => {
    return platform => {
      platforms.value[type].added = [...platforms.value[type].added, platform];

      platforms.value[type].available = without(
        [platform],
        platforms.value[type].available
      ).sort();
    };
  };

  const selectedPlatform = type => {
    return e => {
      if (e.target.value?.length) {
        addProfilePlatform(type)(e.target.value);
      }

      // this resets to "Add platform" option
      e.target.value = "";
    };
  };

  /**
   * ids is used to display what is typed
   * (regardless of the correctness)
   * urls is the final correct object
   */
  const onInputPlatformID = ({ type, platform, id, url, valid }) => {
    if (type && platform) {
      platforms.value[type].ids[platform] = id;
      platforms.value[type].urls[platform] = valid
        ? normalizeUrl(encodeURI(url))
        : undefined;
    }
  };

  const removeProfilePlatform = type => {
    return platform => {
      platforms.value[type].ids = omit([platform], platforms.value[type].ids);
      platforms.value[type].urls = omit([platform], platforms.value[type].urls);

      platforms.value[type].added = without(
        [platform],
        platforms.value[type].added
      );

      platforms.value[type].available = [
        ...platforms.value[type].available,
        platform
      ].sort();
    };
  };

  (() => {
    for (const type of ["social", "elearning"]) {
      for (const platform of Object.keys(
        unref(profile)[`${type}Profiles`]
      ).sort()) {
        if (
          Object.prototype.hasOwnProperty.call(
            unref(profile)[`${type}Profiles`],
            platform
          )
        ) {
          const url = unref(profile)[`${type}Profiles`][platform];
          const { id } = validations[platform]?.validator(url);

          if (id) {
            platforms.value[type].ids[platform] = id;
            platforms.value[type].urls[platform] = url;
            addProfilePlatform(type)(platform);
          }
        }
      }
    }
  })();

  return {
    platforms,
    selectedPlatform,
    onInputPlatformID,
    removeProfilePlatform
  };
}
