import { api } from "~src/config/axios/instances";

const MINIMUM_REDEEMABLE_AMOUNT = 15;

const state = {
  id: null,
  subdomains: null,
  minimum_redeemable_amount: MINIMUM_REDEEMABLE_AMOUNT
};

const mutations = {
  get(state, payload) {
    if (payload) {
      state.id = payload.id;
      state.subdomains = payload.subdomains;
      state.minimum_redeemable_amount =
        payload.minimum_redeemable_amount || MINIMUM_REDEEMABLE_AMOUNT;
    }
  }
};

const actions = {
  get({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .get("/user/v1/settings", {
          headers: { Prefer: "return=representation" }
        })
        .then(
          response => {
            commit("get", response.data[0]);
            resolve(state);
          },
          error => {
            reject(error);
          }
        );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
