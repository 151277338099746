import behance from "./behance";
import dribbble from "./dribbble";
import facebook from "./facebook";
import github from "./github";
import hackaday from "./hackaday";
import instagram from "./instagram";
import linkedin from "./linkedin";
import pinterest from "./pinterest";
import reddit from "./reddit";
import soundcloud from "./soundcloud";
import twitter from "./twitter";
import youtube from "./youtube";

const socialPlatforms = {
  behance,
  dribbble,
  facebook,
  github,
  hackaday,
  instagram,
  linkedin,
  pinterest,
  reddit,
  soundcloud,
  twitter,
  youtube
};

import linkedin_learning from "./linkedin_learning";
import masterclass from "./masterclass";
import pluralsight from "./pluralsight";
import skillshare from "./skillshare";
import treehouse from "./treehouse";
import udemy from "./udemy";

const elearningPlatforms = {
  linkedin_learning,
  masterclass,
  pluralsight,
  skillshare,
  treehouse,
  udemy
};

export const SOCIAL_PLATFORMS = [
  "behance",
  "dribbble",
  "facebook",
  "github",
  "hackaday",
  "instagram",
  "linkedin",
  "pinterest",
  "reddit",
  "soundcloud",
  "twitter",
  "youtube"
].sort();

export const ELEARNING_PLATFORMS = [
  "linkedin_learning",
  "masterclass",
  "pluralsight",
  "skillshare",
  "treehouse",
  "udemy"
].sort();

export const platformReducer = function (platforms) {
  return function (reducer) {
    return platforms.reduce(
      (platforms, platform) => ({
        ...platforms,
        [platform]: reducer(platform)
      }),
      {}
    );
  };
};

const requirePlatform = platform =>
  socialPlatforms[platform] || elearningPlatforms[platform];

export const socialPlatformsReducer = platformReducer(SOCIAL_PLATFORMS);
export const elearningPlatformsReducer = platformReducer(ELEARNING_PLATFORMS);

export const socialPlatformsValidations =
  socialPlatformsReducer(requirePlatform);
export const elearningPlatformsValidations =
  elearningPlatformsReducer(requirePlatform);

export default {
  ...socialPlatformsValidations,
  ...elearningPlatformsValidations
};
