import { urls, paths } from "~src/config/environment";

const removeLeadingSlash = string => string.replace(/^\//, "");
const removeTrailingSlash = string => string.replace(/\/$/, "");

const url = base => path =>
  removeTrailingSlash(
    [removeTrailingSlash(base), removeLeadingSlash(path)].join("/")
  );

export const webAppURL = url(urls.webAppURL);
export const apiDocsURL = url(urls.apiDocsURL);
export const dashboardURL = url(urls.dashboardURL);
export const logoutURL = webAppURL(paths.logOutPath);
