<script setup>
import { ref, computed, onMounted } from "vue";
import InputMask from "inputmask";

const props = defineProps({
  modelValue: {
    type: String,
    required: false
  },
  errors: {
    type: Array,
    default() {
      return [];
    }
  },
  required: {
    type: Boolean,
    default: false
  },
  rootClasses: {
    type: Array,
    default() {
      return [];
    }
  },
  mask: {
    type: Object,
    required: false,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    required: true
  },
  field: {
    type: String,
    required: true
  },
  labelOver: {
    type: Boolean,
    default: true
  },
  labelClass: {
    type: [String, Array],
    default() {
      return [];
    }
  },
  inputSize: {
    type: String,
    default: "medium",
    validator: value => ["medium", "large"].indexOf(value) !== -1
  },
  inputBlock: {
    type: Boolean,
    default: false
  },
  inputPlaceholder: {
    type: String
  },
  inputType: {
    type: String,
    default: "text"
  }
});

const emit = defineEmits(["update:modelValue"]);
const hasErrors = computed(() => props.errors.length > 0);
const model = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  }
});
const field = ref(undefined);

onMounted(() => {
  if (props.mask) {
    InputMask(props.mask).mask(field.value);
  }
});

const label = computed(() => {
  if (props.required) {
    return `${props.label} <span>(required)</span>`;
  } else {
    return props.label;
  }
});
</script>

<template>
  <div
    class="m-form-field"
    :class="[
      hasErrors && 'm-form-field--error',
      props.disabled && 'm-form-field--disabled',
      ...props.rootClasses
    ]"
  >
    <div
      class="m-form-field__label"
      :class="[props.labelOver && 'm-form-field__label--over']"
    >
      <label v-html="label" :for="props.field" :class="labelClass"> </label>
    </div>
    <slot name="description"></slot>
    <div
      class="m-form-field__input"
      :class="[
        `m-form-field__input--${props.inputSize}`,
        props.inputBlock && 'm-form-field__input--block'
      ]"
    >
      <input
        ref="field"
        :name="props.field"
        :disabled="props.disabled ? true : null"
        :type="inputType"
        :placeholder="props.inputPlaceholder"
        v-model="model"
      />
      <svg
        v-if="hasErrors"
        class="m-form-field__input-icon text-red-medium"
        width="1.125em"
        height="1.125em"
        viewbox="0 0 24 24"
      >
        <use xlink:href="#icons-wrong" />
      </svg>
      <svg
        v-if="model && !hasErrors"
        class="m-form-field__input-icon fill-blue-medium"
        width="1.125em"
        height="1.125em"
        viewbox="0 0 24 24"
      >
        <use xlink:href="#icons-checked" />
      </svg>
      <div v-if="hasErrors" class="text-red-medium">
        <span class="text-xs">{{ props.errors[0] }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
label[disabled] {
  color: var(--foreground-low);
}
</style>
