import { api } from "~src/config/axios/instances";

const state = {
  code: undefined,
  courses: []
};

const mutations = {
  list(state, courses) {
    state.courses = courses;
  }
};

const getters = {
  getProduct: state => (courseSlug, cohortSlug) => {
    return state.courses.find(
      ({ cohort }) =>
        cohort.course.slug === courseSlug && cohort.slug === cohortSlug
    );
  }
};

const actions = {
  list({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/account/v1/app/cohort_registrations?select=id,role,state,passed,grade,with_honors,certificate_issuing_state,created_at,final_project:app__final_projects(*,files:app__files(*)),cohort:app__cohorts(id,name,slug,starts_at,ends_at,new_lms,state,files:app__files(*),course:app__course_skeletons(id,name,slug,files:app__files(id,name,sub_id,variants),instructors:app__instructional_profiles(name, files:app__files(id,name,sub_id,variants)))),payments:app__payment_responses(payload->charges->data->0->>receipt_url),certificate:app__certificates(id,fingerprint,valid_at,invalid_at,state),order:app__orders(id,code)`,
          {
            headers: { Prefer: "return=representation" }
          }
        )
        .then(
          response => {
            const { data } = response;

            if (data?.length) {
              commit("list", data);
              resolve(data);
            } else {
              commit("list", []);
              resolve([]);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
