<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const localTheme = ref(
  store.state.profile.preferences?.theme || "classpert-light"
);
const theme = computed(
  () =>
    store.state.profile.preferences?.theme ||
    localTheme.value ||
    "classpert-light"
);
const switchTheme = () => {
  const oppositeTheme =
    store.state.profile.preferences.theme === "classpert-light"
      ? "classpert-dark"
      : "classpert-light";
  localTheme.value = oppositeTheme;
  store.dispatch("profile/update", {
    payload: {
      preferences: {
        ...store.state.profile.preferences,
        theme: oppositeTheme
      }
    }
  });
};
</script>

<template>
  <label class="m-switch m-switch--flat-primary align-middle" for="theme">
    <input
      type="checkbox"
      :checked="theme === 'classpert-dark' ? true : null"
    />
    <div class="m-switch__slider" @click="switchTheme()"></div> </label
  ><span class="ml-2 uppercase align-middle text-xs font-bold text-foreground-high"> Dark Theme </span>
</template>
