// this kind of file is used in the client
// see app/assets/js/i18n/client.js

// DO NOT TRY TO BE CLEVER HERE
// Webpack will outclever you!
// If you try to dry this code up any further,
// webpack will merge every file located under locales
// under a single chunk (yeah)
// Yes, these files are repetitive but it avoids
// webpack lumping unnecessary locales together

import mergeDeepRight from "ramda/es/mergeDeepRight";

import categories from "~locales/en/categories.yml";
import custom from "~locales/en/custom.yml";
import db from "~locales/en/db.yml";
import rails from "~locales/en/rails.yml";
import tags from "~locales/en/tags.yml";
import subjects from "~locales/en/subjects.yml";
import user from "~locales/en/user.yml";
import webApp from "~locales/en/web-app.yml";
import validations from "@vee-validate/i18n/dist/locale/en.json";

const context = {
  categories,
  custom,
  db,
  rails,
  subjects,
  user,
  webApp,
  tags
};

export const en = Object.keys(context).reduce(
  (acc, key) => mergeDeepRight(acc, context[key]),
  {}
);

en.en.validations = mergeDeepRight(en.en.validations || {}, validations);

if (typeof window !== "undefined") {
  window.I18n = window.I18n || {};
  window.I18n.en = en;
}
export default JSON.stringify(en);
