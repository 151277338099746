<template>
  <inline-svg
    :src="require(`../assets/${name}.svg`)"
    :aria-label="name"
  ></inline-svg>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  props: {
    name: {
      type: String,
      required: true
    }
  },
  components: {
    InlineSvg
  }
};
</script>
