<script setup>
import { useRoute } from "vue-router";
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import CertificateSharing from "~src/components/CertificateSharing.vue";
import useCertificate from "~src/composables/certificate";
import { webAppURL } from "~src/lib/utils/urls";
import JsFileDownloader from "js-file-downloader";

const route = useRoute();
const store = useStore();
const displayedName = ref(undefined);

const props = defineProps({
  name: {
    type: String,
    default() {
      return undefined;
    }
  },
  course: {
    type: Object,
    default() {
      return {};
    }
  }
});

const {
  certificateURL,
  certificateImageURL,
  certificatePDFURL,
  linkedinCertificationURL,
  copied,
  copy
} = useCertificate({ course: props.course });

const download = computed(() => {
  return new JsFileDownloader({
    url: certificatePDFURL.value,
    autoStart: false,
    nameCallback: function (name) {
      return "certificate.pdf";
    },
    nativeFallbackOnError: true
  });
});

const sharingTitle = computed(() => `I completed ${props.course.course.name}`);

watch(
  store.state.profile,
  (n, o) => (displayedName.value = props.name || store.state.profile.name)
);

onMounted(() => {
  displayedName.value = props.name || store.state.profile.name;
});
</script>

<template>
  <div>
    <div class="flex items-center">
      <svg class="inline-block w-[1em] h-[1em] text-2xl fill-blue-medium mr-4">
        <use href="#icons-checked" />
      </svg>
      <span class="text-2xl font-bold"
        >Congratulations, {{ displayedName }}!</span
      >
    </div>

    <div class="mt-8 leading-normal">
      Congratulations, we're so happy to celebrate this achievement with you. We
      know you worked so hard to earn this certificate from Classpert and now
      it's your time to celebrate your success. Share it with colleagues, family
      and friends and keep inspiring others from your network on the importance
      of pursuing a life of studies and personal development
    </div>

    <div class="w-full text-center mt-8">
      <img
        class="mx-border"
        v-if="certificateImageURL"
        style="max-width: 100%"
        :src="certificateImageURL"
      />
      <div v-else class="m-skeleton" style="--m-skeleton-height: 25rem"></div>
    </div>

    <div class="mt-8 flex">
      <div class="flex w-full">
        <div class="flex items-center space-x-2">
          <span class="text-sm">Share</span>
          <CertificateSharing
            :title="sharingTitle"
            :url="certificateURL"
            twitterUser="classpert"
          />
        </div>
        <div
          class="flex items-center cursor-pointer lg:ml-8 ml-auto md:ml-8"
          @click="copy"
        >
          <span class="mr-1" v-if="copied">Copied!</span>
          <span class="mr-1" v-else>Copy link</span>
          <svg
            class="h-[1em] text-base inline-block"
            style="max-width: 0.875rem"
          >
            <use href="#icons-clipboard" />
          </svg>
        </div>
      </div>
      <a
        class="m-button hidden md:flex m-button--md ml-auto items-center m-button--outline m-button--foreground-high min-w-[180px]"
        download="certificate.pdf"
        @click="download.start()"
      >
        <svg
          class="flex-[0_0_auto] h-[1em] mr-2 fill-foreground-high inline-block"
          style="max-width: 0.875rem"
        >
          <use href="#icons-paper" /></svg
        ><span class="text-base" style="text-transform: none"
          >Download PDF</span
        >
      </a>
      <a
        class="ml-4 m-button hidden md:flex items-center justify-center m-button--md m-button--linkedin"
        style="width: auto"
        :href="linkedinCertificationURL"
        target="_blank"
      >
        Add to Linkedin</a
      >
    </div>
    <div class="mt-8 flex lg:hidden flex-wrap">
      <button
        class="m-button m-button--sm ml-auto flex items-center justify-center m-button--foreground-high m-button--outline w-full"
      >
        <svg
          class="h-[1em] mr-2 fill-foreground-high"
          style="max-width: 0.875rem"
        >
          <use href="#icons-paper" /></svg
        ><span class="text-base" style="text-transform: none"
          >Download PDF</span
        >
      </button>
      <a
        class="mt-4 m-button m-button--md m-button--linkedin w-full flex items-center justify-center h-12"
        :href="linkedinCertificationURL"
        target="_blank"
      >
        Add to Linkedin</a
      >
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
