<script setup>
import { computed } from "vue";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
import { useI18n } from "~src/lib/i18n";
import SocialMediaInput from "~components/form_fields/SocialMediaInput.vue";

const { t, i18n } = useI18n();

const props = defineProps({
  platforms: {
    type: Object
  },
  serverSideErrors: {
    type: Array,
    default() {
      return [];
    }
  },
  labelClass: {
    type: [String, Array],
    default() {
      return [];
    }
  },
  minRequired: {
    type: Number,
    default: 0
  },
  onSocialPlatformSelection: {
    type: Function
  },
  onSocialPlatformInput: {
    type: Function
  },
  onSocialPlatformRemoval: {
    type: Function
  }
});

const label = computed(() => {
  const content = t(
    "user.sections.account_settings.profile.sections.personal.sections.social_media"
  );
  if (props.minRequired > 0) {
    return `${content} <span>(At least ${props.minRequired} required)</span>`;
  } else {
    return content;
  }
});
</script>

<template>
  <div class="m-form-field">
    <div class="m-form-field__label m-form-field__label--over">
      <label v-html="label" for="social" :class="labelClass"></label>
    </div>
    <div class="m-select relative">
      <select
        @change="e => props.onSocialPlatformSelection(e)"
        :disabled="props.platforms.social.available.length === 0"
      >
        <option value="">
          {{
            props.platforms.social.added.length > 1
              ? t("user.forms.profile.social_profiles_field_select_n", {
                  n: props.platforms.social.added.length
                })
              : t(
                  `user.forms.profile.social_profiles_field_select_${props.platforms.social.added.length}`
                )
          }}
        </option>
        <option
          v-for="option in props.platforms.social.available"
          :value="option"
          :key="option"
        >
          {{ upperFirst(camelCase(option)) }}
        </option>
      </select>
      <div
        v-if="props.platforms.social.added.length < props.minRequired"
        class="text-red-medium absolute"
      >
        <span class="text-xs">At least {{ props.minRequired }} required</span>
      </div>
    </div>

    <div
      :class="[index === 0 ? 'mt-8' : 'mt-4']"
      :key="platform"
      v-for="(platform, index) in props.platforms.social.added"
    >
      <social-media-input
        :platform="platform"
        :server-side-errors="
          props.serverSideErrors.public_profile &&
          props.serverSideErrors.public_profile[platform]
        "
        type="social"
        :modelValue="props.platforms.social.ids"
        @input="props.onSocialPlatformInput"
      >
        <template #control>
          <svg
            @click="() => props.onSocialPlatformRemoval(platform)"
            :key="`${platform}-remove`"
            class="user:platform-remover"
          >
            <use xlink:href="#icons-trash" />
          </svg>
        </template>
      </social-media-input>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user\:platform-remover {
  display: inline-block;
  cursor: pointer;
  width: 1.125em;
  height: 1.125em;
  fill: rgb(var(--foreground-medium));
  &:hover {
    fill: rgb(var(--red-medium));
  }
}
</style>
