<script setup>
import { ref, computed, watch, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ScholarshipForm from "~src/components/scholarship/Form.vue";
import ScholarshipFormSkeleton from "~src/components/scholarship/FormSkeleton.vue";
import { formattedShortDateFromISO } from "~utils/tz";
import { useToast } from "~external/v3/Toast/api";

const route = useRoute();
const router = useRouter();
const store = useStore();
const toast = useToast();

const top = ref(null);
const smoothScroll = inject("smoothScroll");
const scrollToTop = () => {
  smoothScroll({
    scrollTo: top.value,
    updateHistory: false,
    offset: -600
  });
};
const operation = ref("create");

const submitted = () => {
  scrollToTop();
  toast.open({
    message:
      "Application successfully submitted. It will be reviewed and responded by our instructional staff.",
    duration: 10000,
    type: "l1",
    position: "top-right"
  });
};

const longTimeFormat = computed(
  () => store.state.profile.longTimeFormat || "tt ZZZZ"
);

const longDateFormat = computed(
  () => store.state.profile.longDateFormat || "DDDD"
);

const dateTimeFormat = computed(
  () => `${longDateFormat.value} ${longTimeFormat.value}`
);

const cohortLoaded = computed(() => {
  return store.state.cohort.loaded;
});

const userAccountLoaded = computed(() => {
  return store.state.userAccount.loaded;
});

const addressLoaded = computed(() => {
  return store.state.address.loaded;
});

const scholarship = ref({});
const scholarshipLoaded = ref(false);

const loaded = computed(() => {
  return (
    cohortLoaded.value &&
    userAccountLoaded.value &&
    addressLoaded.value &&
    scholarshipLoaded.value
  );
});

const cohort = computed(() => {
  return (
    store.getters["cohort/getScholarshippableCohort"](
      route.params.course_slug,
      route.params.slug
    ) || {}
  );
});

store.dispatch("cohort/scholarshippable");
store.dispatch("address/get");

watch(cohortLoaded, (n, o) => {
  if (cohortLoaded) {
    if (!cohort.value.id) {
      router.replace({ name: "Root" });
    } else {
      store
        .dispatch("scholarship/get", {
          applicableId: cohort.value.id,
          applicableType: "Cohort"
        })
        .then(s => {
          if (s) {
            operation.value = "update";
          }
          scholarship.value = s || {};
          scholarshipLoaded.value = true;
        });
    }
  }
});
</script>

<template>
  <div class="container mx-auto max-lg:px-3 mt-8" ref="top">
    <div class="grid grid-cols-12 gap-x-2">
      <div
        class="col-span-12 lg:col-start-4 lg:col-span-6"
        v-if="cohort && cohort.course"
      >
        <h2>Apply for Scholarship</h2>

        <div class="mt-4 space-y-3 bg-background py-6 px-4">
          <div>
            <div class="flex items-center">
              <svg class="w-[1em] h-[1em] mr-1">
                <use href="#icons-presentation" />
              </svg>
              <span class="font-bold">Course</span>
            </div>
            <div class="mt-1">{{ cohort.course.name }}</div>
          </div>
          <div>
            <div class="flex items-center">
              <svg class="w-[1em] h-[1em] mr-1">
                <use href="#icons-cohort" />
              </svg>
              <span class="font-bold">Cohort</span>
            </div>
            <div class="mt-1">
              {{ cohort.name }} - Cohort {{ cohort.number }}
            </div>
          </div>
          <div>
            <div class="flex items-center">
              <svg class="w-[1em] h-[1em] mr-1">
                <use href="#icons-clock" />
              </svg>
              <span class="font-bold">Class Period</span>
            </div>
            <div class="mt-1">
              {{ formattedShortDateFromISO(cohort.starts_at, longDateFormat) }}
              -
              {{ formattedShortDateFromISO(cohort.ends_at, longDateFormat) }}
            </div>
          </div>
          <div>
            <div class="flex items-center">
              <span class="font-bold">Available Scholarships</span>
            </div>
            <div class="mt-1">
              2
            </div>
          </div>
        </div>

        <div class="mt-5 leading-tight">
          In addition to presenting a strong application and meeting the course prerequisites, individuals falling into
          certain categories below will have a slight advantage:
        </div>

        <ul class="mt-4 leading-tight">
          <li>People with relevant proven work in the field</li>
          <li>
            Undergraduate students or professionals from developing countries
          </li>
          <li>
            Individuals belonging to minority groups that are underrepresented
            in tech
          </li>
        </ul>

        <div class="mt-4 m-alert m-alert--flat-l1">
          All applications will be reviewed by the instructional staff and
          responded to two days before class starts.
        </div>

        <ScholarshipForm
          v-if="loaded"
          :cohort="cohort"
          :scholarship="scholarship"
          :operation="operation"
          @submitted="submitted"
        />
        <ScholarshipFormSkeleton class="mt-4 mb-12" v-else />
      </div>
      <div class="col-span-12 lg:col-start-4 lg:col-span-6" v-else>
        <h2>Apply for Scholarship</h2>

        <div class="m-skeleton mt-4 space-y-1" style="color: transparent">
          <div></div>
          <div></div>
          <div>Class Period:</div>
        </div>

        <div class="mt-4 m-skeleton" style="color: transparent">
          In addition to presenting a strong application and meeting the course prerequisites, individuals falling into
          certain categories below will have a slight advantage:
        </div>

        <ul class="mt-4 m-skeleton" style="color: transparent">
          <li>People with relevant proven work in the field</li>
          <li>
            Undergraduate students or professionals from developing countries
          </li>
          <li>
            Individuals belonging to minority groups that are underrepresented
            in tech
          </li>
        </ul>

        <div class="mt-4 mb-8 m-skeleton" style="color: transparent">
          All applications will be reviewed by the instructional staff and
          responded to two days before class starts.
        </div>

        <ScholarshipFormSkeleton class="mt-4 mb-12" />
      </div>
    </div>
  </div>
</template>
