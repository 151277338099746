import { computed, watch, unref } from "vue";
import { useStore } from "vuex";

const DARK_THEME = "classpert-dark";
const LIGHT_THEME = "classpert-light";

const setTheme = theme =>
  document.documentElement.setAttribute("data-theme", unref(theme));

export default function useTheme() {
  const store = useStore();

  const theme = computed(
    () => store.state.profile.preferences?.theme || LIGHT_THEME
  );

  setTheme(theme);

  watch(theme, () => setTheme(theme));

  return {
    theme
  };
}
