<script setup>
import Icon from "~external/Icon.vue";
import _ from "lodash";
import SectionFrame from "~components/SectionFrame.vue";
import StickyTable from "~external/StickyTable.vue";
import clone from "ramda/es/clone";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import {
  headers,
  formattedDescription,
  formattedPaymentType,
  usePurchaseHistory
} from "~src/composables/purchaseHistory";

const store = useStore();
const orders = ref([]);
const loading = ref(true);

const listOrders = params => store.dispatch("order/list", params);

listOrders().then(data => {
  orders.value = data;
  loading.value = false;
});

const profile = computed(() => store.state.profile);
const { purchaseData } = usePurchaseHistory({ orders, profile });
</script>

<template>
  <div class="col-span-12 lg:col-span-8">
    <div class="mb-4 font-bold">Purchase History</div>
    <div class="mx-border-t mb-10"></div>

    <section-frame>
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-12">
          <sticky-table
            class="text-base m-table--highlight-on-hover"
            :sticky-col-behavior="true"
            sticky-col="order_code"
            :data="purchaseData"
            :header="headers"
            :checkable="false"
          >
            <template #tbody-receipt_url="slotProps">
              <a
                v-if="slotProps.record.receipt_url"
                :href="slotProps.record.receipt_url"
                target="_blank"
                class="text-blue-medium hover:text-blue-medium"
                >Receipt</a
              >
            </template>
          </sticky-table>
        </div>
      </div>
    </section-frame>
  </div>
</template>

<style lang="scss">
th {
  white-space: nowrap;
}
</style>
