<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import Certificate from "~src/components/Certificate.vue";
import useCertificateValidation from "~src/composables/certificateValidation";
import { webAppURL } from "~src/lib/utils/urls";

const route = useRoute();
const router = useRouter();
const store = useStore();
const step = ref(1);
const confirmation = ref(false);
const { state, v$ } = useCertificateValidation({ initialValues: {} });

watch(
  store.state.profile,
  (n, o) => (v$.value.name.$model = store.state.profile.name)
);

onMounted(() => {
  v$.value.name.$model = store.state.profile.name;
});

const steps = [
  "Name on Certificate",
  "Preview your Certificate",
  "Your Certificate"
];

function wizardStep(s) {
  if (step.value == s) {
    return "active";
  } else if (step.value > s) {
    return "done";
  } else {
    return "inactive";
  }
}

const course = computed(() => {
  return (
    store.getters["course/getProduct"](
      route.params.course_slug,
      route.params.slug
    ) || {}
  );
});

const issued = computed(() => {
  return (
    course.value.registration &&
    course.value.registration.certificate_issuing_state === "issued"
  );
});

const pending = computed(() => {
  return (
    course.value.registration &&
    course.value.registration.certificate_issuing_state === "pending"
  );
});

watch(course, () => {
  // TEMPORARY
  router.replace({ name: "MyCoursesCohorts" });

  // if (!pending.value) {
  //   if (issued.value) {
  //     router.replace({
  //       name: "CertificateShow",
  //       params: {
  //         course_slug: route.params.course_slug,
  //         slug: route.params.slug
  //       }
  //     });
  //   } else {
  //     router.replace({ name: "MyCoursesCohorts" });
  //   }
  // }
});
</script>

<template>
  <div id="wizard" v-if="pending">
    <div class="container mx-auto max-lg:px-3">
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-12 lg:col-start-3 lg:col-span-8">
          <div class="mt-8">
            <router-link
              :to="{ name: 'MyCoursesCohorts' }"
              class="flex items-center"
            >
              <svg
                class="w-[1em] mr-2"
                style="height: auto; max-height: 1em; transform: rotate(180deg)"
              >
                <use href="#icons-arrow-stroke" />
              </svg>

              <span>Return to dashboard</span>
            </router-link>
          </div>

          <div class="mt-8 p-8 mx-border rounded">
            <div class="m-wizard-progress">
              <ol>
                <li
                  v-for="(step, index) in steps"
                  :key="step"
                  :data-state="wizardStep(index + 1)"
                  :data-label="step"
                />
              </ol>
            </div>

            <div
              class="w-full my-10"
              style="border-top: 1px solid #c4c4c4"
            ></div>

            <!-- Name on certificate -->
            <div v-if="step === 1">
              <div class="text-2xl font-bold">{{ steps[step - 1] }}</div>

              <div class="mt-8">
                Please, verify that <b>{{ v$.name.$model }}</b> is your full
                legal name as identified by your government issued
                identification and it is spelled correctly. This is especially
                important if you are using this certificate for a job or school
                application.
              </div>

              <div class="mt-4">
                If your name is incorrect, please change it below before
                proceeding
              </div>

              <div
                class="field mt-8"
                :class="{
                  'field--error': v$.name.$errors.length > 0
                }"
              >
                <div class="field__input">
                  <input type="text" v-model="v$.name.$model" />
                </div>
                <div v-if="v$.name.$errors.length > 0" class="text-red-medium">
                  <span class="text-xs">{{ v$.name.$errors[0].$message }}</span>
                </div>
              </div>

              <div class="mt-8 flex">
                <button
                  :disabled="v$.name.$errors.length > 0 ? true : null"
                  class="hidden md:block ml-auto m-button m-button--sm m-button--blue-medium m-button--primary"
                  @click="step = step + 1"
                >
                  Continue
                </button>
                <button
                  :disabled="v$.name.$errors.length > 0 ? true : null"
                  class="lg:hidden ml-auto m-button m-button--sm m-button--blue-medium m-button--primary w-full block"
                  @click="step = step + 1"
                >
                  Continue
                </button>
              </div>
            </div>

            <!-- Preview your certificate -->
            <div v-if="step === 2">
              <div class="text-2xl font-bold">{{ steps[step - 1] }}</div>
              <div class="w-full text-center mt-8">
                <img style="max-width: 100%" :src="certificateImageURL" />
              </div>
              <div class="mt-8 m-alert m-alert--flat-l2 text-foreground-high">
                Once issued, you’ll only be able to re-issue this certificate
                after going through an identity verification process.
              </div>
              <div
                class="m-checkbox mt-8 text-base"
                @click="confirmation = !confirmation"
              >
                <div class="m-checkbox__selector">
                  <input
                    name="confirmation"
                    type="checkbox"
                    :checked="confirmation"
                    value="yes"
                  />
                  <label></label>
                </div>
                <span class="m-checkbox__label">
                  I confirm that I am the person to whom the certificate will be
                  issued and I certify the veracity of the information contained
                  therein
                </span>
              </div>
              <div class="mt-8 flex flex-wrap md:flex-nowrap">
                <button
                  class="hidden md:block mr-auto m-button m-button--sm m-button--blue-medium m-button--outline"
                  @click="step = step - 1"
                >
                  Edit Name
                </button>
                <button
                  class="lg:hidden m-button m-button--sm m-button--blue-medium m-button--outline w-full block"
                  @click="step = step - 1"
                >
                  Edit Name
                </button>

                <button
                  class="hidden md:block ml-auto m-button m-button--sm m-button--blue-medium m-button--primary"
                  :disabled="confirmation ? null : true"
                  @click="step = step + 1"
                >
                  Issue Certificate
                </button>
                <button
                  class="mt-4 lg:hidden m-button m-button--sm m-button--blue-medium m-button--primary w-full block"
                  :disabled="confirmation ? null : true"
                  @click="step = step + 1"
                >
                  Issue Certificate
                </button>
              </div>
            </div>

            <!-- Your certificate -->
            <div v-if="step === 3">
              <Certificate :name="v$.name.$model" :course="course" />
            </div>
          </div>
          <div class="mt-6 mb-24 text-center">
            If you need to re-issue your certificate, please
            <a
              :href="webAppURL('contact-us')"
              class="text-blue-medium hover:text-blue-medium"
              target="_blank"
              >contact us</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
