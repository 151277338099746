import { useStore } from "vuex";

export default function useOffCanvas() {
  const store = useStore();

  return {
    open: () => store.commit("menu/open"),
    close: () => store.commit("menu/close")
  };
}
