<script setup>
import Modal from "~external/v3/Modal.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import TextInputField from "~components/form_fields/TextInput.vue";
import { crossOriginXHR } from "~config/axios/instances";
import { useToast } from "~external/v3/Toast/api";
import { logoutURL } from "~src/lib/utils/urls";

const props = defineProps({
  opened: {
    type: Boolean,
    required: true,
    default: false
  }
});

const toast = useToast();
const store = useStore();
const name = "DestroyAccountModal";
const emit = defineEmits(["close"]);
const password = ref("");
const verification = ref("");

const requestDestroyAccount = token => {
  return crossOriginXHR.post("api/auth/destroy_account", {
    public_id: store.state.userAccount.public_id,
    password: password.value
  });
};

const failureToast = message => {
  toast.open({
    message: message,
    duration: 10000,
    type: "l3",
    position: "bottom"
  });
};

const submit = e => {
  requestDestroyAccount()
    .then(response => {
      emit("close", e);
      window.location.href = logoutURL;
    })
    .catch(() => {
      emit("close", e);
      failureToast(
        "Password verification failed, please ensure you entered the correct password"
      );
    });
};

const verificationString = "delete my account";
const verificationStringLabel = `To verify, type "${verificationString}" below`;
const submitDisabled = computed(() => verification.value != verificationString);

const submitColor = computed(() => {
  if (submitDisabled.value == true) {
    return "";
  } else {
    return "bg-red-medium border-red-medium";
  }
});

const close = e => {
  password.value = "";
  verification.value = "";
  emit("close", e);
};
</script>

<template>
  <modal
    :name="name"
    :opened="opened"
    @close="close"
    :maskClasses="['grid grid-cols-12 gap-x-2']"
    :bodyClasses="['px-4']"
    :wrapperClasses="[
      'bg-transparent',
      'max-md:px-3',
      'items-center',
      'justify-center',
      'col-span-12',
      'md:col-span-6',
      'md:col-start-4',
      'xl:col-span-4',
      'xl:col-start-5',
      'my-auto'
    ]"
    :containerClasses="['p-[1em]']"
  >
    <template #body>
      <div class="flex items-center m-4 leading-tight">
        <div class="space-y-2">
          <p class="text-2xl font-bold text-center mt-4">Destroy Account</p>

          <div
            class="!mt-8 px-2 py-2 rounded text-sm text-red-medium font-bold uppercase"
            style="background-color: rgb(var(--red-medium) / var(--alpha))"
          >
            <span>&#x26A0; Danger zone</span>
          </div>

          <p class="mb-0 text-sm">
            We will immediately delete your account and you won't be able to
            retrieve any information from your account in the future. This
            includes the ability to:
          </p>
          <ul class="mt-0 text-sm">
            <li>Ask for refunds</li>
            <li>Issue or download certificates</li>
          </ul>

          <p class="text-sm">
            Your username will be available to anyone on Classpert.
          </p>

          <p class="text-sm">
            Your will immediately be logged out after confirming you account
            deletion.
          </p>

          <text-input-field
            class="mb-4"
            input-type="password"
            field="password"
            label="Insert your password to confirm your identity"
            input-size="medium"
            :input-block="true"
            input-placeholder="Type you password"
            v-model="password"
          />

          <text-input-field
            class="mb-4"
            field="verification"
            :label="verificationStringLabel"
            input-size="medium"
            :input-block="true"
            :input-placeholder="verificationString"
            v-model="verification"
          />

          <input
            :disabled="submitDisabled"
            @click="submit"
            class="mt-6 m-button m-button--blue-medium m-button--primary m-button--md w-full block"
            :class="submitColor"
            value="Destroy Account"
          />
        </div>
      </div>
    </template>
  </modal>
</template>
