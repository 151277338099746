import { ref, computed } from "vue";
import { useStore } from "vuex";
import { urls } from "~src/config/environment";
import thumbor from "~src/lib/thumbor";

const productURL = (slug, productSlug, newLMS, path) => {
  if (newLMS) {
    return `https://school.classpert.com/courses/${slug}/classrooms/${productSlug}${
      path || ""
    }`;
  }

  return `${urls.lmsURL}/courses/${slug}/cohorts/${productSlug}${path || ""}`;
};

const productImage = files => {
  const file = files.filter(({ sub_id }) => sub_id === "avatar")[0];
  if (file) {
    return thumbor(file)("default");
  } else {
    return undefined;
  }
};

const avatarImage = files => {
  const file = files.filter(({ sub_id }) => sub_id === "avatar")[0];
  if (file) {
    return thumbor(file)("default");
  } else {
    return undefined;
  }
};

const finalProjectCritiqueURL = files => {
  const file = files.filter(({ sub_id }) => sub_id === "final_evaluation")[0];

  if (file) {
    return "https://cdn.classpert.com/uploads/" + file.name;
  } else {
    return undefined;
  }
};

const cohortEnded = cohort => Date.now() > Date.parse(cohort.ends_at);
const cohortBegun = cohort => Date.now() > Date.parse(cohort.starts_at);
const cohortState = cohort => {
  if (cohortBegun(cohort)) {
    if (cohortEnded(cohort)) {
      return "completed";
    } else {
      return "ongoing";
    }
  } else {
    return "upcoming";
  }
};
const cohortColor = cohort => {
  switch (cohortState(cohort)) {
    case "completed":
      return "orange-medium";
      break;
    case "ongoing":
      return "purple-medium";
    default:
      return "blue-medium";
  }
};
const cohortRole = registration => {
  switch (registration.role) {
    case "student":
      return "Student";
    case "coordinator":
      return "Coordinator";
    case "assitant_coordinator":
      return "Assistant Coordinator";
    case "mentor":
      return "Mentor";
    case "instructor":
      return "Instructor";
    case "auditor":
      return "Auditor";
    case "admin":
      return "Admin";
  }
};

const MAX_ACTIVE_COURSES = 3;

export default function useMyCourses() {
  const store = useStore();
  const courses = computed(() => store.state.course.courses);
  const activeCourses = computed(() => {
    return courses.value
      .filter(course => course.state != "suspended")
      .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
      .slice(0, MAX_ACTIVE_COURSES);
  });
  const listCourses = () => store.dispatch("course/list");

  return {
    courses,
    activeCourses,
    listCourses,
    avatarImage,
    productURL,
    productImage,
    cohortEnded,
    cohortBegun,
    cohortState,
    cohortColor,
    cohortRole,
    finalProjectCritiqueURL
  };
}
