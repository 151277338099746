import { createRouter as _createRouter, createWebHistory } from "vue-router";

// Components
import Home from "../components/Home.vue";
import AccountSettings from "../components/AccountSettings.vue";
import PurchaseHistory from "../components/PurchaseHistory.vue";
import Profile from "../components/Profile.vue";
import MyCourses from "../components/MyCourses.vue";
import MyCoursesCohorts from "../components/my_courses/Cohorts.vue";
import MyCoursesOnDemand from "../components/my_courses/OnDemand.vue";
import CertificateShow from "../components/CertificateShow.vue";
import CertificateWizard from "../components/CertificateWizard.vue";
import Scholarship from "../components/Scholarship.vue";

const routes = [
  {
    path: "/",
    name: "Root",
    redirect: "/account-settings",
    component: Home,
    children: [
      {
        path: "/account-settings",
        name: "AccountSettings",
        component: AccountSettings
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile
      },
      {
        path: "/my-courses",
        name: "MyCourses",
        component: MyCourses,
        children: [
          {
            path: "cohorts",
            name: "MyCoursesCohorts",
            component: MyCoursesCohorts
          },
          {
            path: "on-demand",
            name: "MyCoursesOnDemand",
            component: MyCoursesOnDemand
          }
        ]
      },
      {
        path: "/purchase-history",
        name: "PurchaseHistory",
        component: PurchaseHistory
      }
    ]
  },
  {
    path: "/my-courses/:course_slug/cohorts/:slug/certificate",
    name: "CertificateShow",
    component: CertificateShow
  },
  {
    path: "/my-courses/:course_slug/cohorts/:slug/certificate/issue",
    name: "CertificateWizard",
    component: CertificateWizard
  },
  {
    path: "/courses/:course_slug/cohorts/:slug/scholarship",
    name: "Scholarship",
    component: Scholarship,
    meta: { plain: true }
  }
];

export function createRouter() {
  const router = _createRouter({
    // use appropriate history implementation for server/client
    // import.meta.env.SSR is injected by Vite.
    history: createWebHistory(),
    routes
  });

  return router;
}

const router = createRouter();

export default router;
