<script setup>
import { computed, onMounted } from "vue";
import OffCanvasMenu from "~external/v3/OffCanvasMenu.vue";
import session from "~src/config/session";
import useOffCanvas from "~src/composables/offCanvas";
import useAvatar from "~src/composables/avatar";
import useMyCourses from "~src/composables/myCourses";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "~src/lib/i18n";
import useProfile from "~src/composables/profile";
import { urls, paths } from "~config/environment";
import Label from "~src/components/Label.vue";

import {
  webAppURL,
  apiDocsURL,
  dashboardURL,
  logoutURL
} from "~src/lib/utils/urls";

const store = useStore();
const route = useRoute();
const { t, i18n } = useI18n();

const { open, close } = useOffCanvas();
const { avatar } = useAvatar();
const { firstName } = useProfile();
const {
  courses,
  activeCourses,
  listCourses,
  productURL,
  productImage,
  cohortEnded,
  cohortBegun,
  cohortState,
  cohortColor,
  cohortRole
} = useMyCourses();

listCourses();
</script>

<template>
  <off-canvas-menu>
    <template #default>
      <div
        :class="[
          'm-6',
          'o-off-canvas-menu__header-slot',
          'flex',
          'items-center'
        ]"
        style="height: 2.1875rem"
      >
        <div class="o-off-canvas-menu__control" @click="close"></div>
        <div class="flex items-center" style="height: 32px">
          <div class="m-avatar m-avatar--circle m-avatar--32x32 mr-2">
            <img :src="avatar.url" v-if="avatar.url" />
            <svg v-else>
              <use href="#icons-avatar-light-gray"></use>
            </svg>
          </div>
          <div class="flex flex-col">
            <div class="relative font-bold text-base">
              {{ t("user.greetings", { name: firstName }) }}!
            </div>
          </div>
        </div>
      </div>
      <div class="mx-border-t ml-auto mr-auto w-full"></div>
      <div class="text-sm o-off-canvas-menu__body-slot">
        <!-- links -->
        <div class="p-6 space-y-4">
          <a
            class="block font-bold text-sm"
            target="_blank"
            :href="webAppURL('topics')"
            >{{ t("Topics") }}</a
          >
          <a
            class="block font-bold text-sm"
            target="_blank"
            :href="webAppURL('blog')"
            >{{ t("Blog") }}</a
          >
          <a
            class="block font-bold text-sm"
            target="_blank"
            :href="webAppURL('contact-us')"
            >{{ t("contact_us.new.title") }}</a
          >
        </div>
        <div class="mx-border-t ml-auto mr-auto w-full"></div>

        <!-- dashboard -->
        <div class="relative p-6 space-y-4">
          <div
            class="text-foreground-medium text-xs font-bold tracking-widest uppercase"
          >
            Dashboard
          </div>
          <div
            class="items-center mt-6 flex hover:text-blue-medium"
            :class="{ 'text-blue-medium': route.name == 'Profile' }"
          >
            <svg class="w-[1em] h-[1em] mr-2" style="color: inherit">
              <use href="#icons-profile"></use>
            </svg>
            <router-link
              @click.prevent="close"
              style="color: inherit"
              :to="{ name: 'Profile' }"
              >{{ t("Profile") }}</router-link
            >
          </div>
          <div
            class="flex items-center hover:text-blue-medium hover:fill-blue-medium"
            :class="{
              'fill-blue-medium': route.name == 'AccountSettings',
              'text-blue-medium': route.name == 'AccountSettings',
              'fill-foreground-high': route.name != 'AccountSettings'
            }"
          >
            <svg
              class="w-[1em] h-[1em] mr-2"
              style="fill: inherit; color: inherit"
            >
              <use href="#icons-gear"></use>
            </svg>
            <router-link
              @click.prevent="close"
              style="color: inherit"
              :to="{ name: 'AccountSettings' }"
              >{{ t("Account Settings") }}</router-link
            >
          </div>
          <div class="text-foreground-medium text-sm mt-6">ClasspertX</div>
          <div
            class="mt-3 flex items-center hover:fill-blue-medium hover:text-blue-medium"
            :class="{
              'fill-blue-medium': route.name == 'PurchaseHistory',
              'text-blue-medium': route.name == 'PurchaseHistory',
              'fill-foreground-high': route.name != 'PurchaseHistory'
            }"
          >
            <svg
              class="w-[1em] h-[1em] mr-2"
              style="fill: inherit; color: inherit"
            >
              <use href="#icons-receipt"></use>
            </svg>
            <router-link
              @click.prevent="close"
              style="color: inherit"
              :to="{ name: 'PurchaseHistory' }"
              >{{ t("Purchase History") }}</router-link
            >
          </div>
          <div
            class="flex items-center hover:fill-blue-medium hover:text-blue-medium"
            :class="{
              'fill-blue-medium': route.name == 'MyCoursesCohorts',
              'text-blue-medium': route.name == 'MyCoursesCohorts',
              'fill-foreground-high': route.name != 'MyCoursesCohorts'
            }"
          >
            <svg
              class="w-[1em] h-[1em] mr-2"
              style="fill: inherit; color: inherit"
            >
              <use href="#icons-cohort"></use>
            </svg>
            <router-link
              @click.prevent="close"
              style="color: inherit"
              :to="{ name: 'MyCoursesCohorts' }"
              >{{ t("Cohorts") }}</router-link
            >
          </div>
          <div
            v-if="false"
            class="flex items-center hover:fill-blue-medium hover:text-blue-medium"
            :class="{
              'fill-blue-medium': route.name == 'MyCoursesOnDemand',
              'text-blue-medium': route.name == 'MyCoursesOnDemand',
              'fill-foreground-high': route.name != 'MyCoursesOnDemand'
            }"
          >
            <svg
              class="w-[1em] h-[1em] mr-2"
              style="fill: inherit; color: inherit"
            >
              <use href="#icons-on-demand"></use>
            </svg>
            <router-link
              @click.prevent="close"
              style="color: inherit"
              :to="{ name: 'MyCoursesOnDemand' }"
              >On Demand</router-link
            >
          </div>
          <div
            class="text-foreground-medium text-sm mt-6"
            v-if="activeCourses.length"
          >
            {{ t("My Learnings") }}
          </div>
          <ul class="mt-2 mx-list-reset space-y-2" v-if="activeCourses.length">
            <li :key="cohort.id" v-for="{ cohort } in activeCourses">
              <a
                class="block p-2 mx-border rounded"
                target="_blank"
                :href="
                  productURL(
                    cohort.course.slug,
                    cohort.slug,
                    cohort.new_lms,
                    '/about'
                  )
                "
              >
                <div class="text-sm">{{ cohort.course.name }}</div>
                <div class="mt-2 flex justify-between">
                  <div class="text-sm flex">
                    <img
                      class="mr-1"
                      :src="productImage(cohort.files)"
                      style="width: 1rem; height: 1rem"
                      v-if="productImage(cohort.files)"
                    />
                    <div>{{ cohort.name }}</div>
                  </div>
                  <div class="flex items-center">
                    <Label class="text-purple-medium text-xs mr-2">
                      {{ cohort ? "Cohort" : "On Demand" }}
                    </Label>
                    <svg class="w-[1em] h-[1em] text-xs fill-foreground-high">
                      <use href="#icons-external-arrow"></use>
                    </svg>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>

        <div class="mx-border-t ml-auto mr-auto w-full"></div>
        <div class="flex relative p-6">
          <div
            class="flex items-center hover:fill-blue-medium hover:text-blue-medium fill-foreground-high"
          >
            <svg
              class="w-[1em] h-[1em] mr-2"
              style="fill: inherit; color: inherit"
            >
              <use href="#icons-logout"></use>
            </svg>
            <a :href="logoutURL" style="color: inherit">{{
              t("dictionary.sign_out")
            }}</a>
          </div>
        </div>
      </div>
    </template>
  </off-canvas-menu>
</template>
