import { validate } from "./base";

export const canonicalURL = id => `hackaday.io/${id}`;

export const validator = validate({
  host: {
    pattern: /^(?:hackaday\.io)$/
  },
  id: {
    pattern: /^(?!.*(?:\/))[A-z\d\.]{2,25}$/,
    where: [{ position: 0 }]
  },
  canonicalURL
});

export default {
  canonicalURL,
  validator
};
