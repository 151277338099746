import address from "./address";
import application from "./application";
import course from "./course";
import cohort from "./cohort";
import emailVerificationModalStatus from "./emailVerificationModalStatus";
import locale from "./locale";
import menu from "./menu";
import file from "./file";
import finalProject from "./finalProject";
import oauthAccount from "./oauthAccount";
import oauthAvailableProvider from "./oauthAvailableProvider";
import order from "./order";
import progressBar from "./progressBar";
import profile from "./profile";
import userAccount from "./userAccount";
import certificate from "./certificate";
import settings from "./settings";
import scholarship from "./scholarship";
import topAlert from "./topAlert";

export default {
  address,
  application,
  cohort,
  course,
  emailVerificationModalStatus,
  finalProject,
  locale,
  oauthAccount,
  oauthAvailableProvider,
  order,
  menu,
  progressBar,
  profile,
  userAccount,
  certificate,
  scholarship,
  settings,
  topAlert,
  file
};
