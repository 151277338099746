<script setup>
import clone from "ramda/es/clone";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
import SectionFrame from "~components/SectionFrame.vue";
import TextInputField from "~components/form_fields/TextInput.vue";
import Alert from "~components/Alert.vue";
import { snakeCasedKeys, normalizeUrl, safeJSONParse } from "~utils";
import { unref, ref, inject, watch, computed } from "vue";
import { useI18n } from "~src/lib/i18n";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { allCountries } from "country-region-data";
import useCountries from "~src/composables/countries";

const FIELDS = {
  phoneNumber: undefined,
  streetLine1: undefined,
  streetLine2: undefined,
  zipCode: undefined,
  country: undefined,
  state: undefined,
  city: undefined
};

const { t, i18n } = useI18n();
const store = useStore();
const emit = defineEmits(["success", "error"]);
const addressOperation = ref("create");

const initializer = () => undefined;
const alertInitializer = () => ({ message: "", type: "" });

const top = ref(null);
const smoothScroll = inject("smoothScroll");
const scrollToTop = () => {
  smoothScroll({
    scrollTo: top.value,
    updateHistory: false,
    offset: -100
  });
};

const address = ref({});
const loading = ref(true);

const getAddress = params => store.dispatch("address/get", params);
getAddress().then(data => {
  if (data) {
    addressOperation.value = "update";
  }
  address.value = clone(store.state.address);
  loading.value = false;
});

const user = store.state.userAccount;

const { countries } = useCountries();
const serverSideErrors = ref([]);
const alert = ref(alertInitializer());
const sending = ref(false);
const persist = ({ payload: data }) => {
  const payload = {
    user_account_id: user.id,
    ...data
  };

  return addressOperation.value === "create"
    ? store.dispatch("address/create", { payload })
    : store.dispatch("address/update", { id: payload.id, payload });
};

const regions = computed(() => {
  if (address.value.country) {
    var countryRegions = allCountries
      .find(element => element[1] == (address.value.country || "US"))[2]
      .map(k => k[0]);

    if (!countryRegions.includes(address.value.state)) {
      address.value.state = countryRegions[0];
    }
    return countryRegions;
  } else {
    address.value.state = "";
    return [];
  }
});

const submit = event => {
  event.preventDefault();

  serverSideErrors.value = [];
  sending.value = true;

  var payload = snakeCasedKeys({
    ...address.value
  });

  payload["street_line1"] = payload["street_line_1"];
  delete payload["street_line_1"];
  payload["street_line2"] = payload["street_line_2"];
  delete payload["street_line_2"];

  payload["name"] = "Profile Address";
  payload["address_type"] = "shipping";

  if (!payload["id"]) {
    var new_id = uuidv4();
    address.value.id = new_id;
    payload["id"] = new_id;
  }

  persist({ payload })
    .then(() => {
      sending.value = false;
      alert.value = {
        message: t("user.sections.account_settings.profile.success_message"),
        type: "success"
      };
      setTimeout(() => (alert.value = alertInitializer()), 5000);
      emit("success", { what: "personal" });
      scrollToTop();
      addressOperation.value = "update";
    })
    .catch(error => {
      sending.value = false;
      const { response } = error;

      const errorDetail = response?.data?.details || "unmapped_constraint";
      const errorHint = safeJSONParse(response?.data?.hint);
      const errorField =
        errorDetail.match(/^(?:(?<field>.*)__)/)?.groups?.field || "profile";
      const errorMessage = i18n
        .t(`db.${errorDetail}`, errorHint)
        ?.replace(/\.$/, "");

      serverSideErrors.value = {
        [errorField]: [errorMessage]
      };

      alert.value = {
        message: "Please, provide complete information",
        type: "danger"
      };

      emit("error", {
        what: "instructor",
        where: errorField,
        why: errorMessage
      });
      scrollToTop();
    });
};

const emailConfirmed = computed(() => store.state.userAccount.confirmed_at);

const submitOrModal = event => {
  if (emailConfirmed.value) {
    submit(event);
  } else {
    store.commit("emailVerificationModalStatus/change", { open: true });
  }
};
</script>

<template>
  <div class="mt-10 pt-10 mx-border-t">
    <div
      ref="top"
      class="font-bold uppercase text-xs text-foreground-medium tracking-widest mb-6"
    >
      Contact Info
    </div>

    <alert v-if="alert" class-list="text-xs pL10 mb-4" v-bind="alert"></alert>

    <form action="#" ref="form">
      <text-input-field
        class="mb-4"
        field="phone"
        :disabled="loading || sending"
        :label="t('user.forms.contact_info.phone_field_label')"
        input-size="medium"
        :input-block="true"
        :input-placeholder="
          t('user.forms.contact_info.phone_field_placeholder')
        "
        v-model="address.phoneNumber"
      />

      <text-input-field
        class="mb-4"
        field="address_line_1"
        :disabled="loading || sending"
        :label="t('user.forms.contact_info.address_line_1_field_label')"
        input-size="medium"
        :input-block="true"
        :input-placeholder="
          t('user.forms.contact_info.address_line_1_field_placeholder')
        "
        v-model="address.streetLine1"
      />

      <text-input-field
        class="mb-4"
        field="address_line_2"
        :disabled="loading || sending"
        :label="t('user.forms.contact_info.address_line_2_field_label')"
        input-size="medium"
        :input-block="true"
        :input-placeholder="
          t('user.forms.contact_info.address_line_2_field_placeholder')
        "
        v-model="address.streetLine2"
      />

      <text-input-field
        class="mb-4"
        field="zip_code"
        :disabled="loading || sending"
        :label="t('user.forms.contact_info.zip_code_field_label')"
        input-size="medium"
        :input-block="true"
        :input-placeholder="
          t('user.forms.contact_info.zip_code_field_placeholder')
        "
        v-model="address.zipCode"
      />

      <div class="m-form-field mb-5">
        <div class="m-form-field__label m-form-field__label--over">
          <label>{{ t("user.forms.contact_info.country_field_label") }}</label>
        </div>
        <div class="m-select">
          <select v-model="address.country">
            <option value="">
              {{ t("user.forms.profile.country_field_select") }}
            </option>
            <option
              v-for="[code, country] in unref(countries)"
              :value="code"
              :key="code"
            >
              {{ country }}
            </option>
          </select>
        </div>
      </div>

      <div class="m-form-field mb-5">
        <div class="m-form-field__label m-form-field__label--over">
          <label>{{ t("user.forms.contact_info.region_field_label") }}</label>
        </div>
        <div class="m-select">
          <select v-model="address.state">
            <option value="">
              {{ t("user.forms.contact_info.region_field_select") }}
            </option>
            <option
              v-for="region in unref(regions)"
              :value="region"
              :key="region"
            >
              {{ region }}
            </option>
          </select>
        </div>
      </div>

      <text-input-field
        :label="t('user.forms.contact_info.city_field_label')"
        :disabled="loading || sending"
        label="City"
        field="city"
        input-size="medium"
        :input-block="true"
        input-placeholder="Your city"
        v-model="address.city"
      ></text-input-field>

      <input
        :disabled="loading || sending"
        type="submit"
        @click.prevent="submitOrModal"
        class="mt-6 m-button m-button--blue-medium m-button--primary m-button--md"
        :value="t('user.forms.profile.submit')"
      />
    </form>
  </div>
</template>
