<script setup>
import { ref } from "vue";
import SearchInput from "~components/SearchInput.vue";
import LanguageSelect from "~components/LanguageSelect.vue";
import ThemeSwitcher from "~components/ThemeSwitcher.vue";
import Label from "~components/Label.vue";
import { useI18n } from "~src/lib/i18n";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { urls } from "~src/config/environment";
import useAvatar from "~src/composables/avatar";
import useProfile from "~src/composables/profile";
import useMyCourses from "~src/composables/myCourses";
import { logoutURL } from "~src/lib/utils/urls";

const { t, i18n } = useI18n();
const store = useStore();
const { avatar } = useAvatar();
const opened = ref(false);
const { firstName } = useProfile();
const route = useRoute();
const {
  courses,
  activeCourses,
  listCourses,
  productURL,
  productImage,
  cohortEnded,
  cohortBegun,
  cohortState,
  cohortColor,
  cohortRole
} = useMyCourses();
const close = () => (opened.value = false);
</script>

<template>
  <div id="topbar" class="relative bg-surface mx-border-b px-3 lg:px-6">
    <div class="">
      <div class="o-navbar" role="navigation">
        <!-- logo -->
        <div class="o-navbar__brand lg:mr-6">
          <a :href="urls.webAppURL" target="_blank">
            <svg
              width="148px"
              class="fill-foreground-high max-lg:hidden h-[2em] w-auto"
            >
              <use href="#brand-logo"></use>
            </svg>
            <svg
              width="32px"
              class="fill-foreground-high lg:hidden h-[2em] w-auto"
            >
              <use href="#brand-logo_symbol"></use>
            </svg>
          </a>
        </div>

        <div
          class="o-navbar__slot o-navbar__slot--stretch text-right hidden lg:block"
        >
          <ul class="m-list m-list--hrz text-left">
            <!-- login -->
            <li class="mr-8">
              <ThemeSwitcher />
            </li>

            <li>
              <div
                class="flex items-center cursor-pointer"
                @click="opened = !opened"
              >
                <div class="m-avatar m-avatar--circle m-avatar--32x32 mr-1">
                  <img :src="avatar.url" v-if="avatar.url" />
                  <svg v-else>
                    <use href="#icons-avatar-light-gray"></use>
                  </svg>
                </div>
                <svg
                  class="mx-square fill-foreground-high cursor-pointer mr-2"
                  style="--mx-square-size: 0.625rem"
                >
                  <use href="#icons-arrow-filled-down"></use>
                </svg>
              </div>
            </li>
          </ul>
        </div>

        <!-- off-canvas menu button -->
        <div
          class="o-navbar__slot o-navbar__slot--stretch text-right lg:hidden"
        >
          <a
            id="main-menu-offcanvas"
            class="o-navbar__offcanvas-button text-blue-medium"
            @click="store.commit('menu/open')"
          ></a>
        </div>
      </div>
    </div>
    <!-- desktop menu -->
    <div
      class="absolute mx-border bg-surface"
      :class="{ hidden: !opened }"
      style="
        width: 312px;
        right: 16px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        z-index: 1;
      "
    >
      <div class="py-6 px-4">
        <div>{{ t("user.greetings", { name: firstName }) }}!</div>
      </div>
      <div class="mx-border-t ml-auto mr-auto w-full"></div>
      <div class="text-sm py-6 px-4 space-y-4">
        <div
          class="flex items-center hover:text-blue-medium"
          :class="{ 'text-blue-medium': route.name == 'Profile' }"
        >
          <svg class="w-[1em] h-[1em] mr-2" style="color: inherit">
            <use href="#icons-profile"></use>
          </svg>
          <router-link
            @click.prevent="close"
            style="color: inherit"
            :to="{ name: 'Profile' }"
            >{{ t("Profile") }}</router-link
          >
        </div>
        <div
          class="flex items-center hover:text-blue-medium hover:fill-blue-medium"
          :class="{
            'fill-blue-medium': route.name == 'AccountSettings',
            'text-blue-medium': route.name == 'AccountSettings',
            'fill-foreground-high': route.name != 'AccountSettings'
          }"
        >
          <svg
            class="w-[1em] h-[1em] mr-2"
            style="fill: inherit; color: inherit"
          >
            <use href="#icons-gear"></use>
          </svg>
          <router-link
            @click.prevent="close"
            style="color: inherit"
            :to="{ name: 'AccountSettings' }"
            >{{ t("Account Settings") }}</router-link
          >
        </div>
      </div>
      <div class="mx-border-t ml-auto mr-auto w-full"></div>
      <div class="text-sm py-6 px-4 space-y-4">
        <div class="text-foreground-medium text-sm">ClasspertX</div>
        <div
          class="mt-3 flex items-center hover:fill-blue-medium hover:text-blue-medium"
          :class="{
            'fill-blue-medium': route.name == 'PurchaseHistory',
            'text-blue-medium': route.name == 'PurchaseHistory',
            'fill-foreground-high': route.name != 'PurchaseHistory'
          }"
        >
          <svg
            class="w-[1em] h-[1em] mr-2"
            style="fill: inherit; color: inherit"
          >
            <use href="#icons-receipt"></use>
          </svg>
          <router-link
            @click.prevent="close"
            style="color: inherit"
            :to="{ name: 'PurchaseHistory' }"
            >{{ t("Purchase History") }}</router-link
          >
        </div>
        <div
          class="flex items-center hover:fill-blue-medium hover:text-blue-medium"
          :class="{
            'fill-blue-medium': route.name == 'MyCoursesCohorts',
            'text-blue-medium': route.name == 'MyCoursesCohorts',
            'fill-foreground-high': route.name != 'MyCoursesCohorts'
          }"
        >
          <svg
            class="w-[1em] h-[1em] mr-2"
            style="fill: inherit; color: inherit"
          >
            <use href="#icons-cohort"></use>
          </svg>
          <router-link
            @click.prevent="close"
            style="color: inherit"
            :to="{ name: 'MyCoursesCohorts' }"
            >{{ t("Cohorts") }}</router-link
          >
        </div>

        <div
          class="text-foreground-medium text-sm mt-6"
          v-if="activeCourses.length"
        >
          {{ t("My Learnings") }}
        </div>
        <ul class="mt-2 mx-list-reset space-y-2" v-if="activeCourses.length">
          <li :key="cohort.id" v-for="{ cohort } in activeCourses">
            <a
              class="block p-2 mx-border rounded"
              target="_blank"
              :href="
                productURL(
                  cohort.course.slug,
                  cohort.slug,
                  cohort.new_lms,
                  '/about'
                )
              "
            >
              <div class="text-sm">{{ cohort.course.name }}</div>
              <div class="mt-2 flex justify-between">
                <div class="text-sm flex">
                  <img
                    class="mr-1"
                    :src="productImage(cohort.files)"
                    style="width: 1rem; height: 1rem"
                    v-if="productImage(cohort.files)"
                  />
                  <div>{{ cohort.name }}</div>
                </div>
                <div class="flex items-center">
                  <Label class="text-purple-medium text-xs mr-2">
                    {{ cohort ? "Cohort" : "On Demand" }}
                  </Label>
                  <svg class="w-[1em] h-[1em] text-xs fill-foreground-high">
                    <use href="#icons-external-arrow"></use>
                  </svg>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="mx-border-t ml-auto mr-auto w-full"></div>
      <div class="text-sm flex relative py-6 px-4">
        <div
          class="flex items-center hover:fill-blue-medium hover:text-blue-medium fill-foreground-high"
        >
          <svg
            class="w-[1em] h-[1em] mr-2"
            style="fill: inherit; color: inherit"
          >
            <use href="#icons-logout"></use>
          </svg>
          <a :href="logoutURL" style="color: inherit">{{
            t("dictionary.sign_out")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#top-search-bar {
  min-width: 300px;
}

@media (min-width: 992px) {
  #top-search-bar {
    max-width: 450px;
  }
}
</style>
