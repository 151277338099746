import { api } from "~src/config/axios/instances";

const state = {
  loading: false,
  loaded: false,
  scholarshippable: []
};

const mutations = {
  update(state, { field, value }) {
    state[field] = value;
  },
  scholarshippable(state, cohorts) {
    state.scholarshippable = cohorts;
  }
};

const getters = {
  getScholarshippableCohort: state => (courseSlug, cohortSlug) => {
    return state.scholarshippable.find(
      ({ slug, course }) => slug === cohortSlug && course.slug === courseSlug
    );
  }
};

const actions = {
  scholarshippable({ commit }) {
    commit("update", { field: "loaded", value: false });

    return new Promise((resolve, reject) => {
      api
        .get(
          `/account/v1/app/cohorts?select=*,course:app__course_skeletons(*)&eligible_for_scholarships=eq.true`,
          {
            headers: { Prefer: "return=representation" }
          }
        )
        .then(
          response => {
            commit("update", { field: "loaded", value: true });
            const { data } = response;

            if (data?.length) {
              commit("scholarshippable", data);
              resolve(data);
            } else {
              commit("scholarshippable", []);
              resolve([]);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
