<script setup>
import { unref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "~src/lib/i18n";
import useMyCourses from "~src/composables/myCourses";
import { formatStringList } from "~src/lib/utils";
import pluck from "ramda/es/pluck";
import { urls } from "~src/config/environment";
import Label from "~components/Label.vue";

const store = useStore();
const { i18n } = useI18n();
const {
  courses,
  listCourses,
  productURL,
  productImage,
  avatarImage,
  cohortEnded,
  cohortBegun,
  cohortState,
  cohortColor,
  cohortRole,
  finalProjectCritiqueURL
} = useMyCourses();

const nameFormatter = formatStringList(
  unref(i18n.locale),
  "long",
  "conjunction"
);
const pluckName = pluck("name");

const formatDate = date => {
  if (Intl) {
    const parsed = Date.parse(date);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric"
    }).format(parsed);
  } else {
    return date;
  }
};

// not necessary since the main menu
// already fetches them
// listCourses();
</script>

<template>
  <div>
    <div class="mb-4 font-bold">Cohorts</div>
    <div class="mx-border-t mb-10"></div>
    <div class="space-y-6">
      <div
        class="course mx-border rounded"
        v-for="{
          id,
          certificate,
          certificate_issuing_state,
          cohort,
          grade,
          order,
          passed,
          payments,
          final_project,
          role,
          state,
          with_honors
        } in courses"
        :key="id"
      >
        <div class="flex">
          <div
            class="max-lg:hidden flex-none flex"
            v-if="avatarImage(cohort.course.files)"
          >
            <img
              :src="avatarImage(cohort.course.files)"
              style="
                max-width: 11.25rem;
                height: auto;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
              "
            />
          </div>
          <div class="flex-1 pt-4 pl-4 pb-4 max-lg:pr-4">
            <div class="flex md:hidden mb-2">
              <Label :color="cohortColor(cohort)" class="text-xs flex-none">
                {{ cohortState(cohort) }}
              </Label>
            </div>
            <div class="text-lg md:text-xl font-bold">
              {{ cohort.course.name }}
            </div>
            <div class="text-sm mt-1">
              by
              {{ nameFormatter.format(pluckName(cohort.course.instructors)) }}
            </div>
            <div class="mt-4 md:mt-6 flex">
              <div class="flex flex-col mr-8">
                <div class="text-sm text-foreground-medium">Cohort Name</div>
                <div class="text-sm mt-2 flex">
                  <img
                    class="mr-1"
                    :src="productImage(cohort.files)"
                    style="width: 1rem; height: 1rem"
                    v-if="productImage(cohort.files)"
                  />
                  <div>{{ cohort.name }}</div>
                </div>
              </div>
              <div class="flex flex-col">
                <div class="text-sm text-foreground-medium">Course Period</div>
                <div class="text-sm mt-2">
                  <span
                    >{{ formatDate(cohort.starts_at) }} -
                    {{ formatDate(cohort.ends_at) }}</span
                  >
                </div>
              </div>
            </div>
            <div class="mt-4 md:mt-6">
              <div class="flex flex-col">
                <div class="max-md:hidden text-sm text-foreground-medium">
                  Enrollment
                </div>
                <div class="text-sm mt-2 flex items-center">
                  <svg class="w-[1em] h-[1em] mr-2" style="color: inherit">
                    <use href="#icons-profile"></use>
                  </svg>
                  <div class="flex items-center">
                    <span>{{ cohortRole({ role }) }}</span>
                    <Label
                      class="ml-1"
                      v-if="state === 'suspended'"
                      color="secondary"
                    >
                      suspended
                    </Label>
                  </div>
                </div>
              </div>
            </div>
            <div class="lg:hidden text-center mt-4 lg:mt-2">
              <a
                v-if="state === 'active'"
                class="flex m-button m-button--blue-medium m-button--primary m-button--sm justify-center"
                style="white-space: nowrap"
                target="_blank"
                :href="
                  productURL(
                    cohort.course.slug,
                    cohort.slug,
                    cohort.new_lms,
                    '/about'
                  )
                "
              >
                <span class="mr-2">Go to Course</span>
                <svg class="w-[1em] h-[1em] fill-foreground-fixed">
                  <use href="#icons-external-arrow" />
                </svg>
              </a>
            </div>
          </div>
          <div
            class="hidden lg:flex flex-none flex-col justify-between pt-4 pb-4 pr-4"
          >
            <div class="flex">
              <Label
                :color="cohortColor(cohort)"
                class="text-xs flex-none ml-auto"
              >
                {{ cohortState(cohort) }}
              </Label>
            </div>
            <a
              v-if="state === 'active'"
              class="flex m-button m-button--blue-medium m-button--primary m-button--xs"
              style="white-space: nowrap"
              target="_blank"
              :href="
                productURL(
                  cohort.course.slug,
                  cohort.slug,
                  cohort.new_lms,
                  '/about'
                )
              "
            >
              <span class="mr-2">Go to Course</span>
              <svg class="w-[1em] h-[1em] fill-foreground-fixed">
                <use href="#icons-external-arrow" />
              </svg>
            </a>
          </div>
        </div>

        <div
          v-if="
            (payments.length > 0 && payments[0].receipt_url) ||
            (state === 'active' &&
              (certificate_issuing_state === 'pending' ||
                certificate_issuing_state === 'issued'))
          "
          class="mx-border-t w-full pt-4 pb-4 pr-4 pl-4 flex space-x-4"
        >
          <div
            v-if="
              state === 'active' &&
              (certificate_issuing_state === 'pending' ||
                certificate_issuing_state === 'issued')
            "
          >
            <div v-if="certificate_issuing_state === 'issued'">
              <router-link
                :to="{
                  name: 'CertificateShow',
                  params: { course_slug: cohort.course.slug, slug: cohort.slug }
                }"
                class="flex justify-end items-center"
              >
                <svg
                  class="mr-2"
                  width="0.875rem"
                  height="1rem"
                  viewbox="0 0 24 24"
                >
                  <use xlink:href="#icons-certificate-outline" />
                </svg>
                <span class="text-sm">View Certificate</span>
              </router-link>
            </div>
            <div v-if="certificate_issuing_state === 'pending'">
              <router-link
                :to="{
                  name: 'CertificateWizard',
                  params: { course_slug: cohort.course.slug, slug: cohort.slug }
                }"
                class="flex justify-end"
              >
                <svg
                  class="mr-2"
                  width="0.875rem"
                  height="1rem"
                  viewbox="0 0 24 24"
                >
                  <use xlink:href="#icons-certificate-outline" />
                </svg>
                <span class="text-sm">Issue Certificate</span>
              </router-link>
            </div>
          </div>
          <div
            v-if="final_project && finalProjectCritiqueURL(final_project.files)"
          >
            <a
              :href="finalProjectCritiqueURL(final_project.files)"
              target="_blank"
              class="flex justify-end items-center"
            >
              <svg
                class="mr-2"
                width="0.875rem"
                height="1rem"
                viewbox="0 0 24 24"
              >
                <use xlink:href="#icons-critique" />
              </svg>
              <span class="text-sm">View Critique</span>
            </a>
          </div>
          <div v-if="payments.length > 0 && payments[0].receipt_url">
            <a
              target="_blank"
              class="flex justify-end items-center"
              :href="payments[0].receipt_url"
            >
              <svg
                class="mr-2"
                width="0.875rem"
                height="1rem"
                viewbox="0 0 24 24"
              >
                <use xlink:href="#icons-cc" />
              </svg>
              <span class="text-sm">Payment Receipt</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
