<script setup>
import Modal from "~external/v3/Modal.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "~external/v3/Toast/api";

const props = defineProps({
  opened: {
    type: Boolean,
    required: true,
    default: false
  },
  providerName: {
    type: String,
    required: true
  }
});

const name = "UnlinkOauthAccountModal";
const emit = defineEmits(["close", "unlink"]);

const unlink = e => {
  emit("unlink", e);
  emit("close", e);
};

const close = e => {
  emit("close", e);
};
</script>

<template>
  <modal
    :name="name"
    :opened="opened"
    @close="close"
    :maskClasses="['grid grid-cols-12 gap-x-2']"
    :wrapperClasses="[
      'bg-transparent',
      'max-md:px-3',
      'items-center',
      'justify-center',
      'col-span-12',
      'md:col-span-6',
      'md:col-start-4',
      'xl:col-span-4',
      'xl:col-start-5',
      'my-auto'
    ]"
    :containerClasses="['p-[1em]']"
  >
    <template #body>
      <div class="flex items-center m-4 leading-tight">
        <div class="max-md:mt-32 space-y-2">
          <p class="text-2xl font-bold text-center mt-4">
            Unlink your {{ providerName }} account
          </p>
          <div class="mt-8">
            <span>
              Are you sure you want to unlink your
              <span class="font-bold">{{ providerName }}</span>
              account?
            </span>
          </div>

          <input
            @click="unlink"
            class="mt-6 m-button m-button--blue-medium m-button--primary m-button--md w-full block bg-red-medium border-red-medium"
            :value="`Unlink ${providerName}`"
          />

          <input
            @click="close"
            class="mt-6 m-button m-button--blue-medium m-button--outline m-button--md w-full block"
            value="Cancel"
          />
        </div>
      </div>
    </template>
  </modal>
</template>
