<script setup>
import { ref } from "vue";
import DestroyAccountModal from "~components/DestroyAccountModal.vue";

const open = ref(false);

const closeModal = () => {
  open.value = false;
};

const submit = () => {
  open.value = true;
};
</script>

<template>
  <destroy-account-modal :opened="open" @close="closeModal" />

  <div class="mt-10 mx-border-t">
    <div
      class="mt-4 inline-block px-2 py-2 rounded text-xs text-red-medium font-bold uppercase"
      style="background-color: rgb(var(--red-medium) / var(--alpha))"
    >
      <span>&#x26A0; Danger zone</span>
    </div>

    <p class="text-sm leading-tight my-4">
      Click on the button bellow to destroy your account. All your information
      will be deleted permanently
    </p>

    <input
      type="submit"
      @click="submit"
      class="m-button m-button--red-medium m-button--outline m-button--xs"
      value="Destroy Account"
    />
  </div>
</template>
