export const urls = {
  appURL: import.meta.env.VITE_URL,
  webAppURL: import.meta.env.VITE_WEB_APP_URL,
  webAppApiURL: import.meta.env.VITE_WEB_APP_API_URL,
  apiURL: import.meta.env.VITE_API_URL,
  apiDocsURL: import.meta.env.VITE_API_DOCS_URL,
  dashboardURL: import.meta.env.VITE_DASHBOARD_URL,
  fileURL: import.meta.env.VITE_FILE_URL,
  lmsURL: import.meta.env.VITE_LMS_URL,
  thumborURL: import.meta.env.VITE_THUMBOR_URL
};

export const paths = {
  tokenAuthPath: import.meta.env.VITE_TOKEN_AUTH_PATH,
  forgotPasswordPath: import.meta.env.VITE_FORGOT_PASSWORD_PATH,
  resendConfirmationPath: import.meta.env.VITE_RESEND_CONFIRMATION_PATH,
  noUsernamePath: import.meta.env.VITE_NO_USERNAME_PATH,
  logInPath: import.meta.env.VITE_LOG_IN_PATH,
  logOutPath: import.meta.env.VITE_LOG_OUT_PATH
};

export default {
  ...urls,
  ...paths
};
