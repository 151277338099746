import useVueValidate from "@vuelidate/core";
import { reactive, unref, nextTick } from "vue";
import { required, minLength, maxLength, email } from "~src/lib/validations";
import { required as _required } from "@vuelidate/validators/dist/raw.esm.js";
import camelCase from "lodash/camelCase";
import { camelCasedKeys } from "~utils";
import * as validators from "@vuelidate/validators";
import { i18n } from "~src/lib/i18n";

const { createI18nMessage } = validators;
const withI18nMessage = createI18nMessage({ t: i18n.global.t });

export default function useScholarshipValidation({ initialValues }) {
  console.log(initialValues);

  const state = reactive(
    Object.keys(unref(initialValues)).reduce(
      (acc, key) => ({
        ...acc,
        [key]: unref(initialValues)[key] || ""
      }),
      {}
    )
  );

  const requiredIfEmployed = withI18nMessage({
    $message: validators.required.$message,
    $validator: (value, siblings, vm) => {
      // console.log("entrei caraio", siblings, value);
      // await nextTick();

      if (siblings.employed && siblings.employed != "no") {
        return _required(value, siblings, vm);
      } else {
        return true;
      }
    },
    $params: validators.required.$params
  });

  const employed = value => {
    return ["yes", "no", "partially", "in_a_different_industry"].includes(
      value
    );
  };

  const rules = {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(50)
    },
    email: {
      required,
      maxLength: maxLength(60),
      email
    },
    employed: {
      required,
      employed
    },
    company: {
      required: requiredIfEmployed
    },
    employment_description: {},
    country: {
      required
    },
    how_did_you_hear_about_us: {
      required,
      maxLength: maxLength(50)
    },
    tell_us_about_yourself: {
      required,
      maxLength: maxLength(300)
    },
    why_do_you_want_this_scholarship: {
      required,
      maxLength: maxLength(1000)
    },
    anything_you_would_like_us_to_know: {
      maxLength: maxLength(300)
    }
  };

  const v$ = useVueValidate(rules, state);

  return { state, v$ };
}
