import { ref, computed, unref } from "vue";
import { urls } from "~config/environment";
import thumbor from "~src/lib/thumbor";
import { useStore } from "vuex";

export default function useAvatar() {
  const store = useStore();
  const profileId = computed(() => store.state.profile.id);
  const patch = (field, value) =>
    store.commit("profile/patch", { field, value });

  const avatar = computed(() => {
    return store.getters["profile/avatar"];
  });

  const getFile = params => store.dispatch("file/get", params);
  const getAvatar = () => {
    return getFile({ id: unref(profileId), type: "Profile" })
      .then(file => {
        patch("avatarIsLoaded", true);
        if (file && file.name) {
          patch("avatarFileUrl", thumbor(file)("default"));
          patch("avatarIsLegacy", false);
        } else {
          patch("avatarIsLegacy", true);
        }
      })
      .catch(err => console.log(err));
  };

  return { profileId, getFile, getAvatar, avatar };
}
