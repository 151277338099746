<script setup>
import SectionFrame from "~components/SectionFrame.vue";
import Icon from "~external/Icon.vue";
import { ref, computed } from "vue";
import { useI18n } from "~src/lib/i18n";
import { useStore } from "vuex";
import { webAppURL } from "~src/lib/utils/urls";
import capitalize from "lodash/capitalize";
import UnlinkOauthAccountModal from "~components/UnlinkOauthAccountModal.vue";

const store = useStore();
const providers = ref([]);
const selectedProviderName = ref("");
const selectedOauthAccountId = ref("");
const open = ref(false);
const emailConfirmed = computed(() => store.state.userAccount.confirmed_at);
const currentEmail = computed(() => store.state.userAccount.email);
const canUnlink = computed(
  () => !currentEmail.value.includes("@users.classpert.com")
);

const closeModal = () => {
  open.value = false;
};

const openModal = (providerName, oauthAccountId) => {
  selectedProviderName.value = capitalize(providerName);
  selectedOauthAccountId.value = oauthAccountId;
  open.value = true;
};

const listOauthAccounts = params => store.dispatch("oauthAccount/list", params);
const listProviders = params =>
  store.dispatch("oauthAvailableProvider/list", params);

const linkedAccounts = ref([]);
const loadSortedLinkedAccounts = () => {
  listOauthAccounts().then(data => {
    linkedAccounts.value = data.sort((a, b) =>
      a.provider > b.provider ? 1 : -1
    );
  });
};
loadSortedLinkedAccounts();

listProviders().then(data => {
  providers.value = data.sort((a, b) => (a.name > b.name ? 1 : -1));
});

const unlink = id => store.dispatch("oauthAccount/delete", { id });

const submitUnlink = e => {
  unlink(selectedOauthAccountId.value)
    .then(() => {
      loadSortedLinkedAccounts();
    })
    .catch(error => {}); //add then/catch for error handling
};
</script>

<template>
  <unlink-oauth-account-modal
    :opened="open"
    @close="closeModal"
    @unlink="submitUnlink"
    :providerName="selectedProviderName"
  />

  <section-frame :alert="alert">
    <template #header>
      <div class="mt-10 mx-border-t">
        <div class="flex bdAb mt-10">
          <div
            ref="top"
            class="font-bold uppercase text-xs text-foreground-medium tracking-widest"
          >
            Linked Accounts
          </div>
        </div>
      </div>
    </template>

    <div class="space-y-6">
      <div v-if="linkedAccounts.length > 0">
        <p class="text-sm mt-8 font-bold">
          Your account is linked with the following providers
        </p>

        <div v-if="!canUnlink">
          <p class="text-sm">
            You can't unlink with providers before your e-mail is confirmed
          </p>
        </div>

        <ul v-for="item in linkedAccounts">
          <li class="mt-5 flex items-center">
            <icon
              class="mr-2"
              :name="`${item.provider}-circle`"
              :style="`fill: rgb(var(--vendor-${item.provider})); --width: 1em`"
            />
            <div class="text-sm">
              Linked with {{ capitalize(item.provider) }}
            </div>

            <div
              v-if="canUnlink"
              class="text-right flex items-center text-sm ml-auto"
            >
              <a
                href="#"
                @click="openModal(item.provider, item.id)"
                class="align-top"
              >
                Unlink
              </a>
              <icon class="ml-2" name="unlink" style="--width: 1em" />
            </div>
          </li>
        </ul>
      </div>

      <div v-if="linkedAccounts.length < providers.length">
        <p class="text-sm mb-4 font-bold">
          You can link your account with the following providers
        </p>

        <ul v-for="item in providers">
          <li v-if="!linkedAccounts.map(x => x.provider).includes(item.name)">
            <a
              class="m-button m-button--md mb-2"
              :class="'m-button--' + item.name"
              :href="webAppURL(`accounts/auth/${item.name}`)"
            >
              Link with {{ capitalize(item.name) }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section-frame>
</template>
