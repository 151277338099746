import { ref } from "vue";
const clickable = ref(true);

export default function debounced(callback, timeout = 5000) {
  if (clickable.value) {
    callback();
    clickable.value = false;
    setTimeout(() => (clickable.value = true), timeout);
  }
}
