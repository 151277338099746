import domain from "~src/config/domain";

const WEB_APP_URL = locale => path => {
  return domain(locale, path);
};

const state = {
  code: "en",
  webAppURL: WEB_APP_URL("en"),
  decimalSeparator: ".",
  thousandSeparator: ",",
  dateFormat: "dd/MM/yyyy"
};

const mutations = {
  set(state, locale) {
    state.code = locale;
    state.webAppURL = WEB_APP_URL(locale);
    state.decimalSeparator = (1.1)
      .toLocaleString(locale || "en")
      .replace(/\d/g, "");
    state.thousandSeparator = (1000)
      .toLocaleString(locale || "en")
      .replace(/\d/g, "");
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
