<script setup>
import Account from "~components/settings/Account.vue";
import RegionTimezone from "~components/settings/RegionTimezone.vue";
import DestroyAccount from "~components/settings/DestroyAccount.vue";
import EditEmail from "~components/settings/EditEmail.vue";
import LinkedAccounts from "~components/settings/LinkedAccounts.vue";
import clone from "ramda/es/clone";
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";

const profile = ref({});
const loading = ref(true);
const store = useStore();
const init = () => {
  profile.value = clone(store.state.profile);
  loading.value = false;
};

const loaded = computed(() => store.state.profile.loaded);

watch(loaded, init);

if (loaded.value) {
  init();
}
</script>

<template>
  <div class="col-span-12 lg:col-span-5">
    <div class="mb-4 font-bold">Account Settings</div>
    <div class="mx-border-t mb-10"></div>
    <account :profile="profile" :loading="loading" />

    <edit-email />

    <linked-accounts />

    <region-timezone :profile="profile" :loading="loading" />

    <destroy-account />
  </div>
</template>
