import { computed } from "vue";
import { useStore } from "vuex";
import { paths } from "~src/config/environment";

export default function useProfile() {
  const store = useStore();

  const loaded = computed(() => {
    if (store.state.profile.id) {
      return true;
    }
    return false;
  });

  const username = computed(() => store.state.profile.username);
  const firstName = computed(() => {
    return store.getters["profile/firstName"];
  });

  const loading = computed(() => !loaded.value);

  const enforceUsername = () => {
    if (username.value) {
      return true;
    } else {
      window.location.replace(
        store.state.locale.webAppURL(paths.noUsernamePath)
      );
      return false;
    }
  };

  const fetch = () => store.dispatch("profile/get");

  return {
    loaded,
    loading,
    fetch,
    enforceUsername,
    username,
    firstName
  };
}
