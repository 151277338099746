import  { api, railsApi, crossOriginXHR } from "./factory";
import interceptors from "./interceptors";

// api interceptors mixin
api.interceptors.request.use(interceptors.req.suc, interceptors.req.err);
api.interceptors.response.use(interceptors.res.suc, interceptors.res.err);

// railsApi interceptors mixin
railsApi.interceptors.request.use(interceptors.req.suc, interceptors.req.err);
railsApi.interceptors.response.use(interceptors.res.suc, interceptors.res.err);

export { api, railsApi, crossOriginXHR };
