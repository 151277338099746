import "./assets/main.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { useToast } from "~external/v3/Toast/api";

import Multiselect from "vue-multiselect";
import VueSmoothScroll from "vue3-smooth-scroll";

//  Vue Loading Overlay
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// Import Locales
import { i18n } from "./lib/i18n";

// Import XHR clients
import { crossOriginXHR } from "./config/axios/instances";

// Import Session
import session from "./config/session";

// Global Components
import Icon from "./components/Icon.vue";

// Sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";

const toast = useToast();

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);
app.use(VueSmoothScroll);

app.provide("$toast", toast);
app.provide("$crossOriginXHR", crossOriginXHR);
app.provide("$session", session);

app.component("icon", Icon);
app.component("multiselect", Multiselect);
app.component("loading", VueLoading);

app.use(VueLoading);

app.config.compilerOptions.whitespace = "preserve";
app.config.globalProperties.$toast = toast;

Sentry.init({
  app,
  environment: import.meta.env.PROD ? "production" : "development",
  release: import.meta.env.VITE_SENTRY_RELEASE,
  dsn: "https://662dd9b964c84da1999652ae09056cc2@o272618.ingest.sentry.io/6443645",
  integrations: [
    new ExtraErrorDataIntegration({}),
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // https://github.com/getsentry/sentry-javascript/issues/3203#issuecomment-959823083
      tracingOrigins: ["::1"]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1
});

app.mount("#app");
