import { api } from "~src/config/axios/instances";
import { update as u } from "~utils";

const state = {
  id: undefined,
  email: "",
  loading: false,
  loaded: false
};

const mutations = {
  get(state, payload) {
    u(state)(payload);
  },
  update(state, payload) {
    u(state)(payload);
  }
};

const actions = {
  get({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/account/v1/app/user_accounts?select=*,profile:app__profiles(*)&limit=1`
        )
        .then(
          response => {
            const payload = response.data[0];

            if (payload) {
              commit("get", {
                ...payload,
                loaded: true
              });
            }

            resolve(payload);
          },
          error => {
            reject(error);
          }
        );
    });
  },
  update({ commit }, { payload }) {
    return new Promise((resolve, reject) => {
      api.patch(`/account/v1/app/user_accounts?limit=1`, payload).then(
        response => {
          commit("update", {
            ...payload,
            loaded: true
          });
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
