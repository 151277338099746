import { api } from "~src/config/axios/instances";

const state = {
  id: null,
  fileable_id: null,
  fileable_type: null,
  name: null,
  path: null
};

const mutations = {
  get(state, payload) {
    state.id = payload.id;
    state.fileable_id = payload.fileable_id;
    state.fileable_type = payload.fileable_type;
    state.name = payload.name;
    state.path = payload.path;
  }
};

const getters = {
  path: state => {
    return [state.path, state.name].join("/");
  }
};

const actions = {
  get({ commit }, { id, type, subId }) {
    var query;

    if (subId) {
      query = `fileable_id=eq.${id}&fileable_type=eq.${type}&sub_id=eq.${subId}`;
    } else {
      query = `fileable_id=eq.${id}&fileable_type=eq.${type}`;
    }

    return new Promise((resolve, reject) => {
      api
        .get(`/account/v1/app/files?${query}&limit=1`, {
          headers: { Prefer: "return=representation" }
        })
        .then(
          response => {
            const { data } = response;

            if (data?.length) {
              commit("get", data[0]);
              resolve(data[0]);
            } else {
              resolve(undefined);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
